import React, {
  useState,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from "react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { DmsMasterFile } from "../../../interfaces/interfaces";
import { ROUTE_PATHS } from "../../../consts";
import { CompanyContext } from "../../../context/company-context";
import documentsApi from "../../../api/documents-api";
import fileApi from "../../../api/file-api";
import { useLoadData } from "../../../hooks/useLoadData";
import { DownloadButtonComponent } from "../../../components/shared/download-button/download-button.component";
import BackTo from "../../../components/shared/back-to/back-to.component";
import Spinner from "../../../components/shared/spinner/spinner.component";
import Table from "../../../components/shared/table/table.component";
import { getColumns } from "./helpers";

export default function DownloadComponent() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { company } = useContext(CompanyContext);
  const [isLatest, setIsLatest] = useState<boolean>(true);
  const [toggleAll, setToggleAll] = useState<boolean>(false);
  const [enabledDms, setEnabledDms] = useState<number[]>([]);
  const [masterFiles, isLoading] = useLoadData<DmsMasterFile[]>({
    fetcher: useCallback(
      () => documentsApi.getMasterFiles(company.id),
      [company.id]
    ),
  });

  const getFilenameFromDms = (filename: string) => filename.split("-")[1];

  const masterFilesData = useMemo(() => {
    let tmpMasterFiles = masterFiles;
    if (isLatest) {
      const latestMasterFiles: { [key: string]: DmsMasterFile } = {};
      for (const masterFile of masterFiles) {
        const filename = getFilenameFromDms(masterFile.name);
        if (filename in latestMasterFiles) {
          if (latestMasterFiles[filename].version < masterFile.version) {
            latestMasterFiles[filename] = masterFile;
          }
        } else {
          latestMasterFiles[filename] = masterFile;
        }
      }
      tmpMasterFiles = Object.values(latestMasterFiles);
    }
    return tmpMasterFiles.map((masterFile) => ({
      ...masterFile,
      selector: enabledDms.includes(masterFile.id),
    }));
  }, [masterFiles, enabledDms, isLatest]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (toggleAll) {
      setEnabledDms(masterFiles.map(({ id }) => id));
    } else {
      setEnabledDms([]);
    }
  }, [isLoading, masterFiles, toggleAll]);

  const toggleFile = useCallback(
    (id: number) => {
      if (enabledDms.includes(id)) {
        setEnabledDms((prevDms) => prevDms.filter((item) => item !== id));
      } else {
        setEnabledDms((prevDms) => [id, ...prevDms]);
      }
    },
    [enabledDms, setEnabledDms]
  );

  const downloadEnabled = useMemo(() => enabledDms.length > 0, [enabledDms]);

  const COLUMNS = useMemo(
    () =>
      getColumns({
        t,
        companyId: company.id,
        toggleAll,
        setToggleAll,
        toggleFile,
      }),
    [t, company.id, toggleAll, setToggleAll, toggleFile]
  );

  const downloadablePromises = useMemo(
    () =>
      masterFilesData
        .filter(({ selector }) => selector)
        .map(({ name }) =>
          fileApi.downloadFile(
            `${ROUTE_PATHS.company}/${company.id}/document-management/download-file?fileName=${name}`
          )
        ),
    [masterFilesData, company.id]
  );

  return (
    <div className="documents-page">
      <div className="d-flex flex-column gap-3">
        <div className="d-flex justify-content-start px-3 py-1">
          <BackTo
            link={pathname.split("download")[0]}
            title={t("company.tabs.documents.downloads.back")}
          />
        </div>
        <div className="d-flex justify-content-start px-3 py-1">
          <div className="flex-grow-1 form-check form-switch ps-5">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="versionSwitcher"
              checked={!isLatest}
              onChange={() => {
                setEnabledDms([]);
                setToggleAll(false);
                setIsLatest((originalValue) => !originalValue);
              }}
            />
            <label className="form-check-label" htmlFor="grantSwitcher">
              {isLatest
                ? t("company.tabs.documents.downloads.switch.latest")
                : t("company.tabs.documents.downloads.switch.all")}
            </label>
          </div>
          <DownloadButtonComponent
            label={t("company.tabs.documents.downloads.download")}
            disabled={!downloadEnabled}
            downloadHandler={() => Promise.all(downloadablePromises)}
          />
        </div>
        {isLoading ? (
          <Spinner size="medium" />
        ) : (
          <Table
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            columns={COLUMNS}
            data={masterFilesData}
            search={true}
            presortBy={{
              property: "name",
              sortType: "string",
              sortDirection: "up",
            }}
          />
        )}
      </div>
    </div>
  );
}
