import React from "react";
import { FieldValues } from "react-hook-form";
import { GL_ALLOCATION_STATUS } from "../../../consts";
import { GlUpdateTransaction } from "../../../interfaces/interfaces";
import AllocatedGLForm from "./allocatedGL/allocated-gl-form.component";
import UnallocatedGLForm from "./unallocatedGL/unallocated-gl-form.component";

export interface GLFormComponentProps {
  onClose: () => void;
  onSubmit: (
    values: FieldValues,
    callback: (values: FieldValues) => Promise<any>
  ) => void;
  glTransaction: GlUpdateTransaction;
  isConfirmating: boolean;
}

export default function GLFormComponent(
  props: GLFormComponentProps
): JSX.Element {
  const { glTransaction } = props;

  return glTransaction.status === GL_ALLOCATION_STATUS.ALLOCATED_TARGET ? (
    <AllocatedGLForm {...props} />
  ) : (
    <UnallocatedGLForm {...props} />
  );
}
