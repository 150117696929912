import React from "react";
import "./control-center.component.scss";
import AllocationMethods from "./allocation-methods/alloction-method.component";
import AccountingPeriod from "./accounting-period/accounting-period.component";
import CompanySetup from "./company-setup/company-setup.component";

export default function ControlCenterComponent(): JSX.Element {
  return (
    <div className="control-center">
      <AllocationMethods />
      <AccountingPeriod />
      <CompanySetup />
    </div>
  );
}
