import React, { useCallback, useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTE_PATHS, USER_ROLES, USER_ROLE_LABELS } from "../../../consts";
import { Grant } from "../../../interfaces/interfaces";
import grantsApi from "../../../api/grants-api";
import fileApi from "../../../api/file-api";
import { CompanyContext } from "../../../context/company-context";
import { UserContext } from "../../../context/user.context";
import { useLoadData } from "../../../hooks/useLoadData";
import { DownloadButtonComponent } from "../../../components/shared/download-button/download-button.component";
import Spinner from "../../../components/shared/spinner/spinner.component";
import Table from "../../../components/shared/table/table.component";
import "./list.component.scss";
import EmptyListComponent from "../../../components/shared/empty-list/empty-list.component";
import ButtonsComponent from "../../../components/shared/download-upload-buttons/buttons.component";
import { getColumns } from "./helpers";

export default function GrantList(): JSX.Element {
  const { company } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const isUserAdmin = [
    USER_ROLE_LABELS[USER_ROLES.ROLE_ADMIN],
    USER_ROLE_LABELS[USER_ROLES.ROLE_COMPANY_ADMIN],
  ].includes(user.role);
  const [grants, areGrantsLoading, loadGrants] = useLoadData<Grant[]>({
    fetcher: useCallback(() => grantsApi.getGrants(company.id), [company]),
  });
  const [isFilter, setIsFilter] = useState<boolean>(true);
  const navigate = useNavigate();
  const filteredGrants = useMemo(
    () =>
      isFilter
        ? grants.filter((grant: Grant): boolean => grant.isActive === "active")
        : grants,
    [grants, isFilter]
  );
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const uploadGrants = async (files: FileList) => {
    const formData = new FormData();
    formData.append("file", files[0]);

    const uploadData = await grantsApi.uploadGrants(formData, company.id);
    if (uploadData?.errors?.length) {
      return Promise.reject(uploadData);
    }
    loadGrants();
  };

  const uploadFinData = async (file: FileList) => {
    const formData = new FormData();
    formData.append("file", file[0]);

    const uploadData = await grantsApi.uploadFinData(formData, company.id);
    if (uploadData?.errors?.length) {
      return Promise.reject(uploadData);
    }
  };

  const columns = useMemo(
    () => getColumns({ t, pathname, isUserAdmin }),
    [t, pathname, isUserAdmin]
  );

  const importActions = [
    {
      title: t("company.tabs.grants.getGeneralTemplate"),
      url: "templates?templateName=GRANTS",
    },
    {
      title: t("company.tabs.grants.getFinancialTemplate"),
      url: "templates?templateName=GRANTS_BUDGET",
    },
  ];

  const uploadActions = [
    {
      title: t("company.tabs.grants.uploadGeneralData"),
      action: uploadGrants,
      disabled: !isUserAdmin,
    },
    {
      title: t("company.tabs.grants.uploadFinData"),
      action: uploadFinData,
      disabled: !isUserAdmin,
    },
  ];

  return (
    <div className="grants">
      {areGrantsLoading ? (
        <Spinner size="medium" />
      ) : grants.length ? (
        <div>
          <div className="header">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="grantSwitcher"
                checked={!isFilter}
                onChange={() => setIsFilter((oldFilter) => !oldFilter)}
              />
              <label className="form-check-label" htmlFor="grantSwitcher">
                {isFilter
                  ? t("company.tabs.grants.switch.active")
                  : t("company.tabs.grants.switch.all")}
              </label>
            </div>
            <div className="actions gap-2">
              {ButtonsComponent({ uploadActions })}
              <button
                disabled={!isUserAdmin}
                onClick={() => navigate(`${pathname}/grant`)}
                className="btn btn-primary"
              >
                {t("company.tabs.grants.addGrant")}
              </button>
              <DownloadButtonComponent
                fileName="grants.xlsx"
                label={t("general.export")}
                downloadHandler={() =>
                  fileApi.downloadFile(
                    `${ROUTE_PATHS.company}/${company.id}/grant-export`
                  )
                }
              />
            </div>
          </div>
          <Table search columns={columns} data={filteredGrants} />
        </div>
      ) : (
        <EmptyListComponent
          description={t("company.tabs.grants.addList")}
          importActions={importActions}
          uploadActions={uploadActions}
        />
      )}
    </div>
  );
}
