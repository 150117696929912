import { AxiosResponse } from "axios";
import { httpClient } from "../core/http-client/http-client";
import { LoginResponse } from "../interfaces/interfaces";
import storage from "../core/storage/storage";

interface VerifyMfa {
  [key: string]: string;
}

interface UpdatePassword {
  newPassword: string;
  resetCode: string;
  resetKey: string | null;
}

const authBaseUrl = "authenticate";
async function login(email: string, password: string): Promise<LoginResponse> {
  return httpClient
    .post(authBaseUrl, { username: email, password })
    .then((response: AxiosResponse): LoginResponse => {
      if (response.data.idToken) {
        storage.set("token", response.data.idToken);
        storage.set("id", response.data.userId);
      }
      return response.data;
    });
}

function logout(): Promise<AxiosResponse> {
  return httpClient.post(`logout`).then((response: AxiosResponse) => {
    localStorage.clear();
    storage.clear();
    window.location.href = "/login";
    return response.data;
  });
}

const mfaLogin = async (
  confirmationCode: string,
  mfaKey: string
): Promise<LoginResponse> => {
  return httpClient
    .post("mfa/verify-mfacode", { mfaCode: confirmationCode, mfaKey })
    .then((response: AxiosResponse): LoginResponse => {
      storage.set("token", response.data.idToken);
      storage.set("id", response.data.userId);
      return response.data;
    });
};

const resetPassword = async (
  email: string
): Promise<{ resetKey: string; mfaKey: string }> => {
  return httpClient
    .post("account/reset-password/init", { email })
    .then(
      (response: AxiosResponse): { resetKey: string; mfaKey: string } =>
        response.data
    );
};

const verifyMfa = async (
  data: VerifyMfa,
  key: string | null
): Promise<string> => {
  return httpClient
    .post("account/reset-password/verify-mfacode", { ...data, resetKey: key })
    .then((response: AxiosResponse): string => response.data);
};

const updatePassword = async (
  data: UpdatePassword
): Promise<AxiosResponse<string>> => {
  return httpClient
    .post("account/reset-password/finish", data)
    .then((response: AxiosResponse) => response.data);
};

export const authApi = {
  login,
  logout,
  mfaLogin,
  resetPassword,
  verifyMfa,
  updatePassword,
};

export default authApi;
