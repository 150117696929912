import { useContext, useMemo } from "react";
import { FaPlus } from "react-icons/fa";
import {
  ROUTE_PATHS,
  GRANT_NEW_LINE_TYPES,
  USER_ROLES,
  USER_ROLE_LABELS,
} from "../../../consts";
import { GrantFinData } from "../../../interfaces/interfaces";
import fileApi from "../../../api/file-api";
import { UserContext } from "../../../context/user.context";
import Table from "../../../components/shared/table/table.component";
import BackTo from "../../../components/shared/back-to/back-to.component";
import { DownloadButtonComponent } from "../../../components/shared/download-button/download-button.component";
import Modal from "../../../components/shared/modal/modal.component";
import NewLineForm from "../../../components/forms/grant/new-line.form";
import { getColumns } from "./helpers";
import { useBudgetData } from "./useBudgetData";
import HistoryModalComponent from "./history-modal/history-modal.componentn";
import "./budget.component.scss";

export interface ModificationHistory {
  id: string;
  date: string;
  amount: string;
  account: string;
  description?: string;
}

export default function BudgetDataComponent(): JSX.Element {
  const {
    t,
    renderAllModificationHeader,
    isEditing,
    budgetModifications,
    setBudgetModifications,
    isModificationsBalance,
    showModificationHistoryModal,
    setShowModificationHistoryModal,
    budgetModificationsHistory,
    pathname,
    modifyBudget,
    company,
    currentBudget,
    grantId,
    grant,
    newLineModal,
    setNewLineModal,
    isConfirmating,
    cancelAddingNewLine,
    addLineHandler,
  } = useBudgetData();

  const { user } = useContext(UserContext);
  const isReadonly = useMemo(
    () => user.role === USER_ROLE_LABELS[USER_ROLES.ROLE_USER],
    [user.role]
  );

  const columns = useMemo(
    () =>
      getColumns({
        t,
        renderAllModificationHeader,
        isEditing,
        budgetModifications,
        setBudgetModifications,
        isModificationsBalance,
      }),
    [
      budgetModifications,
      isEditing,
      isModificationsBalance,
      renderAllModificationHeader,
      setBudgetModifications,
      t,
    ]
  );

  return (
    <div className="grant-budget">
      <HistoryModalComponent
        show={showModificationHistoryModal}
        onClose={() => setShowModificationHistoryModal(false)}
        data={budgetModificationsHistory}
      />
      <Modal
        show={newLineModal !== GRANT_NEW_LINE_TYPES.NONE}
        title={t("company.tabs.grants.finData.newLine.title")}
        {...(isConfirmating && {
          subtitle: t("company.tabs.grants.finData.newLine.subtitle"),
        })}
      >
        <NewLineForm
          type={newLineModal}
          grant={grant!}
          isConfirmating={isConfirmating}
          onCancel={cancelAddingNewLine}
          onSubmit={addLineHandler}
        />
      </Modal>
      <div className="header pb-2">
        <BackTo
          link={pathname.split("/grant/")[0]}
          title={t("company.tabs.grants.finData.backToGrant", {
            id: grant?.code,
          })}
        />
        <div className="actions gap-2">
          <div className="dropdown new-line">
            <button
              disabled={isReadonly}
              className="d-flex align-items-center dropdown-toggle btn btn-primary"
              data-bs-toggle="dropdown"
            >
              <FaPlus className="pe-1" />
              <span>{t("company.tabs.grants.finData.newLine.label")}</span>
            </button>
            <ul className="dropdown-menu">
              <li
                className="dropdown-item"
                onClick={() =>
                  setNewLineModal(GRANT_NEW_LINE_TYPES.COMPENSATION)
                }
              >
                <span>{t("company.tabs.grants.finData.newLine.employee")}</span>
              </li>
              <li
                className="dropdown-item"
                onClick={() => setNewLineModal(GRANT_NEW_LINE_TYPES.EXPENSES)}
              >
                <span>
                  {t("company.tabs.grants.finData.newLine.nonPersonal")}
                </span>
              </li>
            </ul>
          </div>
          <button className="btn btn-primary" disabled={isReadonly}>
            {t("company.tabs.grants.finData.grantRenewal")}
          </button>
          <button
            disabled={isReadonly || (isEditing && !isModificationsBalance)}
            className="btn btn-primary"
            onClick={modifyBudget}
          >
            {isEditing
              ? t("general.save")
              : t("company.tabs.grants.finData.modifyBudget")}
          </button>
          <DownloadButtonComponent
            fileName="grant-budget.xlsx"
            label={t("general.export")}
            downloadHandler={() =>
              fileApi.downloadFile(
                `${ROUTE_PATHS.company}/${company.id}/grant-budgets-export?grantId.equals=${grantId}`
              )
            }
          />
        </div>
      </div>
      <Table
        columns={columns}
        isFooter={true}
        data={currentBudget}
        filter={(row: GrantFinData) =>
          row?.account?.accountGrouping?.type !== "Program Expense"
        }
        filteredTotalTitle={t(
          "company.tabs.grants.finData.list.separatorTitle"
        )}
        totalTitle={t("company.tabs.grants.finData.list.totalTitle")}
        restTotalTitle={t("company.tabs.grants.finData.list.totalSecondTitle")}
      />
    </div>
  );
}
