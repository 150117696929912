import { useTranslation } from "react-i18next";
import "./dashboard.component.scss";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  GrantDashboard,
  ProgramDashboard,
  UserSetting,
} from "../../../interfaces/interfaces";
import Table from "../../../components/shared/table/table.component";
import { CompanyContext } from "../../../context/company-context";
import { UserContext } from "../../../context/user.context";
import dashboardApi from "../../../api/dashboard-api";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import Spinner from "../../../components/shared/spinner/spinner.component";
import fileApi from "../../../api/file-api";
import { PERMISSIONS, ROUTE_PATHS } from "../../../consts";
import InActiveUserModalComponent from "../../../components/shared/inactiveusermodal/inactiveuser-modal.component";
import { DownloadButtonComponent } from "../../../components/shared/download-button/download-button.component";
import { getGrantColumns, getProgramColumns } from "./helpers";
export default function Dashboard(): JSX.Element {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  const { setChosenMonth } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const month = new Date().getMonth();
  const [currentProgram, setCurrentProgram] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [isFilterApply, setIsFilterAlly] = useState<boolean>(false);
  const [grantDashboards, setGrantDashboards] = useState<GrantDashboard[]>([]);
  const errorHandler = useErrorHandler();
  const [programDashboards, setProgramDashboards] = useState<
    ProgramDashboard[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  localStorage.setItem("companyId", company?.id);
  const isActive = company?.licenseActive;
  const isCompanyAdmin = user.authorities.includes(PERMISSIONS.companyAdmin);
  const { chosenMonth } = useContext(CompanyContext);
  const [userSettings, setUserSettings] = useState<UserSetting | null>(null);
  const [toggle, setToggle] = useState<boolean | undefined>();
  useEffect(() => {
    dashboardApi.getUserSettings(company?.id, user.id).then((response) => {
      setToggle(
        response.userConfigSettings.dashboard.displayLastMonthAllocatedExpense
      );
      if (response.date) {
        setChosenMonth(response.date.slice(0, response.date.lastIndexOf("-")));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (toggle != undefined) {
      dashboardApi.getExpense(company?.id, user.id, toggle).then((response) => {
        if (response.date) {
          setChosenMonth(
            response.date.slice(0, response.date.lastIndexOf("-"))
          );
        }
      });
    }
  }, [company?.id, user.id, setChosenMonth, toggle]);
  useEffect(() => {
    const date = new Date(chosenMonth);
    const year = date.getUTCFullYear();
    const month1 = date.getUTCMonth() + 1;
    let lastDate = 0;
    if (
      month1 == 1 ||
      month1 == 3 ||
      month1 == 5 ||
      month1 == 7 ||
      month1 == 8 ||
      month1 == 10 ||
      month1 == 12
    ) {
      lastDate = 31;
    } else if (month1 == 2) {
      if (year % 4 == 0) {
        lastDate = 29;
      } else {
        lastDate = 28;
      }
    } else {
      lastDate = 30;
    }
    const finalDate = `${year}-${
      month1 >= 10 ? month1 : `0${month1}`
    }-${lastDate}`;
    setSelectedDate(finalDate);
    dashboardApi
      .getDashboards(company?.id, finalDate)
      .then(
        ({ grantDashboard, programDashboard, userSetting }) => {
          setProgramDashboards(programDashboard);
          setGrantDashboards(grantDashboard);
          setUserSettings(userSetting);
        },
        (err) => errorHandler(err)
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [company, errorHandler, t, chosenMonth]);
  const filteredProgramDashboards = useMemo(
    () =>
      isFilterApply
        ? programDashboards.filter(
            ({ programCode }) => programCode === currentProgram
          )
        : programDashboards,
    [currentProgram, isFilterApply, programDashboards]
  );
  const filteredGrantDashboards = useMemo(
    () =>
      isFilterApply
        ? grantDashboards.filter(
            ({ programCode }) => programCode === currentProgram
          )
        : grantDashboards,
    [currentProgram, isFilterApply, grantDashboards]
  );
  const programColumns = useMemo(() => getProgramColumns({ t }), [t]);
  const grantsColumns = useMemo(() => getGrantColumns({ t }), [t]);
  const inAcvtiveCompMsg =
    company?.name +
    " is currently inactive.  You will only have Read Access Only.  Please call Edge GBS support for assistance at 1.888.989.9355";
  const handleToggle = () => {
    setToggle((current) => {
      return !current;
    });
  };
  return (
    <div className="dashboard">
      <div className="header">
        <div className="current-month">{`${t("general.monthTitle")} - ${t(
          `general.month.${month}`
        )}`}</div>
        <div className="form-check form-switch h6" style={{ color: "green" }}>
          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
            Display last month of allocated expenses
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
            checked={toggle}
            // checked={userSettings?.dashboard.displayLastMonthAllocatedExpense}
            onChange={(e) => setToggle(e.target.checked)}
          />
        </div>
        <div className="actions gap-2">
          <button
            onClick={() => {
              setIsFilterAlly((state) => !state);
              if (isFilterApply) {
                setCurrentProgram(null);
              }
            }}
            disabled={!currentProgram}
            className="btn btn-primary"
          >
            {t(isFilterApply ? "general.clearFilter" : "general.filter")}
          </button>
          {!isActive && !isCompanyAdmin && (
            <InActiveUserModalComponent
              title={inAcvtiveCompMsg}
              activeValue={isActive}
            />
          )}
          <DownloadButtonComponent
            fileName="dashboard.xlsx"
            label={t("general.export")}
            downloadHandler={() =>
              fileApi.downloadFile(
                `${ROUTE_PATHS.company}/${
                  company.id
                }/dashboard-export/${selectedDate}${
                  isFilterApply ? `?currentProgram=${currentProgram}` : ""
                }`
              )
            }
          />
        </div>
      </div>
      {isLoading ? (
        <Spinner size="medium" />
      ) : (
        <div className="container-fluid">
          <div className="half-container">
            <Table
              columns={programColumns}
              data={filteredProgramDashboards}
              presortBy={{
                property: "programCode",
                sortType: "number",
                sortDirection: "up",
              }}
              onRowClick={({ programCode }) => setCurrentProgram(programCode)}
              isRowActive={(row) => row?.programCode === currentProgram}
              classNames={{
                row: ({ currentMonthGrantFunding, yearGrantFunding }) =>
                  +currentMonthGrantFunding === 0 || +yearGrantFunding === 0
                    ? "red-text-programs"
                    : "",
              }}
              isFooter={true}
            />
          </div>
          <div className="half-container">
            <Table
              columns={grantsColumns}
              data={filteredGrantDashboards}
              presortBy={{
                property: "grantCode",
                sortType: "number",
                sortDirection: "up",
              }}
              onRowClick={({ programCode }) => setCurrentProgram(programCode)}
              isRowActive={(row) => row?.programCode === currentProgram}
              classNames={{
                row: ({ remainingBillingMonths }) =>
                  +remainingBillingMonths <= 3 ? "red-text-grants" : "",
              }}
              isFooter={true}
            />
          </div>
        </div>
      )}
    </div>
  );
}
