import { AxiosResponse } from "axios";
import { httpClient } from "../core/http-client/http-client";
import {
  DmsGlTransaction,
  DmsFindInvoicesResult,
  DmsMasterFile,
} from "../interfaces/interfaces";

async function getPeriodsToGrants(
  companyId: string,
  chosenMonth: string
): Promise<Array<string>> {
  return httpClient
    .get(
      `company/${companyId}/document-management/grant-codes?activePeriod=${chosenMonth}`
    )
    .then((response: AxiosResponse) => response.data);
}

async function getTransactions(
  companyId: string,
  chosenMonth: string,
  grantCode: string
): Promise<DmsGlTransaction[]> {
  return httpClient
    .get(
      `company/${companyId}/document-management/grant-transanctions?activePeriod=${chosenMonth}${
        grantCode && `&grantCode=${grantCode}`
      }`
    )
    .then((response: AxiosResponse) => response.data);
}

async function getMasterFiles(companyId: string): Promise<DmsMasterFile[]> {
  return httpClient
    .get(`company/${companyId}/document-management/master-files`)
    .then((response: AxiosResponse) => response.data);
}

async function uploadInvoices(
  data: FormData,
  companyId: string | undefined,
  accountingPeriod: string,
  grantCode: string
) {
  return httpClient
    .post(
      `company/${companyId}/document-management/upload-files?activePeriod=${accountingPeriod}${
        grantCode ? `&grantCode=${grantCode}` : ""
      }`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then(
      (
        response: AxiosResponse
      ): DmsFindInvoicesResult & { errors?: Record<string, string>[] } =>
        response.data
    );
}

const documentsApi = {
  getPeriodsToGrants,
  getTransactions,
  getMasterFiles,
  uploadInvoices,
};

export default documentsApi;
