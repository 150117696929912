import React, { useContext, useMemo, useState, useEffect } from "react";
import "./comnany-form.component.scss";
import { useForm, FieldValues, Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Company } from "../../../interfaces/interfaces";
import companyApi from "../../../api/company-api";
import { useNavigate } from "react-router";
import {
  CURRENCIES,
  LANGUAGES,
  ROUTE_PATHS,
  TYPES_TO_UI,
  USER_ROLE_LABELS,
  USER_ROLES,
} from "../../../consts";
import { PHONE_NUMBER_VALIDATOR } from "../../../consts";
import CreateEntityModal from "../../shared/create-entity-modal/modal.component";
import { UserContext } from "../../../context/user.context";
import { CompanyContext } from "../../../context/company-context";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import { ToastContext } from "../../../context/toast.context";
import FormPhoneComponent from "../form-fields/form-phone.component";
import FormInputComponent from "../form-fields/form-input.component";
import FormDatePicker from "../form-fields/form-date-picker";
import FormMonthPicker from "../form-fields/form-month-picker";
import { getAutoFiscalEndDate } from "../../../utils";
interface CompanyFormProps {
  entity?: Company | null;
}

export default function CompanyForm({ entity }: CompanyFormProps): JSX.Element {
  const { user } = useContext(UserContext);
  const { setCompany, company } = useContext(CompanyContext);
  const isReadonly = useMemo(
    () => user.role === USER_ROLE_LABELS[USER_ROLES.ROLE_USER],
    [user.role]
  );
  const isEditable = useMemo(
    () => user.role === USER_ROLE_LABELS[USER_ROLES.ROLE_ADMIN],
    [user.role]
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  const { addToast } = useContext(ToastContext);
  const errorHandler = useErrorHandler();
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    ...(entity && { defaultValues: entity }),
  });
  const phoneNumber = watch("phoneNumber");
  const companyFiscalPeriodStartDate = watch("companyFiscalPeriodStartDate");
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    if (companyFiscalPeriodStartDate) {
      setValue(
        "companyFiscalPeriodEndDate",
        getAutoFiscalEndDate(companyFiscalPeriodStartDate)
      );
    }
  }, [companyFiscalPeriodStartDate, setValue]);
  const submit = (values: FieldValues): void => {
    const apiHandler = entity
      ? companyApi.editCompany
      : companyApi.createCompany;

    apiHandler(values).then(
      (data: Company) => {
        if (!entity) {
          setShowModal(true);
        }
        setCompany(data);
        addToast({
          id: user.id,
          type: "success",
          message: t(
            `company.tabs.companyForm.${entity ? "editSuccess" : "addSuccess"}`
          ),
        });
      },
      (err) => errorHandler(err)
    );
  };

  return (
    <>
      <CreateEntityModal
        show={showModal}
        submitTitle={t("company.tabs.companyForm.createModal.createUser")}
        cancelTitle={t("company.tabs.companyForm.createModal.cancel")}
        onCancel={() => {
          navigate(`/${ROUTE_PATHS.companies}`);
        }}
        onSubmit={() =>
          navigate(`/${ROUTE_PATHS.companies}/company/${company?.id}/users`)
        }
        bodyData={t("company.tabs.companyForm.createModal.body", {
          id: company?.id8Digits,
        })}
        title={t("company.tabs.companyForm.createModal.title")}
      />
      <form onSubmit={handleSubmit(submit)}>
        <fieldset disabled={isReadonly}>
          <div className="company-form row gap-4">
            <div className="col">
              <FormInputComponent
                readonly={!isEditable}
                label={t("company.tabs.companyForm.name")}
                error={errors?.name?.message}
                inputProps={register("name", {
                  required: {
                    value: true,
                    message: t("general.form.validation.required"),
                  },
                })}
                property="name"
              />
              <div className="mb-3">
                <label htmlFor="type" className="form-label">
                  {t("company.tabs.companyForm.type")}
                </label>
                <select
                  className="form-select"
                  disabled={!isEditable}
                  id="type"
                  {...register("companyType")}
                >
                  {Object.entries(TYPES_TO_UI).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
              <FormInputComponent
                readonly={!isEditable}
                label={t("company.tabs.companyForm.identifier")}
                inputProps={register("uid", {
                  pattern: {
                    value: /^[0-9-]+$/,
                    message: t("general.form.validation.numbersWithHyphens"),
                  },
                  required: {
                    value: true,
                    message: t("general.form.validation.required"),
                  },
                })}
                property="uid"
                error={errors?.uid?.message}
              />
              <div className="mb-3">
                <label htmlFor="fiscalPeriod" className="form-label">
                  {t("company.tabs.companyForm.fiscalPeriod")}
                </label>
                <div className="fiscal-period gap-4">
                  <div>
                    <FormMonthPicker
                      firstDay={true}
                      control={control as unknown as Control<FieldValues, any>}
                      property="companyFiscalPeriodStartDate"
                      rules={{
                        required: {
                          value: true,
                          message: t("general.form.validation.required"),
                        },
                      }}
                    />
                  </div>
                  <div>
                    <FormMonthPicker
                      firstDay={false}
                      control={control as unknown as Control<FieldValues, any>}
                      property="companyFiscalPeriodEndDate"
                      rules={{
                        required: {
                          value: true,
                          message: t("general.form.validation.required"),
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  {t("company.tabs.companyForm.description")}
                </label>
                <textarea
                  readOnly={!isEditable}
                  className="form-control"
                  id="description"
                  {...register("description")}
                />
              </div>
            </div>
            <div className="col">
              <FormPhoneComponent
                disabled={!isEditable}
                error={errors?.phoneNumber?.message}
                value={phoneNumber}
                formProps={register("phoneNumber", {
                  pattern: {
                    value: PHONE_NUMBER_VALIDATOR,
                    message: t("general.form.validation.phone"),
                  },
                })}
              />

              <div className="mb-3">
                <label htmlFor="address" className="form-label">
                  {t("company.tabs.companyForm.address")}
                </label>
                <textarea
                  className="form-control"
                  id="address"
                  {...register("address")}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="country" className="form-label">
                  {t("company.tabs.companyForm.country")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  {...register("country")}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="state" className="form-label">
                  {t("company.tabs.companyForm.state")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="state"
                  {...register("state")}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="city" className="form-label">
                  {t("company.tabs.companyForm.city")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  {...register("city")}
                />
              </div>
              <FormInputComponent
                label={t("company.tabs.companyForm.postalCode")}
                inputProps={register("postalCode", {
                  pattern: {
                    value: /^(?=.*?[1-9])[0-9()-]+$/,
                    message: t("general.form.validation.numbers"),
                  },
                })}
                error={errors?.postalCode?.message}
                property="postalCode"
              />
            </div>
            <div className="col justify-content-between">
              <div>
                <div className="mb-3">
                  <label htmlFor="language" className="form-label">
                    {t("company.tabs.companyForm.language")}
                  </label>
                  <select
                    className="form-select"
                    disabled={!isEditable}
                    id="currency"
                    {...register("language")}
                  >
                    <option value={LANGUAGES.english}>
                      {LANGUAGES.english}
                    </option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="currency" className="form-label">
                    {t("company.tabs.companyForm.currency")}
                  </label>
                  <select
                    className="form-select"
                    disabled={!isEditable}
                    id="currency"
                    {...register("currencyName")}
                  >
                    <option value={CURRENCIES.usd}>{CURRENCIES.usd}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col">
              <FormDatePicker
                label={t("company.tabs.companyForm.auditTimestamp")}
                readonly={true}
                property="auditTrailDateStamp"
                inputProps={register("auditTrailDateStamp")}
              />
              <FormDatePicker
                label={t("company.tabs.companyForm.effectiveDate")}
                readonly={!isEditable}
                property="effectiveDate"
                inputProps={register("effectiveDate")}
              />
              <div className="mb-3 btn-group" role="group">
                <div className="form-check form-check-inline">
                  <input
                    disabled={!isEditable}
                    className="form-check-input"
                    type="radio"
                    id="type1"
                    {...register("license")}
                    value="Active"
                  />
                  <label className="form-check-label" htmlFor="typeParent1">
                    {t("company.tabs.companyForm.active")}
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="type2"
                    disabled={!isEditable}
                    {...register("license")}
                    value="Inactive"
                  />
                  <label className="form-check-label" htmlFor="typeSubsidiary">
                    {t("company.tabs.companyForm.inactive")}
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-3 right">
              <button
                className="btn me-3 border btn-secondary"
                onClick={() => navigate(`/${ROUTE_PATHS.companies}`)}
              >
                {t("company.tabs.companyForm.cancel")}
              </button>
              <button className="btn btn-primary" type="submit">
                {t(`company.tabs.companyForm.${entity ? "edit" : "submit"}`)}
              </button>
            </div>
          </div>
        </fieldset>
      </form>
    </>
  );
}
