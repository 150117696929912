export const getYearAndMonthFromDate = (
  dateString = ""
): string | undefined => {
  if (Date.parse(dateString)) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return `${year}-${month >= 10 ? month : `0${month}`}`;
  }
  return;
};
