import { TFunction } from "i18next";
import { DmsMasterFile } from "../../../interfaces/interfaces";
import { SortTypes } from "../../../interfaces/types";
import { DownloadButtonComponent } from "../../../components/shared/download-button/download-button.component";
import fileApi from "../../../api/file-api";
import { ROUTE_PATHS } from "../../../consts";
import React, { Dispatch, SetStateAction } from "react";

export const getColumns = ({
  t,
  companyId,
  toggleAll,
  setToggleAll,
  toggleFile,
}: {
  t: TFunction;
  companyId: string | undefined;
  toggleAll: boolean;
  setToggleAll: Dispatch<SetStateAction<boolean>>;
  toggleFile: (id: number) => void;
}): {
  header: string;
  property: keyof DmsMasterFile;
  sort?: boolean;
  sortType?: SortTypes;
  render?: (data: DmsMasterFile) => JSX.Element | string;
  renderHeader?: () => JSX.Element | string;
}[] => [
  {
    header: "",
    property: "selector",
    sort: false,
    renderHeader: () => (
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          checked={toggleAll}
          onChange={() => setToggleAll((prevState: boolean) => !prevState)}
        />
      </div>
    ),
    render: ({ selector, id }: DmsMasterFile) => (
      <div className="d-flex flex-fill">
        <div className="form-check mx-auto">
          <input
            className="form-check-input"
            type="checkbox"
            checked={selector}
            onChange={() => toggleFile(id)}
          />
        </div>
      </div>
    ),
  },
  {
    header: t("company.tabs.documents.downloads.list.name"),
    property: "name",
    sort: true,
    sortType: "string",
  },
  {
    header: t("company.tabs.documents.downloads.list.numberOfPages"),
    property: "numberOfPages",
    sort: true,
    sortType: "number",
  },
  {
    header: t("company.tabs.documents.downloads.list.version"),
    property: "version",
    sort: true,
    sortType: "number",
  },
  {
    header: t("company.tabs.documents.downloads.list.accountingPeriod"),
    property: "accountingPeriod",
    sort: true,
    sortType: "number",
  },
  {
    header: t("company.tabs.documents.downloads.list.fiscalYear"),
    property: "fiscalYear",
    sort: true,
    sortType: "number",
  },
  {
    header: t("company.tabs.documents.downloads.list.grantCode"),
    property: "grantCode",
    sort: true,
    sortType: "string",
  },
  {
    header: t("company.tabs.documents.downloads.list.programCode"),
    property: "programCode",
    sort: true,
    sortType: "string",
  },
  {
    header: t("company.tabs.documents.downloads.list.link.header"),
    property: "link",
    render: ({ name }: DmsMasterFile) => (
      <DownloadButtonComponent
        label={t("company.tabs.documents.downloads.list.link.label")}
        asLink={true}
        downloadHandler={() =>
          fileApi.downloadFile(
            `${ROUTE_PATHS.company}/${companyId}/document-management/download-file?fileName=${name}`
          )
        }
      />
    ),
  },
];
