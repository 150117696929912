import React from "react";
import { Controller, Control } from "react-hook-form";
import Select from "react-select";

interface FormControlledSelectComponentProps {
  label?: string;
  options?: Array<{ label: string; value: string }>;
  property: string;
  control: Control;
  disabled?: boolean;
}

export default function FormControlledSelectComponent({
  label,
  property,
  options = [],
  control,
  disabled = false,
}: FormControlledSelectComponentProps): JSX.Element {
  return (
    <Controller
      name={property}
      control={control}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        return (
          <div className="mb-3 form-field ">
            <label htmlFor={property} className="form-label">
              {label}
            </label>
            <Select
              {...field}
              isDisabled={disabled}
              components={{ IndicatorSeparator: null }}
              onChange={(newValue) => field.onChange(newValue?.value)}
              value={options.find((option) => option.value === field.value)}
              options={options}
            />
            {error && <div className="error">{error.message}</div>}
          </div>
        );
      }}
    />
  );
}
