import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import "./user-menu.component.scss";
import authApi from "../../../../api/auth-api";
import { UserContext } from "../../../../context/user.context";
import TemplatesModalComponent from "../templates-modal/templates-modal.component";

export default function UserMenu(): JSX.Element {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [showTemplatesModal, setShowTemplatesModal] = useState<boolean>(false);
  const currentUserRole = "Super Admin";
  const currentRole = "Company Admin";
  const ReadOnlyUser = "Read Only";

  const renderMenuItem = () => {
    if (currentUserRole === user.role) {
      return (
        <>
          <li>
            <a className="dropdown-item" href="/companies">
              {t("shared.navbar.userMenu.companies")}
            </a>
          </li>
          <li>
            <a className="dropdown-item" href="/users">
              {t("shared.navbar.userMenu.users")}
            </a>
          </li>
          <li>
            <a
              onClick={() => setShowTemplatesModal(true)}
              className="dropdown-item"
              href="#"
            >
              {t("shared.navbar.userMenu.getTemplates")}
            </a>
          </li>
          <li>
            <a onClick={authApi.logout} className="dropdown-item" href="#">
              {t("shared.navbar.userMenu.logout")}
            </a>
          </li>
        </>
      );
    } else if (ReadOnlyUser || currentRole === user.role) {
      return (
        <>
          <li>
            <a
              onClick={() => setShowTemplatesModal(true)}
              className="dropdown-item"
              href="#"
            >
              {t("shared.navbar.userMenu.getTemplates")}
            </a>
          </li>
          <li>
            <a onClick={authApi.logout} className="dropdown-item" href="#">
              {t("shared.navbar.userMenu.logout")}
            </a>
          </li>
        </>
      );
    }
  };
  return (
    <div className="gap-4 user-menu">
      <TemplatesModalComponent
        onClose={() => setShowTemplatesModal(false)}
        show={showTemplatesModal}
      />
      <span className="user-role">{user.firstName}</span>
      <div className="dropdown">
        <button
          className="btn btn-secondary"
          type="button"
          id="userMenu"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        />
        <ul
          className="dropdown-menu dropdown-menu-end"
          aria-labelledby="userMenu"
        >
          {renderMenuItem()}
        </ul>
      </div>
    </div>
  );
}
