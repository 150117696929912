import "./card.component.scss";
interface CardProps {
  children: JSX.Element;
  title?: string;
  header?: string;
}

export default function Card({ children, header, title }: CardProps) {
  return (
    <div className="card tile-custom text-dark bg-light mb-3">
      {header && <div className="card-header">{header}</div>}
      <div className="card-body">
        {title && <h5 className="card-title">{title}</h5>}
        {children}
      </div>
    </div>
  );
}
