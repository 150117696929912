import { useTranslation } from "react-i18next";
import { SpinnerButton } from "../../spinner/spinner-button";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import ErrorToFixModalComponent from "./error-to-fix-modal/error-modal.component";
import ErrorModalComponent from "../upload-button/error-modal/error-modal.component";
import { ChangeHandlerProps, Column, ValidationFileError } from "./interfaces";
import { CompanyContext } from "../../../../context/company-context";
import { useErrorHandler } from "../../../../hooks/useErrorHandler";
import { useUploadHook } from "./hooks/upload-hook";
import { useUploadResultData } from "./hooks/upload-result-hook";
import UploadResultModal from "./upload-result-modal/upload-result-modal.component";
import {
  UploadingError,
  UploadResponse,
} from "../../../../interfaces/interfaces";
import PreselectionModal from "./preselection-modal/preselection-modal.component";

interface UploadComponentProps {
  disabled?: boolean;
  showPreselection: boolean;
  postUploadEvent?: () => void;
  title?: string;
  errors: Record<string, string>;
  validateHandler: (
    file: FormData,
    companyId: string
  ) => Promise<ValidationFileError>;
  uploadHandler: (file: FormData, companyId: string) => Promise<UploadResponse>;
  config: Record<
    string,
    {
      staticProps: {
        subTitle: string;
        footerTitle: string;
        title: string;
      };
      columns: Column[];
      validateEntities: (entities: Record<string, string>[]) => boolean;
      saveEntities: ({
        data,
        companyId,
      }: {
        data: Record<string, string>[];
        companyId: string;
      }) => Promise<Record<string, string>[]>;
      checkIfErrorEmpty: (data: ValidationFileError) => boolean;
      getData: (data: ValidationFileError) => Record<string, string>[];
      getEntities: (
        data: ValidationFileError
      ) => Record<string, Record<string, string>>;
    }
  >;
}

export default function UploadComponent({
  disabled = false,
  showPreselection,
  postUploadEvent,
  config,
  errors,
  title,
  validateHandler,
  uploadHandler,
}: UploadComponentProps): JSX.Element {
  const {
    buttonRef,
    onFileUpload,
    ref,
    showPreselectionModal,
    setPreselectionModal,
    selection,
    setSelection,
  } = useUploadHook();

  const {
    uploadResultData,
    showUploadResultModal,
    setShowUploadResultModal,
    isUploadResults,
    setUploadResult,
    setIsUploadResults,
  } = useUploadResultData();
  const errorHandler = useErrorHandler();

  const { t } = useTranslation();

  const { company, chosenMonth } = useContext(CompanyContext);
  const activePeriod = useMemo(
    () => chosenMonth.split("/")?.[0],
    [chosenMonth]
  );
  const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false);
  const [failedUploadErrors, setFailedUploadErrors] = useState<
    Array<UploadingError>
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentError, setCurrentError] = useState<string>("");
  const [currentFormData, setCurrentFormData] = useState<FormData>();
  const [entitiesToSave, setEntitiesToSave] =
    useState<Record<string, Record<string, string>>>();

  const [show, setShow] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<string>("");

  const [errorModalData, setErrorModalData] =
    useState<Record<string, string>[]>();

  const changeHandler = useCallback(
    ({ value, property, id }: ChangeHandlerProps) => {
      setEntitiesToSave((state) => ({
        ...state,
        [id]: {
          ...(state?.[id] || {}),
          [property]: value,
        },
      }));
    },
    []
  );

  const disableSaving = useMemo(() => {
    const entities = Object.values(entitiesToSave || {});
    return config[currentError]?.validateEntities(entities);
  }, [config, currentError, entitiesToSave]);

  const upload = useCallback(
    (formData?: FormData) => {
      const data = formData || currentFormData;
      if (!data) {
        return;
      }
      setShowUploadResultModal(false);
      setIsLoading(true);
      setShow(false);
      uploadHandler(data, company.id)
        .then(
          (result: UploadResponse) => {
            if (result.status !== "ERROR") {
              setUploadResult(result);
              setIsUploadResults(true);
              setShowUploadResultModal(true);
            } else {
              setFailedUploadErrors(result.errors);
              setShowErrorsModal(true);
            }
          },
          (err) => {
            errorHandler(err);
          }
        )
        .finally(() => {
          setIsLoading(false);
        });
    },
    [
      company,
      currentFormData,
      errorHandler,
      setIsUploadResults,
      setShowUploadResultModal,
      setUploadResult,
      uploadHandler,
    ]
  );

  const validate = useCallback(
    (file: FormData | undefined = undefined) => {
      setConfirmation("");
      const formData = file || currentFormData;
      if (!formData) {
        return;
      }
      setShow(false);
      setEntitiesToSave(undefined);
      setCurrentError("");
      setIsLoading(true);
      validateHandler(formData, company.id).then(
        (response) => {
          const currentValidationError = Object.values(errors).find((error) =>
            config[error].checkIfErrorEmpty(response)
          );
          if (!currentValidationError) {
            upload(formData);
            return;
          }
          setCurrentError(currentValidationError); //check if needed
          setEntitiesToSave(
            config[currentValidationError].getEntities(response)
          );
          setErrorModalData(config[currentValidationError].getData(response));
          setShow(true);
        },
        (err) => {
          setIsLoading(false);
          if (err.errors) {
            setFailedUploadErrors(err.errors);
            setShowErrorsModal(true);
          }
        }
      );
    },
    [
      validateHandler,
      config,
      errors,
      company,
      currentFormData,
      setConfirmation,
      setEntitiesToSave,
      setShow,
      upload,
    ]
  );

  const getFileAndValidate = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const files = target?.files || [];
      const formData = new FormData();
      formData.append("file", files[0]);
      if (activePeriod) {
        formData.append("activePeriod", activePeriod);
      }
      if (selection) {
        formData.append("uploadType", selection);
      }
      setCurrentFormData(formData);
      validate(formData);
      target.value = "";
    },
    [activePeriod, validate, selection]
  );

  const addEntities = useCallback(() => {
    if (!entitiesToSave) {
      return;
    }
    if (!confirmation) {
      setConfirmation(t("company.tabs.gl.uploadModel.toastTitle"));
      return;
    }
    config[currentError]
      ?.saveEntities({
        data: Object.values(entitiesToSave),
        companyId: company.id,
      })
      .then(
        () => {
          validate();
        },
        (err) => {
          errorHandler(err);
        }
      );
  }, [
    config,
    company,
    confirmation,
    currentError,
    entitiesToSave,
    errorHandler,
    setConfirmation,
    t,
    validate,
  ]);

  return (
    <>
      <PreselectionModal
        show={showPreselectionModal}
        onClose={() => setPreselectionModal(false)}
        selection={selection}
        setSelection={setSelection}
        onUploadProceed={onFileUpload}
      />
      <button
        ref={buttonRef}
        disabled={disabled || isLoading}
        style={{ color: isLoading ? "#000" : "#fff" }}
        onClick={() =>
          showPreselection ? setPreselectionModal(true) : ref.current?.click()
        }
        type="button"
        className="btn btn-primary"
      >
        <input ref={ref} hidden type="file" onChange={getFileAndValidate} />
        {title || t("general.upload")}
        {isLoading && <SpinnerButton target={buttonRef.current} />}
      </button>
      <UploadResultModal
        show={showUploadResultModal}
        isUploadResults={isUploadResults}
        submitHandler={upload}
        data={uploadResultData}
        cancelHandler={() => {
          setShowUploadResultModal(false);
          setIsLoading(false);
        }}
        reloadData={postUploadEvent}
      />
      <ErrorModalComponent
        show={showErrorsModal}
        onClose={() => setShowErrorsModal(false)}
        errors={failedUploadErrors}
      />
      <ErrorToFixModalComponent
        {...config[currentError]?.staticProps}
        showModal={show}
        changeHandler={changeHandler}
        columns={config[currentError]?.columns || []}
        data={errorModalData || []}
        submitHandler={addEntities}
        cancelHandler={() => {
          setShowUploadResultModal(true);
          setShow(false);
          setIsUploadResults(false);
          setIsLoading(false);
        }}
        disabled={disableSaving}
        toastMessage={confirmation}
      />
    </>
  );
}
