import { AxiosResponse } from "axios";
import { httpClient } from "../core/http-client/http-client";
import { ControlCenterJobFunction } from "../interfaces/interfaces";

const editCompanySetup = async (
  companyId: string | undefined,
  data: Record<string, string>
): Promise<Record<string, string>> => {
  return httpClient
    .post(`company/${companyId}/control-center`, data)
    .then((response: AxiosResponse) => response.data);
};

const getOpenCloseChecklist = async (
  companyId: string,
  chosenMonth: string
): Promise<Record<string, number>> => {
  return httpClient
    .get(`company/${companyId}/check-list?activePeriod=${chosenMonth}`)
    .then((response: AxiosResponse) => response.data);
};

async function getSystemAllocationMCOAs(
  companyId: string
): Promise<Record<string, string>> {
  return httpClient
    .get(
      `company/${companyId}/control-center/masterchart-accounts/system-allocation`
    )
    .then((response: AxiosResponse): Record<string, string> => response.data);
}

async function getProgramExpenseMCOAs(
  companyId: string
): Promise<Record<string, string>> {
  return httpClient
    .get(
      `company/${companyId}/control-center/masterchart-accounts/program-expense`
    )
    .then((response: AxiosResponse): Record<string, string> => response.data);
}

async function getJobFunctionsAcronyms(
  companyId: string
): Promise<ControlCenterJobFunction[]> {
  return httpClient
    .get(`company/${companyId}/control-center/job-functions/fringe-benefits`)
    .then(
      (response: AxiosResponse): ControlCenterJobFunction[] => response.data
    );
}

const controlCenterApi = {
  editCompanySetup,
  getOpenCloseChecklist,
  getJobFunctionsAcronyms,
  getSystemAllocationMCOAs,
  getProgramExpenseMCOAs,
};

export default controlCenterApi;
