import React, { useCallback, useContext, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { USER_ROLE_LABELS, USER_ROLES } from "../../consts";
import { User } from "../../interfaces/interfaces";
import companyApi from "../../api/company-api";
import userApi from "../../api/user-api";
import { CompanyContext } from "../../context/company-context";
import { ToastContext } from "../../context/toast.context";
import { UserContext } from "../../context/user.context";
import { useLoadData } from "../../hooks/useLoadData";
import Modal from "../../components/shared/modal/modal.component";
import ConfirmationModalComponent from "../../components/shared/confirmation-modal/confirmation-modal.component";
import Table from "../../components/shared/table/table.component";
import Spinner from "../../components/shared/spinner/spinner.component";
import UserForm from "../../components/forms/user-form/user-form.component";
import "./user.details.component.scss";

export default function UserDetailsComponent(): JSX.Element | null {
  const { company } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const { addToast } = useContext(ToastContext);
  const { t } = useTranslation();
  const isReadonly = useMemo(
    () => user.role === USER_ROLE_LABELS[USER_ROLES.ROLE_USER],
    [user.role]
  );
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [showInviteConfirmation, setShowInviteConfirmation] =
    useState<boolean>(false);
  const [companyUsers, isLoading, loadCompanyUsers] = useLoadData<User[]>({
    fetcher: useCallback(
      () => companyApi.getCompanyUsers(company.id),
      [company]
    ),
    transformer: useCallback(
      (users: User[]) =>
        users.map((companyUser: User) => ({
          ...companyUser,
          invite: companyUser.inviteSent
            ? t("companies.inviteSent")
            : t("companies.inviteNotSent"),
        })),
      [t]
    ),
  });
  const canEditSelectedUser = useMemo(() => {
    if (!currentUser) return true;
    if (user.role === USER_ROLE_LABELS[USER_ROLES.ROLE_ADMIN]) return true;
    if (
      user.role === USER_ROLE_LABELS[USER_ROLES.ROLE_COMPANY_ADMIN] &&
      currentUser.role === USER_ROLE_LABELS[USER_ROLES.ROLE_ADMIN]
    )
      return false;
    return true;
  }, [currentUser, user]);
  const sendInvite = useCallback(
    (userId: string | undefined): void => {
      if (!showInviteConfirmation && currentUser && currentUser.inviteSent) {
        setShowInviteConfirmation(true);
        setOpenForm(false);
        return;
      }
      userApi
        .sendInvite(userId)
        .then(
          () => {
            addToast({
              id: currentUser?.id,
              type: "success",
              message: t("users.userForm.sendInviteSuccess"),
            });
            loadCompanyUsers();
          },
          () =>
            addToast({
              id: currentUser?.id,
              type: "error",
              message: t("users.userForm.sendInviteError"),
            })
        )
        .finally(() => {
          setOpenForm(false);
          setShowInviteConfirmation(false);
          setCurrentUser(null);
        });
    },
    [showInviteConfirmation, currentUser, addToast, t, loadCompanyUsers]
  );

  const COLUMNS: {
    header: string;
    property: keyof User;
    disabledClick?: boolean;
    sort?: boolean;
    render?: (row: User) => JSX.Element | string;
  }[] = [
    {
      header: t("company.tabs.user-details.list.id"),
      property: "id",
      sort: true,
    },
    {
      header: t("company.tabs.user-details.list.name"),
      property: "firstName",
      sort: true,
    },
    {
      header: t("company.tabs.user-details.list.email"),
      property: "email",
      sort: true,
    },
    {
      header: t("company.tabs.user-details.list.role"),
      property: "role",
      sort: true,
    },
    {
      header: t("company.tabs.user-details.list.invite"),
      property: "invite",
      sort: true,
    },
    {
      header: "",
      disabledClick: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      property: "",
      render: (): JSX.Element => (
        <a
          className={`text-decoration-none ${
            !isReadonly ? "link-primary" : "link-dark"
          }`}
        >
          {t("general.disable")}
        </a>
      ),
    },
  ];

  return (
    <>
      <ConfirmationModalComponent
        title={t("users.userForm.reSendInviteBody", { id: currentUser?.id })}
        show={showInviteConfirmation}
        onSubmit={() => sendInvite(currentUser?.id)}
        onClose={() => {
          setShowInviteConfirmation(false);
          setCurrentUser(null);
        }}
        submitTitle={t("users.userForm.reSendInvite")}
      />
      <Modal
        centredContent={false}
        className="user-form-modal"
        show={openForm}
        title={t("users.userForm.formTitle")}
        onClose={() => {
          setOpenForm(false);
          setCurrentUser(null);
        }}
      >
        <UserForm
          sendInvite={!isReadonly ? sendInvite : undefined}
          readonly={
            (isReadonly || !canEditSelectedUser) && currentUser?.id !== user.id
          }
          entity={currentUser}
          allowSuperAdminRole={false}
          getUsers={loadCompanyUsers}
          key={currentUser?.id}
          onClose={() => {
            setOpenForm(false);
            setCurrentUser(null);
          }}
        />
      </Modal>
      {isLoading ? (
        <Spinner size="medium" />
      ) : (
        <>
          {!isReadonly && (
            <div className="header pb-2 px-3">
              <button
                onClick={() => setOpenForm(true)}
                className="btn btn-primary"
              >
                {t("general.add")}
              </button>
            </div>
          )}
          <Table
            search={true}
            columns={COLUMNS}
            data={companyUsers}
            {...(!isReadonly
              ? {
                  onRowClick: (row: User) => {
                    setCurrentUser(row);
                    setOpenForm(true);
                  },
                }
              : {})}
          />
        </>
      )}
    </>
  );
}
