import { RELOAD_UPLOAD_TYPE } from "../../../../../consts";
import Modal from "../../../modal/modal.component";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

interface PreselectionModalProps {
  show: boolean;
  onClose: () => void;
  selection: string;
  setSelection: Dispatch<SetStateAction<string>>;
  onUploadProceed: () => void;
}
export default function PreselectionModal({
  show,
  onClose,
  selection,
  setSelection,
  onUploadProceed,
}: PreselectionModalProps): JSX.Element {
  const { t } = useTranslation();
  const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
  const onCancel = useCallback(() => {
    onClose();
    setSelection("");
    setIsConfirmation(false);
  }, [onClose, setSelection]);

  const onSubmit = useCallback(() => {
    if (!isConfirmation) {
      setIsConfirmation(true);
      return;
    }
    onUploadProceed();
    setIsConfirmation(false);
  }, [onUploadProceed, isConfirmation, setIsConfirmation]);
  return (
    <Modal
      title={t("reloadModal.title")}
      show={show}
      {...(isConfirmation && { subtitle: t("reloadModal.subtitle") })}
      Footer={
        <div className="gap-3 confirmation-footer footer">
          <button onClick={onCancel} className="btn btn-secondary">
            {t("general.cancel")}
          </button>
          <button
            onClick={onSubmit}
            className="btn btn-primary"
            disabled={!selection}
          >
            {isConfirmation ? t("reloadModal.approve") : t("general.ok")}
          </button>
        </div>
      }
    >
      <div className="mt-2 d-flex flex-column justify-content-center align-items-center">
        <p>{t("reloadModal.header")}</p>
        <div className="ms-2">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="reloadType"
              id="reloadModalNew"
              disabled={isConfirmation}
              onClick={() => setSelection(RELOAD_UPLOAD_TYPE.NEW)}
              checked={selection === RELOAD_UPLOAD_TYPE.NEW}
            />
            <label className="form-check-label" htmlFor="reloadModalNew">
              {t("reloadModal.radioLabelNew")}
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="reloadType"
              id="reloadModalAll"
              disabled={isConfirmation}
              onClick={() => setSelection(RELOAD_UPLOAD_TYPE.ALL)}
              checked={selection === RELOAD_UPLOAD_TYPE.ALL}
            />
            <label className="form-check-label" htmlFor="reloadModalAll">
              {t("reloadModal.radioLabelAll")}
            </label>
          </div>
        </div>
      </div>
    </Modal>
  );
}
