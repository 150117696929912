export const PERMISSIONS = {
  user: "ROLE_USER",
  companyAdmin: "ROLE_COMPANY_ADMIN",
  admin: "ROLE_ADMIN",
};
export const COMPANY_TYPES = {
  standAlone: "standAlone",
  parent: "parent",
  affiliate: "affiliate",
  serviceProvider: "serviceProvider",
};

export const TYPES_TO_UI = {
  STANDALONE: "Stand-alone",
  PARENT: "Parent",
  AFFILIATES: "Affiliates",
  SERVICE_PROVIDER: "Service Provider",
};

export const CURRENCIES = {
  usd: "USD",
};

export const LANGUAGES = {
  english: "English",
};

export const COMPANY_PARTS = {
  dashboard: "dashboard",
  details: "details",
  users: "users",
  mcoa: "mcoa",
  programs: "programs",
  jobFunctions: "job-functions",
  billingAllocationMethod: "control-center",
  grants: "grants",
  gl: "gl",
  payrollRegister: "payrollRegister",
  documents: "documents",
};

export const SUPER_ROUTES = [
  {
    key: COMPANY_PARTS.details,
    permissionsMatcher: (userPermissions: [string] = [""]) =>
      !userPermissions.includes(PERMISSIONS.user),
  },
  {
    key: COMPANY_PARTS.dashboard,
    permissionsMatcher: (userPermissions: [string] = [""]) =>
      !userPermissions.includes(PERMISSIONS.user),
  },
  {
    key: COMPANY_PARTS.users,
    permissionsMatcher: (userPermissions: [string] = [""]) =>
      !userPermissions.includes(PERMISSIONS.user),
  },
  {
    key: COMPANY_PARTS.mcoa,
    permissionsMatcher: (userPermissions: [string] = [""]) =>
      !userPermissions.includes(PERMISSIONS.user),
  },
];
