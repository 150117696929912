import React, { useContext, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  DmsFindInvoicesResult,
  DmsGlTransaction,
} from "../../../interfaces/interfaces";
import documentsApi from "../../../api/documents-api";
import { CompanyContext } from "../../../context/company-context";
import { useLoadData } from "../../../hooks/useLoadData";
import ActionHeaderComponent from "./action-header/action-header.component";
import Modal from "../../../components/shared/modal/modal.component";
import Table from "../../../components/shared/table/table.component";
import Spinner from "../../../components/shared/spinner/spinner.component";
import "./list.component.scss";
import { formatDate } from "../../../utils";
import { DownloadButtonComponent } from "../../../components/shared/download-button/download-button.component";
import fileApi from "../../../api/file-api";
import { ROUTE_PATHS } from "../../../consts";

export function DocumentList() {
  const { t } = useTranslation();
  const { company, chosenMonth } = useContext(CompanyContext);
  const [selectedGrant, setSelectedGrant] = useState("");
  const [findInvoicesResult, setFindInvoicesResult] = useState<
    DmsFindInvoicesResult | undefined
  >(undefined);

  const fName = findInvoicesResult?.namesOfCreatedMergedFiles[0];
  const [grantsByPeriod, areGrantsLoading] = useLoadData({
    fetcher: useCallback(
      () => documentsApi.getPeriodsToGrants(company.id, chosenMonth),
      [company, chosenMonth]
    ),
  });
  const [glTransactions, areGLsLoading, reloadGLs] = useLoadData<
    DmsGlTransaction[]
  >({
    fetcher: useCallback(
      () =>
        documentsApi.getTransactions(company.id, chosenMonth, selectedGrant),
      [company.id, chosenMonth, selectedGrant]
    ),
  });

  const isLoading = useMemo(() => areGrantsLoading, [areGrantsLoading]);
  const COLUMNS = useMemo(
    () => [
      {
        header: t("company.tabs.documents.list.accountingPeriod"),
        property: "accountingPeriod",
      },
      {
        header: t("company.tabs.documents.list.grantCode"),
        property: "grantCode",
        sort: true,
        sortType: "number",
      },
      {
        header: t("company.tabs.documents.list.transactionId"),
        property: "transanctionId",
      },
      {
        header: t("company.tabs.documents.list.transactionDate"),
        property: "transanctionDate",
        format: (value: string | JSX.Element) => {
          if (typeof value === "string") {
            return formatDate({ dateString: value });
          }
          return "";
        },
      },
      {
        header: t("company.tabs.documents.list.glAccount"),
        property: "accountCode",
      },
      {
        header: t("company.tabs.documents.list.jeDesc"),
        property: "description",
        sort: true,
        sortType: "string",
      },
      {
        header: t("company.tabs.documents.list.amount"),
        property: "amount",
        sort: true,
        sortType: "number",
      },
      {
        header: t("company.tabs.documents.list.vendorName"),
        property: "vendorName",
        sort: true,
        sortType: "string",
      },
      {
        header: t("company.tabs.documents.list.vendorId"),
        property: "vendorCode",
        sort: true,
        sortType: "string",
      },
      {
        header: t("company.tabs.documents.list.invoiceId"),
        property: "invoiceNumber",
      },
      {
        header: t("company.tabs.documents.list.filename"),
        property: "fileName",
      },
      {
        header: t("company.tabs.documents.list.uploadStatus"),
        property: "pageNumberInMergedFile",
        render: ({
          pageNumberInMergedFile,
        }: {
          pageNumberInMergedFile: string | null;
        }) =>
          pageNumberInMergedFile
            ? `Success: page ${pageNumberInMergedFile}`
            : "Missing invoice file",
      },
    ],
    [t]
  );

  return isLoading ? (
    <Spinner size="medium" />
  ) : (
    <div className="documents-page">
      <Modal
        size="medium"
        title={t("general.uploadStatus")}
        show={!!findInvoicesResult}
        Footer={
          <div className="footer">
            <button
              onClick={() => setFindInvoicesResult(undefined)}
              className="btn btn-primary px-5"
            >
              {t("general.ok")}
            </button>
          </div>
        }
      >
        <div className="d-flex flex-column gap-1">
          {!!findInvoicesResult?.namesOfValidatedFiles && (
            <div className="d-flex gap-3">
              <div>
                <p>
                  <b>
                    {findInvoicesResult?.numberOfValidatedFiles +
                      findInvoicesResult?.numberOfMissingTransactions}
                  </b>
                  &nbsp; transactions total
                </p>
              </div>
            </div>
          )}

          {!!findInvoicesResult?.namesOfCreatedMergedFiles.length && (
            <div className="d-flex gap-3">
              <div>
                <p>
                  <b>{findInvoicesResult?.numberOfValidatedFiles}</b>
                  &nbsp; transactions have matching invoices
                </p>
              </div>
            </div>
          )}

          {!!findInvoicesResult?.numberOfMissingTransactions && (
            <div className="d-flex gap-3">
              <div>
                <p>
                  <b style={{ color: "red" }}>
                    {findInvoicesResult?.numberOfMissingTransactions}
                  </b>
                  &nbsp; transactions are missing invoices, and will be denoted
                  with
                  <span style={{ color: "red" }}>
                    "Missing invoice file"
                  </span>{" "}
                  in the "UPLOAD STATUS" column
                </p>
              </div>
            </div>
          )}
          <div className="d-flex gap-3">
            <div>
              <p>
                <DownloadButtonComponent
                  label="Click here"
                  asLink={true}
                  downloadHandler={() =>
                    fileApi.downloadFile(
                      `${ROUTE_PATHS.company}/${company.id}/document-management/download-file?fileName=${fName}`
                    )
                  }
                />
                to download the file
              </p>
            </div>
          </div>
        </div>
      </Modal>
      <div>
        <ActionHeaderComponent
          grantsByPeriod={grantsByPeriod}
          selectedPeriod={chosenMonth}
          selectedGrant={selectedGrant}
          setSelectedGrant={setSelectedGrant}
          setUploadResult={setFindInvoicesResult}
          reloadGLs={reloadGLs}
        />
        {glTransactions.length ? (
          areGLsLoading ? (
            <Spinner size="medium" />
          ) : (
            <Table
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              columns={COLUMNS}
              data={glTransactions}
              search={true}
            />
          )
        ) : (
          <div className="empty-table flex-fill justify-content-center align-items-center">
            {t("general.filteredDataMessage")}
          </div>
        )}
      </div>
      {/*//   todo Yuriy ask if this is crucial */}
      {/*// ) : (*/}
      {/*//   <div className="empty-table flex-fill justify-content-center align-items-center">*/}
      {/*//     <p>{t("company.tabs.documents.emptyList")}</p>*/}
      {/*//   </div>*/}
    </div>
  );
}
