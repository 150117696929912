import "./company-setup.component.scss";
import Card from "../../../components/shared/card/card.component";
import { useTranslation } from "react-i18next";
import CompanySetupForm from "../../../components/forms/company-setup/company-setup.component";
import { useCallback, useContext } from "react";
import controlCenterApi from "../../../api/control-center";
import { CompanyContext } from "../../../context/company-context";
import { ToastContext } from "../../../context/toast.context";
import { useErrorHandler } from "../../../hooks/useErrorHandler";

export default function CompanySetup() {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  const { addToast } = useContext(ToastContext);
  const errorHandler = useErrorHandler();
  const submit = useCallback(
    (data: Record<string, string>) => {
      controlCenterApi.editCompanySetup(company.id, data).then(
        () => {
          addToast({
            id: company.id,
            type: "success",
            message: t("company.tabs.control-center.company-setup.editSuccess"),
          });
        },
        (err) => errorHandler(err)
      );
    },
    [addToast, company, errorHandler, t]
  );
  return (
    <Card header={t("company.tabs.control-center.allocationSetup")}>
      <CompanySetupForm submit={submit} />
    </Card>
  );
}
