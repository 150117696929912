import { createContext, useCallback, useState } from "react";
import { ToastTypes } from "../interfaces/types";
import "./toast.scss";

const DEFAULT_TIMEOUT = 10000;

interface MessageInterface {
  id: string;
  type: ToastTypes;
  message: string;
}

interface ToastContextInterface {
  // fix all ts errors when change any to MessageInterface
  addToast: (message: any, timeout?: number) => void;
  removeToast: (messageId: string) => void;
}

interface ToastProviderInterface {
  children: React.ReactNode;
}

const ToastContext = createContext({} as ToastContextInterface);

const ToastProvider = ({ children }: ToastProviderInterface) => {
  const [messages, setMessage] = useState<Array<MessageInterface>>([]);

  const removeToast = useCallback((messageId: string): void => {
    setMessage(
      (state: Array<MessageInterface>): Array<MessageInterface> =>
        state.filter(({ id }) => id !== messageId)
    );
  }, []);

  const addToast = useCallback(
    (message: MessageInterface, timeout: number = DEFAULT_TIMEOUT): void => {
      setMessage((state: Array<MessageInterface>): Array<MessageInterface> => {
        const filteredArray = state.filter(({ id }) => id !== message.id);
        return [...filteredArray, message];
      });
      setTimeout((): void => {
        setMessage((state: Array<MessageInterface>) =>
          state.filter((item) => item.id !== message.id)
        );
      }, timeout);
    },
    []
  );
  return (
    <ToastContext.Provider
      value={{
        addToast,
        removeToast,
      }}
    >
      <div className="toast-container position-fixed top start-0 p-1 w-100">
        {messages.map(
          ({ id, type, message }): JSX.Element => (
            <div
              key={id}
              className={`toast show align-items-center w-100 ${type}`}
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div className="d-flex">
                <div className={`toast-body`}>{message}</div>
                <button
                  type="button"
                  className="btn-close me-2 m-auto"
                  aria-label="Close"
                  onClick={() => removeToast(id)}
                />
              </div>
            </div>
          )
        )}
      </div>
      {children}
    </ToastContext.Provider>
  );
};

export { ToastProvider, ToastContext };
