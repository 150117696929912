import { useCallback, useRef, useState } from "react";

export const useUploadHook = () => {
  const [showPreselectionModal, setPreselectionModal] =
    useState<boolean>(false);
  const [selection, setSelection] = useState<string>("");
  const ref = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const onFileUpload = useCallback(() => {
    ref.current?.click();
    setPreselectionModal(false);
  }, []);
  return {
    buttonRef,
    onFileUpload,
    ref,
    showPreselectionModal,
    setPreselectionModal,
    selection,
    setSelection,
  };
};
