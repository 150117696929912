import { useTranslation } from "react-i18next";
import { useState, useMemo } from "react";
import { isEmpty, sum } from "lodash";
import { TFunction } from "i18next";
import { UploadResponse } from "../../../../../interfaces/interfaces";

const transformObjectToDisplay = (
  object: Record<string, number>,
  t: TFunction,
  type: string
) =>
  isEmpty(object)
    ? {}
    : Object.entries(object).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [t(`company.tabs.gl.uploadModel.errorName.${type}`, { code: key })]:
            value,
        }),
        {}
      );

export const useUploadResultData = () => {
  const { t } = useTranslation();
  const [isUploadResults, setIsUploadResults] = useState<boolean>(false);
  const [uploadResult, setUploadResult] = useState<UploadResponse>(
    {} as UploadResponse
  );
  const [showUploadResultModal, setShowUploadResultModal] =
    useState<boolean>(false);
  const uploadResultData: Record<string, number> | undefined = useMemo(() => {
    if (!uploadResult) {
      return undefined;
    }
    const {
      numberOfRowsImported,
      numberOfFailedRows,
      accountCodeToNumberOfFailedRows,
      programCodeToNumberOfFailedRows,
      grantCodeToNumberOfFailedRows,
      totalAmount,
    } = uploadResult;
    const failedRows = {
      ...transformObjectToDisplay(
        accountCodeToNumberOfFailedRows,
        t,
        "account"
      ),
      ...transformObjectToDisplay(
        programCodeToNumberOfFailedRows,
        t,
        "program"
      ),
      ...transformObjectToDisplay(grantCodeToNumberOfFailedRows, t, "grant"),
    };
    const otherFailed = numberOfFailedRows - sum(Object.values(failedRows));
    return {
      numberOfRowsImported,
      numberOfFailedRows,
      totalAmount,
      ...failedRows,
      [t("company.tabs.gl.uploadModel.errorName.other")]: otherFailed,
    };
  }, [t, uploadResult]);
  return {
    uploadResultData,
    showUploadResultModal,
    setShowUploadResultModal,
    isUploadResults,
    setUploadResult,
    setIsUploadResults,
  };
};
