import {
  Column,
  ValidationFileError,
} from "../../../components/shared/upload/upload-validation-component/interfaces";
import { FUNCTION_TYPES, PROGRAM_TYPES } from "../../../consts";
import programsApi from "../../../api/programs-api";
import jobFunctionsApi from "../../../api/job-functions-api";

export const ERRORS = {
  jobFunction: "notValidJobFunctions",
  programs: "notValidPrograms",
};

export const getJobFunctionColumns = (
  regions: Array<string>
): Array<Column> => [
  {
    header: "company.tabs.jobFunctions.list.acronym",
    property: "acronym",
    type: "text",
  },
  {
    header: "company.tabs.jobFunctions.list.description",
    property: "description",
    type: "input",
  },
  {
    header: "company.tabs.jobFunctions.list.region",
    property: "region",
    type: "selectWithCreate",
    options: regions.map((value) => ({
      value,
      label: value,
    })),
  },
  {
    header: "company.tabs.jobFunctions.list.type",
    property: "type",
    type: "select",
    options: Object.values(FUNCTION_TYPES).map((value) => ({
      value,
      label: value,
    })),
  },
];

export const getProgramColumns = (): Array<Column> => [
  {
    header: "company.tabs.programs.list.header.id",
    property: "code",
    type: "text",
  },
  {
    header: "company.tabs.programs.list.header.name",
    property: "name",
    type: "input",
  },
  {
    header: "company.tabs.programs.form.departmentType",
    property: "category",
    type: "select",
    options: Object.values(PROGRAM_TYPES).map((value) => ({
      value,
      label: value,
    })),
  },
];

export const getJobFunctionProps = (): {
  subTitle: string;
  footerTitle: string;
  title: string;
} => ({
  footerTitle:
    "company.tabs.payrollRegister.uploadModel.jobFunction.footerTitle",
  title: "company.tabs.payrollRegister.uploadModel.jobFunction.title",
  subTitle: "company.tabs.payrollRegister.uploadModel.jobFunction.subTitle",
});

export const getProgramProps = (): {
  subTitle: string;
  footerTitle: string;
  title: string;
} => ({
  footerTitle: "company.tabs.gl.uploadModel.program.footerTitle",
  title: "company.tabs.gl.uploadModel.program.title",
  subTitle: "company.tabs.gl.uploadModel.program.subTitle",
});

export const validateJobFunctionEntity = (
  jobFunction: Record<string, string>
): boolean => {
  const fields = ["acronym", "description", "region", "type"];
  return fields.every((item) => jobFunction[item]);
};

export const validateProgramEntity = (
  account: Record<string, string>
): boolean => {
  const fields = ["code", "name", "category"];
  return fields.every((item) => account[item]);
};

export const CONFIG = {
  [ERRORS.jobFunction]: {
    staticProps: getJobFunctionProps(),
    columns: getJobFunctionColumns([]),
    validateEntities: (entities: Record<string, string>[]) =>
      entities.some((entity) => !validateJobFunctionEntity(entity)),
    saveEntities: ({
      data,
      companyId,
    }: {
      data: Record<string, string>[];
      companyId: string;
    }) => jobFunctionsApi.createJobFunctionsBulk({ companyId, data: data }),
    checkIfErrorEmpty: (response: ValidationFileError) =>
      !!Object.keys(response.notValidJobFunctions).length,
    getData: (response: ValidationFileError) =>
      Object.entries(response.notValidJobFunctions).map(
        ([acronym, description]) => ({
          id: acronym,
          acronym,
          description,
        })
      ),
    getEntities: (response: ValidationFileError) =>
      Object.entries(response.notValidJobFunctions).reduce(
        (acc, [acronym, description]) => ({
          ...acc,
          [acronym]: { acronym, description },
        }),
        {}
      ),
  },
  [ERRORS.programs]: {
    staticProps: getProgramProps(),
    columns: getProgramColumns(),
    validateEntities: (entities: Record<string, string>[]) =>
      entities.some((entity) => !validateProgramEntity(entity)),
    saveEntities: ({
      data,
      companyId,
    }: {
      data: Record<string, string>[];
      companyId: string;
    }) => programsApi.createProgramsBulk(companyId, data),
    checkIfErrorEmpty: (response: ValidationFileError) =>
      !!response.notValidPrograms.length,
    getData: (response: ValidationFileError) =>
      response.notValidPrograms.map((code: string) => ({
        code,
        id: code,
      })),
    getEntities: (response: ValidationFileError) =>
      response.notValidPrograms.reduce(
        (acc, code) => ({
          ...acc,
          [code]: { code },
        }),
        {}
      ),
  },
};
