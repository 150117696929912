import { ReloadModalValue } from "../interfaces/types";

export * from "./colors";
export * from "./company";
export * from "./toast";
export * from "./forms";
export * from "./jobFunction";
export * from "./mcoa";
export * from "./program";
export * from "./table";
export * from "./grants";

export const PERMISSIONS = {
  user: "ROLE_USER",
  companyAdmin: "ROLE_COMPANY_ADMIN",
  admin: "ROLE_ADMIN",
};

export const USER_ROLES = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_COMPANY_ADMIN: "ROLE_COMPANY_ADMIN",
  ROLE_USER: "ROLE_USER",
  ROLE_SERVICE_PROVIDER: "ROLE_SERVICE_PROVIDER",
};

export const USER_ROLE_LABELS = {
  [USER_ROLES.ROLE_ADMIN]: "Super Admin",
  [USER_ROLES.ROLE_COMPANY_ADMIN]: "Company Admin",
  [USER_ROLES.ROLE_USER]: "Read Only",
};

export const USERS_ROLES_TO_DTO = {
  [USER_ROLE_LABELS[USER_ROLES.ROLE_ADMIN]]: USER_ROLES.ROLE_ADMIN,
  [USER_ROLE_LABELS[USER_ROLES.ROLE_COMPANY_ADMIN]]:
    USER_ROLES.ROLE_COMPANY_ADMIN,
  [USER_ROLE_LABELS[USER_ROLES.ROLE_USER]]: USER_ROLES.ROLE_USER,
};

export const ROUTE_PATHS = {
  dashboard: "dashboard",
  company: "company",
  companies: "companies",
  userDetails: "user-details",
  mcoa: "mcoa",
  programs: "programs",
  jobFunctions: "job-functions",
  grants: "grants",
  billingAllocation: "control-center",
  gl: "gl",
  payroll: "payroll",
  documentManagement: "documents",
  users: "users",
  login: "login",
  forgotPassword: "forgot-password",
  updatePassword: "update-password",
};

export const ROUTES = [
  {
    key: ROUTE_PATHS.dashboard,
    permissionsMatcher: (userPermissions: [string] = [""]) =>
      !userPermissions.includes(PERMISSIONS.admin),
  },
  {
    key: ROUTE_PATHS.company,
    permissionsMatcher: (userPermissions: [string] = [""]) =>
      !userPermissions.includes(PERMISSIONS.admin),
  },
  {
    key: ROUTE_PATHS.userDetails,
    permissionsMatcher: (userPermissions: [string] = [""]) =>
      !userPermissions.includes(PERMISSIONS.admin),
  },
  {
    key: ROUTE_PATHS.mcoa,
    permissionsMatcher: (userPermissions: [string] = [""]) =>
      !userPermissions.includes(PERMISSIONS.admin),
  },
  {
    key: ROUTE_PATHS.programs,
    permissionsMatcher: (userPermissions: [string] = [""]) =>
      !userPermissions.includes(PERMISSIONS.admin),
  },
  {
    key: ROUTE_PATHS.jobFunctions,
    permissionsMatcher: (userPermissions: [string] = [""]) =>
      !userPermissions.includes(PERMISSIONS.admin),
  },
  {
    key: ROUTE_PATHS.billingAllocation,
    permissionsMatcher: (userPermissions: [string] = [""]) =>
      !userPermissions.includes(PERMISSIONS.admin),
  },
  {
    key: ROUTE_PATHS.grants,
    permissionsMatcher: (userPermissions: [string] = [""]) =>
      !userPermissions.includes(PERMISSIONS.admin),
  },
  {
    key: ROUTE_PATHS.gl,
    permissionsMatcher: (userPermissions: [string] = [""]) =>
      !userPermissions.includes(PERMISSIONS.admin),
  },
  {
    key: ROUTE_PATHS.payroll,
    permissionsMatcher: (userPermissions: [string] = [""]) =>
      !userPermissions.includes(PERMISSIONS.admin),
  },
  {
    key: ROUTE_PATHS.documentManagement,
    permissionsMatcher: (userPermissions: [string] = [""]) =>
      !userPermissions.includes(PERMISSIONS.admin),
  },
];

export const GRANT_STATUSES = {
  active: "active",
  inactive: "inactive",
};

export const RESPONSE_STATUSES = {
  ERROR: "error",
  OK: "success",
};

export const DOCUMENT_NAMES = {
  year: "YEAR",
  vendorName: "VENDOR_NAME",
  accountingPeriod: "ACCOUNTING_PERIOD",
};

export const DOCUMENT_NAMES_MANDATORY = {
  vendorId: "VENDOR_ID",
  invoiceNumber: "INVOICE_NUMBER",
};

export const CURRENCY_SIGNS: Record<"USD", "$"> = {
  USD: "$",
};

export const BILLING_ALLOCATION_METHODS = {
  SAM: "SAM",
  WAM: "WAM",
  MAM: "MAM",
};

export const PAYROLL_ALLOCATION_STATUS = {
  ALLOCATED: "ALLOCATED",
  NOT_ALLOCATED: "NOT_ALLOCATED",
};

export const GL_ALLOCATION_STATUS = {
  ALLOCATED: "ALLOCATED",
  ALLOCATED_TARGET: "ALLOCATED_TARGET",
  NOT_ALLOCATED: "NOT_ALLOCATED",
};

export const RELOAD_UPLOAD_TYPE: Record<string, ReloadModalValue> = {
  NEW: "UPLOAD_NEW_RECORDS",
  ALL: "RELOAD_WITH_PURGE",
};
