import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../../../context/company-context";
import "./company-id.component.scss";

export default function CompanyIdComponent() {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  return (
    <div className="company-id d-flex gap-2">
      <div className="id-label">{t("general.id")}</div>
      <div className="id-input">{company?.id8Digits}</div>
    </div>
  );
}
