import { useTranslation } from "react-i18next";
import Modal from "../../../modal/modal.component";
import { useCallback, useMemo } from "react";
import "./upload-result-modal.component.scss";
import { formatNumber } from "../../../../../utils";

interface UploadResultModalProps {
  show: boolean;
  isUploadResults: boolean;
  data?: Record<string, number>;
  submitHandler: () => void;
  cancelHandler: () => void;
  reloadData?: () => void;
}

export default function UploadResultModal({
  show,
  isUploadResults,
  data,
  submitHandler,
  cancelHandler,
  reloadData,
}: UploadResultModalProps): JSX.Element {
  const closeAndReload = useCallback(() => {
    cancelHandler();
    if (reloadData) {
      reloadData();
    }
  }, [cancelHandler, reloadData]);
  const { numberOfRowsImported, totalAmount, numberOfFailedRows, ...rest } =
    data || {};
  const { t } = useTranslation();
  const title = useMemo(
    () =>
      t(
        isUploadResults
          ? "general.uploadStatus"
          : "company.tabs.gl.uploadModel.uploadConfirmation"
      ),
    [isUploadResults, t]
  );
  return (
    <Modal className="upload-result" show={show} title={title}>
      <div className="content">
        {isUploadResults ? (
          <>
            <div className="header">
              <p>
                {t(
                  `general.${
                    numberOfRowsImported === 1
                      ? "uploadResultsSuccess"
                      : "uploadResultsSuccess_plural"
                  }`,
                  {
                    count: numberOfRowsImported,
                    total: formatNumber(+totalAmount),
                  }
                )}
              </p>
              <p>
                {t(
                  `company.tabs.gl.uploadModel.${
                    numberOfFailedRows === 1
                      ? "uploadResultFailed"
                      : "uploadResultFailed_plural"
                  }`,
                  {
                    count: numberOfFailedRows,
                  }
                )}
              </p>
            </div>
            <div className="result-content">
              {Object.entries(rest).map(([key, value]) => (
                <div key={key} className="item">
                  <div className="gap-3 item-left">
                    <span className="fw-bold">{key}</span>
                    {key !== "Other reasons" && (
                      <span>{t("general.notFound")}</span>
                    )}
                  </div>
                  <div className="item-right">
                    {t(
                      `company.tabs.gl.uploadModel.${
                        value === 1 ? "rowsEffects" : "rowsEffects_plural"
                      }`,
                      { value }
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="body-string">
            {t("company.tabs.gl.uploadModel.uploadConfirmationBody")}
          </div>
        )}
      </div>
      <div className="footer">
        <div className="buttons gap-3">
          {!isUploadResults && (
            <button onClick={cancelHandler} className="btn btn-secondary">
              {t("general.cancel")}
            </button>
          )}
          <button
            onClick={isUploadResults ? closeAndReload : submitHandler}
            className="btn btn-primary"
          >
            {t("general.ok")}
          </button>
        </div>
      </div>
    </Modal>
  );
}
