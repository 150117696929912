import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { FieldValues, useForm } from "react-hook-form";
import { Program } from "../../../../interfaces/interfaces";
import glAccountsApi from "../../../../api/gl-account-api";
import { CompanyContext } from "../../../../context/company-context";
import { useLoadData } from "../../../../hooks/useLoadData";
import { GLFormComponentProps } from "../gl-form.component";
import FormInputComponent from "../../form-fields/form-input.component";
import FormSelectComponent from "../../form-fields/form-select.component";

export default function UnallocatedGLForm({
  onClose,
  onSubmit,
  glTransaction,
  isConfirmating,
}: GLFormComponentProps) {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    resetField,
  } = useForm({
    ...(glTransaction && { defaultValues: glTransaction }),
  });
  const programId = watch("programId");
  const grantId = watch("grantId");
  const [isValid, setIsValid] = useState<boolean>(true);
  const [programs, areProgramsLoading] = useLoadData<
    (Program & { formatted: string })[]
  >({
    fetcher: useCallback(
      () => glAccountsApi.getDropdownPrograms(company.id),
      [company.id]
    ),
  });

  const [grants, areGrantsLoading] = useLoadData<Array<Record<string, string>>>(
    {
      fetcher: useCallback(
        () => glAccountsApi.getDropdownGrants(company.id, programId.toString()),
        [company.id, programId]
      ),
      transformer: useCallback(
        (grantsData: any) => [{ id: "", formatted: "EMPTY" }, ...grantsData],
        []
      ),
    }
  );
  glTransaction.dummyAmount = 0.01;
  const [accounts, areAccountsLoading] = useLoadData<
    Array<Record<string, any>>
  >({
    fetcher: useCallback(
      () =>
        glAccountsApi.getDropdownAccounts(
          company.id,
          String(glTransaction.dummyAmount),
          grantId
        ),
      [company.id, glTransaction, grantId]
    ),
  });

  const isLoading = useMemo(
    () => areProgramsLoading || areGrantsLoading || areAccountsLoading,
    [areProgramsLoading, areGrantsLoading, areAccountsLoading]
  );

  useEffect(() => {
    if (grantId && !accounts.length) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [grantId, accounts]);

  useEffect(() => {
    if (!areProgramsLoading) {
      resetField("programId");
    }
  }, [resetField, areProgramsLoading]);

  useEffect(() => {
    if (!areGrantsLoading) {
      resetField("grantId");
    }
  }, [resetField, areGrantsLoading]);

  useEffect(() => {
    if (!areAccountsLoading) {
      resetField("accountId");
    }
  }, [resetField, areAccountsLoading]);

  const saveCallback = useCallback(
    (values: FieldValues) => {
      const data = {
        id: values.id.toString(),
        programId: values.programId.toString(),
        grantId: values.grantId || null,
        accountId: values.accountId.toString(),
      };
      return glAccountsApi.updateUnallocatedGL(company.id, data.id, data);
    },
    [company.id]
  );

  return (
    <form onSubmit={handleSubmit((values) => onSubmit(values, saveCallback))}>
      <div className="gap-4 row">
        <div className="col">
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.gl.form.unallocated.fiscalYear")}
              inputProps={register("fiscalYear")}
              property={"fiscalYear"}
              error={errors?.fiscalYear?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.gl.form.unallocated.accountingPeriod")}
              inputProps={register("accountingPeriod")}
              property={"accountingPeriod"}
              error={errors?.accountingPeriod?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.gl.form.unallocated.transanctionId")}
              inputProps={register("transactionId")}
              property={"transactionId"}
              error={errors?.transactionId?.message}
            />
          </div>
          <div className="mb-3">
            <FormSelectComponent
              readonly={isConfirmating}
              label={t("company.tabs.gl.form.unallocated.programAllocationId")}
              inputProps={register("programId")}
              property={"programId"}
              options={programs.map((program) => ({
                value: program.id,
                label: program.formatted,
              }))}
              error={errors?.programId?.message}
            />
          </div>
          <div className="mb-3">
            <FormSelectComponent
              readonly={isConfirmating}
              label={t("company.tabs.gl.form.unallocated.grantCode")}
              inputProps={register("grantId")}
              property={"grantId"}
              options={grants.map((grant) => ({
                value: grant.id,
                label: grant.formatted,
              }))}
              error={errors?.grantId?.message}
            />
          </div>
          {!(isLoading || isValid) && (
            <div className="mb-1 text-center bg-danger text-dark">
              <p>{t("company.tabs.gl.form.unallocated.noAccount")}</p>
            </div>
          )}
          <div className="mb-3">
            <FormSelectComponent
              readonly={isConfirmating}
              label={t("company.tabs.gl.form.unallocated.glAccount")}
              inputProps={register("accountId")}
              property={"accountId"}
              options={accounts.map((account) => ({
                value: account.id,
                label: account.formatted,
              }))}
              error={errors?.accountId?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.gl.form.unallocated.description")}
              inputProps={register("jeDescription")}
              property={"jeDescription"}
              error={errors?.jeDescription?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.gl.form.unallocated.amount")}
              inputProps={register("transactionAmount")}
              property={"transactionAmount"}
              error={errors?.transactionAmount?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.gl.form.unallocated.vendor")}
              inputProps={register("apVendorName")}
              property={"apVendorName"}
              error={errors?.apVendorName?.message}
            />
          </div>
          <div className="gap-2 modal-buttons">
            <button onClick={onClose} className="btn w-50 btn-secondary">
              {t("general.cancel")}
            </button>
            <button
              disabled={!isValid}
              type="submit"
              className="btn w-50 btn-primary"
            >
              {isConfirmating ? t("general.confirm") : t("general.save")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
