import { useTranslation } from "react-i18next";
import React, { useContext } from "react";
import { CompanyContext } from "../../../context/company-context";
import "./month-switcher.component.scss";

export default function MonthSwitcher() {
  const { t } = useTranslation();
  const { chosenMonth, setChosenMonth, firstMonth, currentMonth } =
    useContext(CompanyContext);
  return (
    <div className="month-switcher">
      <label htmlFor="month-switcher" className="form-label">
        {t("general.accountingPeriod")}
      </label>
      <input
        className="form-control"
        min={firstMonth}
        max={currentMonth}
        id="month-switcher"
        value={chosenMonth}
        onChange={({ target }) => setChosenMonth(target.value)}
        type="month"
      />
    </div>
  );
}
