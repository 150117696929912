import React, { useCallback } from "react";
import { TableProps } from "../table.component";
import classNames from "classnames";
import "./row.component.scss";

interface RowProps<T> {
  columns: TableProps<T>["columns"];
  columnsPositions: Record<number, string | number>;
  row: T;
  onRowClick?: (row: T) => void;
  isRowActive?: (row: T) => boolean;
  rowIndex: number;
  extraCell?: () => JSX.Element;
  className?: (data: T) => string;
  freezeColumnsNumber?: number;
}

export default function Row<T>({
  columns,
  row,
  onRowClick,
  isRowActive,
  rowIndex,
  columnsPositions,
  extraCell,
  className,
  freezeColumnsNumber,
}: RowProps<T>): JSX.Element {
  const onRowClickHandler = useCallback(
    (data: T) => {
      if (!onRowClick) {
        return;
      }
      onRowClick(data);
    },
    [onRowClick]
  );

  return (
    <tr
      onClick={() => onRowClickHandler(row)}
      className={classNames(className && className(row), {
        clickable: onRowClick,
        active: isRowActive && isRowActive(row),
        odd: rowIndex % 2,
        even: !(rowIndex % 2),
      })}
      key={rowIndex}
    >
      {columns.map(
        (
          {
            freeze,
            property,
            render,
            editable,
            onChange,
            format = (value) => value,
            disabledClick,
            customComponent,
            textAlign,
          },
          index
        ) => {
          const renderValue = render
            ? render(row)
            : String(
                typeof row[property] === "number"
                  ? row[property]
                  : row[property] || ""
              );
          return (
            <td
              key={index}
              style={{
                ...(freeze && { left: columnsPositions[index] }),
                ...(textAlign && { textAlign }),
              }}
              className={`${disabledClick ? "disabled-click" : ""} ${
                freeze ? "freeze" : ""
              } ${
                freezeColumnsNumber && freezeColumnsNumber - 1 === index
                  ? "show-shadow"
                  : ""
              }`}
            >
              {customComponent && customComponent(row)}
              {editable && onChange && (
                <input
                  onChange={({ target }) => {
                    onChange(target.value, row);
                  }}
                  type="text"
                  defaultValue={String(renderValue)}
                />
              )}
              {!customComponent && !editable && format(renderValue)}
            </td>
          );
        }
      )}
      {extraCell && <td>{extraCell()}</td>}
    </tr>
  );
}
