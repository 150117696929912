import React from "react";
import "./update-password.component.scss";
import LoginWrapper from "../wrapper/wrapper.component";
import { useTranslation } from "react-i18next";
import { FieldValues, useForm } from "react-hook-form";
import authApi from "../../../api/auth-api";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

export default function UpdatePassword(): JSX.Element {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();
  const navigate = useNavigate();

  const submit = async ({
    temporaryPass,
    newPassword,
    confirmationPassword,
  }: FieldValues): Promise<void> => {
    if (newPassword !== confirmationPassword) {
      setError("confirmationPassword", {
        message: t("login.updatePassword.confirmationPasswordValidation"),
      });
      return;
    }
    authApi
      .updatePassword({
        resetCode: temporaryPass,
        newPassword,
        resetKey: searchParams.get("resetKey"),
      })
      .then(
        () => {
          navigate("/");
        },
        (err) => {
          setError("confirmationPassword", {
            message: err.title || err.detail || t("general.serverError"),
          });
        }
      );
  };

  return (
    <LoginWrapper title={t("login.updatePassword.title")}>
      <form
        autoComplete="off"
        onClick={() => clearErrors()}
        onSubmit={handleSubmit(submit)}
      >
        <div className="mb-4">
          <label htmlFor="temporaryPass" className="form-label">
            {t("login.updatePassword.temporaryPass")}
          </label>
          <input
            tabIndex={-1}
            autoComplete="off"
            type="password"
            className="form-control"
            id="temporaryPass"
            {...register("temporaryPass")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="newPassword" className="form-label">
            {t("login.updatePassword.newPass")}
          </label>
          <input
            type="password"
            className="form-control"
            id="newPassword"
            {...register("newPassword", {
              pattern:
                /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[`~!@#$%^&*()\-_=+\\|\[{\]};:'",<.>\/?])[A-Za-z\d`~!@#$%^&*()\-_=+\\|\[{\]};:'",<.>\/?]{8,}$/,
            })}
          />
          {errors.newPassword && (
            <div className="error">{t("login.updatePassword.validation")}</div>
          )}
          <div className="password-hint">
            {t("login.loginForm.passwordHint")}
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="confirmPass" className="form-label">
            {t("login.updatePassword.confirmPass")}
          </label>
          <input
            type="password"
            className="form-control"
            id="confirmPass"
            {...register("confirmationPassword")}
          />
          {errors.confirmationPassword && (
            <div className="error">{errors.confirmationPassword.message}</div>
          )}
        </div>
        <div className="d-grid gap-2 mb-4">
          <button type="submit" className="btn btn-primary">
            {t("login.loginForm.nextBtn")}
          </button>
        </div>
      </form>
    </LoginWrapper>
  );
}
