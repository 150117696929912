import React, { useCallback, useContext, useMemo, useState } from "react";
import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TOAST_TYPES, USER_ROLES, USER_ROLE_LABELS } from "../../consts";
import { Program } from "../../interfaces/interfaces";
import programsApi from "../../api/programs-api";
import { CompanyContext } from "../../context/company-context";
import { UserContext } from "../../context/user.context";
import { ToastContext } from "../../context/toast.context";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { useLoadData } from "../../hooks/useLoadData";
import ButtonsComponent from "../../components/shared/download-upload-buttons/buttons.component";
import EmptyListComponent from "../../components/shared/empty-list/empty-list.component";
import Modal from "../../components/shared/modal/modal.component";
import ProgramFormComponent from "../../components/forms/program/program-form.component";
import Table from "../../components/shared/table/table.component";
import Spinner from "../../components/shared/spinner/spinner.component";
import "./programs.component.scss";
import { getColumns } from "./helpers";
import { DownloadButtonComponent } from "../../components/shared/download-button/download-button.component";
import fileApi from "../../api/file-api";

export default function Programs(): JSX.Element {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const handleError = useErrorHandler();
  const { company } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [programToEdit, setProgramToEdit] = useState<Program | undefined>(
    undefined
  );
  const [programs, areProgramsLoading, loadPrograms] = useLoadData<Program[]>({
    fetcher: useCallback(() => programsApi.getPrograms(company.id), [company]),
  });
  const isReadonly = useMemo(
    () => user.role === USER_ROLE_LABELS[USER_ROLES.ROLE_USER],
    [user.role]
  );

  const onFileUpload = useCallback(
    async (files: FileList): Promise<void> => {
      const formData = new FormData();
      formData.append("file", files[0]);
      const uploadData = await programsApi.uploadPrograms(formData, company.id);
      if (uploadData?.errors?.length) {
        return Promise.reject(uploadData);
      }
      loadPrograms();
    },
    [company.id, loadPrograms]
  );

  const submitHandler = (data: FieldValues): void => {
    const fetcher = data.id
      ? programsApi.updateProgram
      : programsApi.createProgram;
    fetcher(company.id, data)
      .then(
        () => {
          setShowForm(false);
          loadPrograms();
          addToast({
            id: company.id,
            type: TOAST_TYPES.success,
            message: t(
              `company.tabs.programs.${data.id ? "edit" : "add"}Success`
            ),
          });
        },
        (err) => handleError(err)
      )
      .finally(() => {
        setProgramToEdit(undefined);
        setShowForm(false);
      });
  };

  const importActions = [
    {
      title: t("programs.upload.getTemplate"),
      url: "templates?templateName=PROGRAMS",
    },
  ];

  const uploadActions = [
    {
      title: t("programs.upload.uploadTemplate"),
      action: onFileUpload,
      disabled: isReadonly,
    },
  ];
  const columns = useMemo<
    {
      header: string;
      property: keyof Program;
      sort?: boolean;
    }[]
  >(() => getColumns(t), [t]);
  return (
    <div className="programs-page">
      <Modal
        centredContent={false}
        show={showForm}
        title={t(`company.tabs.programs.${programToEdit ? "edit" : "add"}`)}
      >
        <ProgramFormComponent
          key={programToEdit?.id}
          entity={programToEdit}
          onClose={() => {
            setShowForm(false);
            setProgramToEdit(undefined);
          }}
          onSubmit={submitHandler}
        />
      </Modal>

      {areProgramsLoading ? (
        <Spinner size="medium" />
      ) : !!programs.length ? (
        <div>
          <div className="list-header">
            <div className="actions gap-2">
              <button
                disabled={isReadonly}
                onClick={() => setShowForm(true)}
                className="btn btn-primary"
              >
                {t("company.tabs.programs.add")}
              </button>
              {ButtonsComponent({ uploadActions })}
              <DownloadButtonComponent
                label={t("general.export")}
                downloadHandler={() =>
                  fileApi.downloadFile(`company/${company.id}/programs-export`)
                }
              />
            </div>
          </div>
          <Table
            columns={columns}
            search
            data={programs}
            {...(!isReadonly
              ? {
                  onRowClick: (row: Program) => {
                    setProgramToEdit(row);
                    setShowForm(true);
                  },
                }
              : {})}
          />
        </div>
      ) : (
        <EmptyListComponent
          {...{
            importActions,
            uploadActions,
            description: t("company.tabs.programs.emptyTitle"),
          }}
        />
      )}
    </div>
  );
}
