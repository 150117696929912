import {
  Column,
  ValidationFileError,
} from "../../../components/shared/upload/upload-validation-component/interfaces";
import {
  ACCOUNT_CLASSES,
  ACCOUNT_GROUPING_TYPE,
  PROGRAM_TYPES,
} from "../../../consts";
import mcoaApi from "../../../api/mcoa-api";
import programsApi from "../../../api/programs-api";

export const ERRORS = {
  accounts: "notValidAccounts",
  programs: "notValidPrograms",
};

export const getAccountColumns = (): Array<Column> => [
  {
    header: "company.tabs.mcoa.list.account",
    property: "code",
    type: "text",
  },
  {
    header: "company.tabs.mcoa.list.description",
    property: "name",
    type: "input",
  },
  {
    header: "company.tabs.mcoa.list.class",
    property: "accountClass",
    type: "select",
    options: Object.values(ACCOUNT_CLASSES).map((value) => ({
      value,
      label: value,
    })),
  },
  {
    header: "company.tabs.mcoa.list.type",
    property: "accountType",
    type: "select",
    options: Object.values(ACCOUNT_GROUPING_TYPE).map((value) => ({
      value,
      label: value,
    })),
  },
];

export const getProgramColumns = (): Array<Column> => [
  {
    header: "company.tabs.programs.list.header.id",
    property: "code",
    type: "text",
  },
  {
    header: "company.tabs.programs.list.header.name",
    property: "name",
    type: "input",
  },
  {
    header: "company.tabs.programs.form.departmentType",
    property: "category",
    type: "select",
    options: Object.values(PROGRAM_TYPES).map((value) => ({
      value,
      label: value,
    })),
  },
];

export const getAccountProps = (): {
  subTitle: string;
  footerTitle: string;
  title: string;
} => ({
  footerTitle: "company.tabs.gl.uploadModel.account.footerTitle",
  title: "company.tabs.gl.uploadModel.account.title",
  subTitle: "company.tabs.gl.uploadModel.account.subTitle",
});

export const getProgramProps = (): {
  subTitle: string;
  footerTitle: string;
  title: string;
} => ({
  footerTitle: "company.tabs.gl.uploadModel.program.footerTitle",
  title: "company.tabs.gl.uploadModel.program.title",
  subTitle: "company.tabs.gl.uploadModel.program.subTitle",
});

export const accountsToDto = ({
  code,
  name,
  accountClass,
  accountType,
}: Record<string, string>) => ({
  code,
  name,
  accountClass,
  accountGrouping: {
    type: accountType,
  },
});

export const validateAccountEntity = (
  account: Record<string, string>
): boolean => {
  const fields = ["code", "name", "accountClass", "accountType"];
  return fields.every((item) => account[item]);
};

export const validateProgramEntity = (
  account: Record<string, string>
): boolean => {
  const fields = ["code", "name", "category"];
  return fields.every((item) => account[item]);
};

export const CONFIG = {
  [ERRORS.accounts]: {
    staticProps: getAccountProps(),
    columns: getAccountColumns(),
    validateEntities: (entities: Record<string, string>[]) =>
      entities.some((entity) => !validateAccountEntity(entity)),
    saveEntities: ({
      data,
      companyId,
    }: {
      data: Record<string, string>[];
      companyId: string;
    }) => mcoaApi.createMCOABulk({ companyId, data: data.map(accountsToDto) }),
    checkIfErrorEmpty: (response: ValidationFileError) =>
      !!Object.keys(response.notValidAccounts).length,
    getData: (response: ValidationFileError) =>
      Object.entries(response.notValidAccounts || {}).map(([code, name]) => ({
        id: code,
        code,
        name,
      })),
    getEntities: (response: ValidationFileError) =>
      Object.entries(response.notValidAccounts).reduce(
        (acc, [code, name]) => ({
          ...acc,
          [code]: { code, name },
        }),
        {}
      ),
  },
  [ERRORS.programs]: {
    staticProps: getProgramProps(),
    columns: getProgramColumns(),
    validateEntities: (entities: Record<string, string>[]) =>
      entities.some((entity) => !validateProgramEntity(entity)),
    saveEntities: ({
      data,
      companyId,
    }: {
      data: Record<string, string>[];
      companyId: string;
    }) => programsApi.createProgramsBulk(companyId, data),
    checkIfErrorEmpty: (response: ValidationFileError) =>
      !!response.notValidPrograms.length,
    getData: (response: ValidationFileError) =>
      response.notValidPrograms.map((code: string) => ({
        code,
        id: code,
      })),
    getEntities: (response: ValidationFileError) =>
      response.notValidPrograms.reduce(
        (acc, code) => ({
          ...acc,
          [code]: { code },
        }),
        {}
      ),
  },
};
