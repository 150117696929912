export const formatNumber = (value: number) => {
  if (!value) {
    return "0.00";
  }
  return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatToNumber = (value: string) => {
  if (!value) {
    return;
  }
  return value.replace(/\,/g, "");
};

export const formatDate = ({
  dateString,
  format = "en-US",
}: {
  dateString: string;
  format?: string;
}): string => {
  if (!Date.parse(dateString)) {
    return "";
  }
  const dateObject = new Date(dateString);
  return dateObject.toLocaleDateString(format, { timeZone: "UTC" });
};

export const getYearMonth = (dateString: string) => {
  return dateString?.slice(0, 7);
};

export const getFirstDay = (dateString: string) => {
  return getYearMonth(dateString) + "-01";
};

export const getLastDay = (dateString: string) => {
  try {
    const [year, month] = getYearMonth(dateString)
      .split("-")
      .map((e) => +e);
    const date = new Date(Date.UTC(year, month, 0));
    return date.toISOString().slice(0, 10);
  } catch (e) {
    return dateString;
  }
};

export const getAutoFiscalEndDate = (dateString: string) => {
  try {
    const [year, month] = getYearMonth(dateString)
      .split("-")
      .map((e) => +e);
    const date = new Date(Date.UTC(year + 1, month - 1, 0));
    return date.toISOString().slice(0, 10);
  } catch (e) {
    return dateString;
  }
};

export const setOffsetHoursToDate = (date: Date) => {
  try {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  } catch (e) {
    return date;
  }
};
