import { MCOAInterface, UploadResponse } from "../interfaces/interfaces";
import { httpClient } from "../core/http-client/http-client";
import { AxiosResponse } from "axios";
import { FieldValues } from "react-hook-form";

async function getMCOAs(
  companyId: string | undefined
): Promise<Array<MCOAInterface>> {
  const { data } = await httpClient.get(
    `company/${companyId}/masterchart-accounts/count`
  );
  return httpClient
    .get(`company/${companyId}/masterchart-accounts?size=${data}`)
    .then((response: AxiosResponse): Array<MCOAInterface> => response.data);
}

async function getMCOAsByGrant(
  companyId: string,
  grantCode: string
): Promise<Array<Record<string, string>>> {
  return httpClient
    .get(`company/${companyId}/masterchart-accounts/grant-budget/${grantCode}`)
    .then(
      (response: AxiosResponse): Array<Record<string, string>> => response.data
    );
}

async function createMCOA({
  companyId,
  data,
}: {
  companyId: string | undefined;
  data: FieldValues;
}): Promise<MCOAInterface> {
  return httpClient
    .post(`company/${companyId}/masterchart-accounts`, {
      ...data,
      company: { id: companyId },
    })
    .then((response: AxiosResponse): MCOAInterface => response.data);
}

async function createMCOABulk({
  companyId,
  data,
}: {
  companyId: string | undefined;
  data: Record<string, string | Record<string, string>>[];
}): Promise<Record<string, string>[]> {
  const payload = data.map((account) => ({
    ...account,
    company: { id: companyId },
  }));
  return httpClient
    .post(`company/${companyId}/masterchart-accounts/bulk-create`, payload)
    .then((response: AxiosResponse): Record<string, string>[] => response.data);
}

async function updateMCOA({
  companyId,
  data,
}: {
  companyId: string | undefined;
  data: FieldValues;
}): Promise<MCOAInterface> {
  return httpClient
    .put(`company/${companyId}/masterchart-accounts/${data.id}`, data)
    .then((response: AxiosResponse): MCOAInterface => response.data);
}

async function uploadMCOA(
  data: FormData,
  companyId: string | undefined
): Promise<UploadResponse> {
  return httpClient
    .post(`company/${companyId}/masterchart-accounts/file-upload`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: AxiosResponse): UploadResponse => response.data);
}

const mcoaApi = {
  uploadMCOA,
  getMCOAs,
  createMCOA,
  updateMCOA,
  createMCOABulk,
  getMCOAsByGrant,
};

export default mcoaApi;
