import React, { useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DetailsByProgram } from "../../../interfaces/interfaces";
import { SORT_TYPES, ROUTE_PATHS } from "../../../consts";
import { formatNumber } from "../../../utils";
import dashboardApi from "../../../api/dashboard-api";
import fileApi from "../../../api/file-api";
import { CompanyContext } from "../../../context/company-context";
import { useLoadData } from "../../../hooks/useLoadData";
import { getStaticColumns } from "./helpers";
import BackTo from "../../../components/shared/back-to/back-to.component";
import { DownloadButtonComponent } from "../../../components/shared/download-button/download-button.component";
import Spinner from "../../../components/shared/spinner/spinner.component";
import Table from "../../../components/shared/table/table.component";

export default function ProgramDashboard(): JSX.Element {
  const { company } = useContext(CompanyContext);
  const { t } = useTranslation();
  const { programId } = useParams();

  const { chosenMonth } = useContext(CompanyContext);
  const date = new Date(chosenMonth);
  const year = date.getUTCFullYear();
  const month1 = date.getUTCMonth() + 1;
  let lastDate = 0;
  if (
    month1 == 1 ||
    month1 == 3 ||
    month1 == 5 ||
    month1 == 7 ||
    month1 == 8 ||
    month1 == 10 ||
    month1 == 12
  ) {
    lastDate = 31;
  } else if (month1 == 2) {
    if (year % 4 == 0) {
      lastDate = 29;
    } else {
      lastDate = 28;
    }
  } else {
    lastDate = 30;
  }
  const finalDate = `${year}-${
    month1 >= 10 ? month1 : `0${month1}`
  }-${lastDate}`;
  const [programDashboard, isLoading] = useLoadData({
    fetcher: useCallback(
      () =>
        dashboardApi.getProgramDetails({
          companyId: company?.id,
          programCode: programId,
          finalDate: finalDate,
        }),
      [company?.id, programId, finalDate]
    ),
  });

  const rowsToDisplay = useMemo(
    () => [
      ...(programDashboard?.prDashboardDetailsByProgram?.map(
        (prProgramDetails) => ({
          ...prProgramDetails,
          accountName: prProgramDetails.jobFunctionAcronym,
          ...(prProgramDetails.isJobFunctionForFringeBenefits && {
            sticky: true,
          }),
        })
      ) || []),
      ...(programDashboard?.glDashboardDetailsByProgram?.map(
        (glProgramDetails) => ({
          ...glProgramDetails,
          ...(glProgramDetails.isAccountForIndirectCostAllocation && {
            sticky: true,
          }),
        })
      ) || []),
    ],
    [programDashboard]
  );

  const uniqueGrants = useMemo<string[]>(
    () => programDashboard?.programGrantCodes || [],
    [programDashboard]
  );

  const columns = useMemo<
    {
      header: string;
      render?: (row: DetailsByProgram) => string | JSX.Element;
      property: keyof DetailsByProgram;
      sum?: (row: DetailsByProgram, acc: number) => number;
      format?: (value: string | JSX.Element) => string | JSX.Element;
    }[]
  >(
    () => [
      ...getStaticColumns({ t }),
      ...uniqueGrants.map((grantCode) => ({
        header: `${t(
          "company.tabs.dashboard.programDetails.mtdGrant"
        )} ${grantCode}, $`,
        property: grantCode,
        sum: (row: DetailsByProgram, acc: number) =>
          (+row.grantCodeAndAmountSpent[grantCode] || 0) + acc,
        format: (value: string | JSX.Element) => formatNumber(+value),
        sort: true,
        sortType: SORT_TYPES.number,
      })),
    ],
    [t, uniqueGrants]
  );

  const rowsWithGrants = useMemo<DetailsByProgram[]>(
    () =>
      rowsToDisplay.map((item) => {
        return {
          ...item,
          ...uniqueGrants.reduce(
            (acc, grant) => ({
              ...acc,
              [grant]: item.grantCodeAndAmountSpent[grant],
            }),
            {}
          ),
        };
      }),
    [rowsToDisplay, uniqueGrants]
  );
  return (
    <div className="program-dashboard">
      <div className="header flex-fill justify-content-between">
        <BackTo
          link={`/companies/company/${company.id}/dashboard`}
          title={`${t("company.tabs.dashboard.programDetails.program")} ${
            programDashboard.programName
          } / ${programId}`}
        />
        <DownloadButtonComponent
          fileName={`program-dashboard-${programId}.xlsx`}
          label={t("general.export")}
          downloadHandler={() =>
            fileApi.downloadFile(
              `${ROUTE_PATHS.company}/${company.id}/dashboard/details-by-program/${programId}/export/${finalDate}`
            )
          }
        />
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <Table
          columns={columns}
          data={rowsWithGrants}
          presortBy={{
            property: "accountName",
            sortDirection: "up",
            sortType: "string",
          }}
          isFooter
          filter={(row: DetailsByProgram) => !!row?.jobFunctionAcronym}
          filteredTotalTitle={t(
            "company.tabs.grants.finData.list.separatorTitle"
          )}
          totalTitle={t("company.tabs.grants.finData.list.totalTitle")}
          restTotalTitle={t(
            "company.tabs.grants.finData.list.totalSecondTitle"
          )}
          filteredHeader={t(
            "company.tabs.dashboard.programDetails.employeeCompensation"
          )}
          restHeader={t(
            "company.tabs.dashboard.programDetails.notPersonalExpenses"
          )}
        />
      )}
    </div>
  );
}
