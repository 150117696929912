import { AxiosResponse } from "axios";
import { httpClient } from "../core/http-client/http-client";
import {
  GrantDashboard,
  GrantDetailsDashboard,
  ProgramDashboard,
  ProgramDetailsDashboard,
  UserSetting,
} from "../interfaces/interfaces";

const getDashboards = async (
  companyId: string | undefined,
  finalDate: string | undefined
): Promise<{
  programDashboard: ProgramDashboard[];
  grantDashboard: GrantDashboard[];
  userSetting: UserSetting;
}> => {
  return httpClient
    .get(`company/${companyId}/dashboard/${finalDate}`)
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

const getProgramDetails = async ({
  companyId,
  programCode,
  finalDate,
}: {
  companyId: string | undefined;
  programCode: string | undefined;
  finalDate: string | undefined;
}): Promise<ProgramDetailsDashboard> => {
  return httpClient
    .get(
      `company/${companyId}/dashboard/details-by-program/${programCode}/${finalDate}`
    )
    .then((response: AxiosResponse) => response.data);
};

const getGrantsDetails = async ({
  companyId,
  grantCode,
  periodStart,
  periodEnd,
}: {
  companyId: string | undefined;
  grantCode: string | undefined;
  periodStart?: string;
  periodEnd?: string;
}): Promise<GrantDetailsDashboard> => {
  return httpClient
    .get(
      `company/${companyId}/dashboard/details-by-grant/${grantCode}/${
        periodStart || periodEnd
          ? `?periodStart=${periodStart}&periodEnd=${periodEnd}`
          : ""
      }`
    )
    .then((response: AxiosResponse) => response.data);
};

const getExpense = async (
  companyId: string | undefined,
  userID: string | undefined,
  toggle: boolean | undefined
) => {
  return httpClient
    .get(
      `company/${companyId}/dashboard/details-last-month/${userID}/${toggle}`
    )
    .then((response: AxiosResponse) => {
      return response.data;
    });
};
const getUserSettings = async (
  companyId: string | undefined,
  userId: string | undefined
) => {
  return httpClient
    .get(`company/${companyId}/dashboard/user-settings/${userId}`)
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

const dashboardApi = {
  getDashboards,
  getProgramDetails,
  getGrantsDetails,
  getExpense,
  getUserSettings,
};
export default dashboardApi;
