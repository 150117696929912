import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTE_PATHS } from "../../../consts";
import { User } from "../../../interfaces/interfaces";
import permissionsApi from "../../../api/permissions-api";
import userApi from "../../../api/user-api";
import { useLoadData } from "../../../hooks/useLoadData";
import PageLayout from "../../../components/shared/page-layout/page-layout.component";
import Table from "../../../components/shared/table/table.component";
import Spinner from "../../../components/shared/spinner/spinner.component";
import "./user-list.component.scss";

export default function UserList(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userList, setUserList] = useState<Array<User>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [users, areUsersLoading] = useLoadData<User[]>({
    fetcher: userApi.getUsers,
  });
  const [usersCompanies, areUsersCompaniesLoading] = useLoadData<
    Record<string, Array<User>>
  >({
    fetcher: permissionsApi.getUsersCompanies,
  });

  useEffect(() => {
    setIsLoading(true);
    if (!(areUsersLoading || areUsersCompaniesLoading)) {
      const updatedUsers = users.map((user: User) => ({
        ...user,
        company: usersCompanies[+user.id],
        invite: user.inviteSent
          ? t("companies.inviteSent")
          : t("companies.inviteNotSent"),
      }));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setUserList(updatedUsers);
      setIsLoading(false);
    }
  }, [
    t,
    setIsLoading,
    setUserList,
    areUsersLoading,
    areUsersCompaniesLoading,
    users,
    usersCompanies,
  ]);

  const COLUMNS = [
    {
      header: t("users.list.id"),
      property: "id",
      sort: true,
      sortType: "number",
    },
    {
      header: t("users.list.name"),
      property: "firstName",
      sort: true,
    },
    {
      header: t("users.list.email"),
      property: "email",
      sort: true,
    },
    {
      header: t("users.list.company"),
      property: "company[0].name",
      sort: true,
      render: ({ company }: User) => company?.[0]?.name,
    },
    {
      header: t("users.list.role"),
      property: "role",
      sort: true,
    },
    {
      header: t("users.list.status"),
      property: "invite",
      sort: true,
    },
    {
      header: "",
      property: "",
      render: ({ id }: User) => (
        <Link
          className="text-decoration-none"
          // eslint-disable-next-line @typescript-eslint/dot-notation
          to={`/${ROUTE_PATHS.users}/user/${id}`}
        >
          {t("users.list.details")}
        </Link>
      ),
    },
  ];

  return (
    <PageLayout
      smallHeader
      title={t("users.pageTitle")}
      actionTitle={t("users.createUser")}
      action={() => navigate(`/${ROUTE_PATHS.users}/user`)}
    >
      {isLoading ? (
        <Spinner size="medium" />
      ) : (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Table search={true} columns={COLUMNS} data={userList} />
      )}
    </PageLayout>
  );
}
