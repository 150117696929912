import { TFunction } from "i18next";
import { GlTransaction } from "../../interfaces/interfaces";
import { SortTypes } from "../../interfaces/types";
import { formatDate, formatNumber } from "../../utils";
import { DownloadButtonComponent } from "../../components/shared/download-button/download-button.component";
import fileApi from "../../api/file-api";
import { ROUTE_PATHS } from "../../consts";
import { Dispatch, SetStateAction } from "react";

export const getColumns = ({
  t,
  isUserAdmin,
  companyId,
}: {
  t: TFunction;
  isUserAdmin: boolean;
  setEditedGLID: Dispatch<SetStateAction<string | undefined>>;
  companyId: string | undefined;
}): {
  header: string;
  property: keyof GlTransaction;
  sort?: boolean;
  sortType?: SortTypes;
  render?: (data: GlTransaction) => JSX.Element | string;
  format?: (value: string | JSX.Element) => string | JSX.Element;
}[] => [
  ...(isUserAdmin
    ? [
        {
          header: "",
          property: "actionList" as const,
          render: (): JSX.Element => (
            <div className="dropdown">
              <p className="dropdown-toggle" data-bs-toggle="dropdown">
                {t("company.tabs.gl.list.actions.title") as JSX.Element}
              </p>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item">
                    {t("company.tabs.gl.list.actions.edit") as JSX.Element}
                  </a>
                </li>
              </ul>
            </div>
          ),
        },
      ]
    : []),
  {
    header: t("company.tabs.gl.list.fiscalYear"),
    property: "fiscalYear",
    sort: true,
    sortType: "number",
  },
  {
    header: t("company.tabs.gl.list.accountingPeriod"),
    property: "accountingPeriod",
  },
  {
    header: t("company.tabs.gl.list.id"),
    property: "edgeId",
    sort: true,
    sortType: "string",
  },
  {
    header: t("company.tabs.gl.list.transanctionId"),
    property: "transanctionId",
  },
  {
    header: t("company.tabs.gl.list.program.code"),
    property: "program.code",
    render: ({ program }: GlTransaction) => program?.code,
    sort: true,
  },
  {
    header: t("company.tabs.gl.list.grant.code"),
    property: "grant.code",
    render: ({ grant }: GlTransaction) => grant?.code,
    sort: true,
    sortType: "string",
  },
  {
    header: t("company.tabs.gl.list.tranType"),
    property: "transanctionType",
  },
  {
    header: t("company.tabs.gl.list.transactionDate"),
    property: "transactionDate",
    format: (value) => {
      if (typeof value === "string") {
        return formatDate({ dateString: value });
      }
      return "";
    },
  },
  {
    header: t("company.tabs.gl.list.account.code"),
    property: "account.code",
    render: ({ account }: GlTransaction) => account?.code,
  },
  {
    header: t("company.tabs.gl.list.account.name"),
    property: "account.name",
    render: ({ account }: GlTransaction) => account?.name,
  },
  {
    header: t("company.tabs.gl.list.description"),
    property: "description",
  },
  {
    header: t("company.tabs.gl.list.entryType"),
    property: "entryType",
  },
  {
    header: t("company.tabs.gl.list.amount"),
    property: "amount",
    format: (value: string | JSX.Element) => formatNumber(+value),
  },
  {
    header: t("company.tabs.gl.list.allocationPercentage"),
    property: "allocationPercentage",
    format: (value: string | JSX.Element): string => (value ? `${value}%` : ""),
  },
  {
    header: t("company.tabs.gl.list.vendor.name"),
    property: "vendor.name",
    render: ({ vendor }: GlTransaction) => vendor?.name,
  },
  {
    header: t("company.tabs.gl.list.fileName"),
    property: "fileName",
  },
  {
    header: t("company.tabs.gl.list.masterDocument"),
    property: "pageNumberInMergedFile",
    render: ({ pageNumberInMergedFile, pdfLink }: GlTransaction) =>
      pageNumberInMergedFile ? (
        <DownloadButtonComponent
          label={`Page #${pageNumberInMergedFile}`}
          asLink={true}
          downloadHandler={() =>
            fileApi.downloadFile(
              `${ROUTE_PATHS.company}/${companyId}/document-management/download-file?fileName=${pdfLink}`
            )
          }
        />
      ) : (
        "Missing invoice file"
      ),
  },
];
