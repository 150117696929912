import React from "react";
import { useTranslation } from "react-i18next";
import Card from "../../../components/shared/card/card.component";
import "./allocation-method.component.scss";

export default function AllocationMethods() {
  const { t } = useTranslation();
  return (
    <Card header={t("company.tabs.control-center.allocationMethods")}>
      <div className="methods gap-3">
        <div className="form-check form-check-inline gap-3">
          <input
            disabled
            className="form-check-input"
            type="radio"
            id="type1"
            value={"true"}
            checked
          />
          <label className="form-check-label label" htmlFor="typeParent1">
            {t("company.tabs.billingAllocationMethod.samTitle")}
            <small>
              {t("company.tabs.billingAllocationMethod.samSubTitle")}
            </small>
          </label>
        </div>
        <div className="form-check form-check-inline gap-3">
          <input
            disabled
            className="form-check-input"
            type="radio"
            id="type3"
          />
          <label className="form-check-label label" htmlFor="typeParent3">
            {t("company.tabs.billingAllocationMethod.wamTitle")}
            <small>
              {t("company.tabs.billingAllocationMethod.wamSubTitle")}
            </small>
          </label>
        </div>
        <div className="form-check form-check-inline gap-3">
          <input
            disabled
            className="form-check-input"
            type="radio"
            id="type3"
          />
          <label className="form-check-label label" htmlFor="typeParent3">
            {t("company.tabs.billingAllocationMethod.mamTitle")}
            <small>
              {t("company.tabs.billingAllocationMethod.mamSubTitle")}
            </small>
          </label>
        </div>
        <div className="mb-3 form-field">
          <label htmlFor="factor" className="form-label">
            {t("company.tabs.billingAllocationMethod.companyFactor")}
          </label>
          <select disabled className="form-select" id="factor">
            <option value=""></option>
          </select>
        </div>
        <div>
          {t("company.tabs.billingAllocationMethod.companyFactorSubTitle")}
        </div>
        <div className="footer">
          <button disabled className="btn btn-primary">
            {t("general.save")}
          </button>
        </div>
      </div>
    </Card>
  );
}
