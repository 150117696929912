import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ROUTE_PATHS, USER_ROLES, USER_ROLE_LABELS } from "../../../consts";
import { Program } from "../../../interfaces/interfaces";
import fileApi from "../../../api/file-api";
import { CompanyContext } from "../../../context/company-context";
import { UserContext } from "../../../context/user.context";
import { DownloadButtonComponent } from "../../../components/shared/download-button/download-button.component";
import WarningButtonComponent from "../../../components/shared/warning-button/warning-button.component";
import "./action-header.component.scss";
import UploadComponent from "../../../components/shared/upload/upload-validation-component/upload-validation.component";
import payrollApi from "../../../api/payroll-api";
import { ERRORS } from "./helpers";
import { useRegions } from "../hooks/useRegions";

interface ActionHeaderProps {
  allocationPrograms: Program[];
  allocationMade: boolean;
  purgePrograms: Program[];
  runAllocation: () => Promise<void>;
  runPurge: () => Promise<void>;
  allocationProgram: string;
  setAllocationProgram: (code: string) => void;
  purgeProgram: string;
  setPurgeProgram: (code: string) => void;
  postUploadEvent: () => void;
  showPreselection: boolean;
}

export default function ActionHeaderComponent({
  allocationPrograms,
  purgePrograms,
  runAllocation,
  runPurge,
  allocationProgram,
  setAllocationProgram,
  purgeProgram,
  setPurgeProgram,
  postUploadEvent,
  showPreselection,
}: ActionHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const { company, chosenMonth } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const isReadonly = useMemo(
    () => user.role === USER_ROLE_LABELS[USER_ROLES.ROLE_USER],
    [user.role]
  );
  const activePeriod = useMemo(
    () => chosenMonth.split("/")?.[0],
    [chosenMonth]
  );
  const programCode = useMemo(
    () =>
      allocationPrograms.find(
        (program) => program.id.toString() === allocationProgram
      )?.code,
    [allocationProgram, allocationPrograms]
  );
  const allocationWarningMessage = useMemo(
    () =>
      allocationProgram === "all"
        ? t("shared.allocation.warningAll")
        : t("shared.allocation.warningCode", { code: programCode }),
    [t, allocationProgram, programCode]
  );
  const purgeWarningMessage = useMemo(
    () =>
      purgeProgram === "all"
        ? t("shared.purge.warningAll")
        : t("shared.purge.warningCode", {
            code: purgePrograms.find(
              (program) => program.id.toString() === purgeProgram
            )?.code,
          }),
    [purgeProgram, t, purgePrograms]
  );
  const { configWithRegion } = useRegions();

  return (
    <div className="payroll-register__header">
      <div className="d-flex justify-content-end align-items-end gap-5">
        <div className="d-flex flex-column gap-1">
          <UploadComponent
            disabled={isReadonly}
            showPreselection={showPreselection}
            postUploadEvent={postUploadEvent}
            uploadHandler={payrollApi.uploadPayrolls}
            validateHandler={payrollApi.validatePRFile}
            title={t("company.tabs.payrollRegister.upload")}
            errors={ERRORS}
            config={configWithRegion}
          />
        </div>
        <div>
          <label htmlFor="allocate">
            {t("company.tabs.payrollRegister.allocateLabel")}
          </label>
          <div className="d-flex select-wrapper gap-2">
            <select
              disabled={isReadonly}
              onChange={(e) => setAllocationProgram(e.target.value)}
              className="form-control"
              id="allocate"
              defaultValue={allocationProgram}
            >
              <option value="all">All</option>
              {allocationPrograms.map(({ id, code, name }) => (
                <option key={code} value={id}>
                  {code} - {name}
                </option>
              ))}
            </select>
            <WarningButtonComponent
              disabled={isReadonly}
              title={t("shared.allocation.label")}
              warning={allocationWarningMessage}
              callback={runAllocation}
            />
          </div>
        </div>
        <div>
          <label htmlFor="purge">
            {t("company.tabs.payrollRegister.purgeLabel")}
          </label>
          <div className="d-flex select-wrapper gap-2">
            <select
              disabled={isReadonly}
              onChange={(e) => setPurgeProgram(e.target.value)}
              className="form-control"
              id="purge"
              defaultValue={purgeProgram}
            >
              <option value="all">All</option>
              {purgePrograms.map(({ id, code, name }) => (
                <option key={code} value={id}>
                  {code} - {name}
                </option>
              ))}
            </select>
            <WarningButtonComponent
              disabled={isReadonly}
              warning={purgeWarningMessage}
              title={t("company.tabs.payrollRegister.purge")}
              callback={runPurge}
            />
          </div>
        </div>
        <DownloadButtonComponent
          fileName="payroll-register.xlsx"
          label={t("company.tabs.payrollRegister.export")}
          downloadHandler={() =>
            fileApi.downloadFile(
              `${ROUTE_PATHS.company}/${
                company.id
              }/payroll-register/journal-entries${
                activePeriod ? `?activePeriod=${activePeriod}` : ""
              }`
            )
          }
        />
        <DownloadButtonComponent
          fileName="payroll-register.xlsx"
          label={t("general.export")}
          downloadHandler={() =>
            fileApi.downloadFile(
              `${ROUTE_PATHS.company}/${company.id}/payroll-register-export${
                activePeriod ? `?activePeriod=${activePeriod}` : ""
              }`
            )
          }
        />
      </div>
    </div>
  );
}
