import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { FieldValues, useForm } from "react-hook-form";
import glAccountsApi from "../../../../api/gl-account-api";
import { CompanyContext } from "../../../../context/company-context";
import { GLFormComponentProps } from "../gl-form.component";
import FormInputComponent from "../../form-fields/form-input.component";
import Table from "../../../shared/table/table.component";
import "./allocated-gl-form.component.scss";

export default function AllocatedGLForm({
  onClose,
  onSubmit,
  glTransaction,
  isConfirmating,
}: GLFormComponentProps) {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    ...(glTransaction && { defaultValues: glTransaction }),
  });
  const [isValid, setIsValid] = useState<boolean>(true);

  const allocationModifications = glTransaction.allocatedTransactions.map(
    (_, index) => watch(`allocatedTransactions.${index}.allocationModification`)
  );

  const modificationSum = useMemo(() => {
    const totalSum = allocationModifications.reduce(
      (acc, curr) => (isNaN(curr) ? acc : acc + curr),
      0
    );
    return totalSum;
  }, [allocationModifications]);

  const exceedingRowsIds = useMemo(() => {
    const ids = [];
    for (let i = 0; i < glTransaction.allocatedTransactions.length; i++) {
      if (
        !isNaN(allocationModifications[i]) &&
        allocationModifications[i] >
          glTransaction.allocatedTransactions[i].remainingBalance
      ) {
        ids.push(glTransaction.allocatedTransactions[i].id);
      }
    }
    return ids;
  }, [allocationModifications, glTransaction]);

  useEffect(() => {
    if (modificationSum !== 0 || exceedingRowsIds.length > 0) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [modificationSum, exceedingRowsIds]);

  const COLUMNS = useMemo(
    () => [
      {
        header: t("company.tabs.gl.form.allocated.list.edgeId"),
        property: "edgeId",
      },
      {
        header: t("company.tabs.gl.form.allocated.list.grantCode"),
        property: "grantCode",
      },
      {
        header: t("company.tabs.gl.form.allocated.list.transactionAmount"),
        property: "transactionAmount",
      },
      {
        header: t("company.tabs.gl.form.allocated.list.allocationModification"),
        property: "allocationModification",
        render: ({ id }: Record<string, any>): JSX.Element => {
          const idIndex = glTransaction.allocatedTransactions.findIndex(
            (transaction) => transaction.id === id
          );
          return (
            <input
              readOnly={isConfirmating}
              className="form-control p-0 text-end fs-none"
              {...register(
                `allocatedTransactions.${idIndex}.allocationModification`,
                {
                  valueAsNumber: true,
                  pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                }
              )}
            />
          );
        },
      },
      {
        header: t("company.tabs.gl.form.allocated.list.remainingBalance"),
        property: "remainingBalance",
      },
    ],
    [t, glTransaction, register, isConfirmating]
  );

  const saveCallback = useCallback(
    (values: FieldValues) => {
      const data = values.allocatedTransactions
        .filter((transaction: any) => transaction.allocationModification)
        .map((transaction: any) => ({
          id: transaction.id.toString(),
          parentId: values.id.toString(),
          allocationModification: transaction.allocationModification.toString(),
        }));
      return glAccountsApi.updateAllocatedGL(
        company.id,
        glTransaction.id.toString(),
        data
      );
    },
    [company.id, glTransaction]
  );

  return (
    <form
      className="allocated-gl-form"
      onSubmit={handleSubmit((values) => onSubmit(values, saveCallback))}
    >
      <div className="gap-4 row">
        <div className="col">
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.gl.form.allocated.accountingPeriod")}
              inputProps={register("accountingPeriod")}
              property={"accountingPeriod"}
              error={errors?.accountingPeriod?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.gl.form.allocated.programId")}
              inputProps={register("programId")}
              property={"programId"}
              error={errors?.programId?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.gl.form.allocated.accountId")}
              inputProps={register("accountId")}
              property={"accountId"}
              error={errors?.accountId?.message}
            />
          </div>
          <div className="mb-4">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.gl.form.allocated.transactionAmount")}
              inputProps={register("transactionAmount")}
              property={"transactionAmount"}
              error={errors?.transactionAmount?.message}
            />
          </div>
          {!!exceedingRowsIds.length && (
            <div className="mb-0 text-center bg-danger text-dark">
              <p>{t("company.tabs.gl.form.allocated.exceedingModification")}</p>
            </div>
          )}
          <div className="mb-0">
            <Table
              columns={COLUMNS}
              data={glTransaction.allocatedTransactions}
              classNames={{
                row: ({ id }) =>
                  exceedingRowsIds.includes(id) ? "text-danger" : "",
              }}
            />
          </div>
          {!!modificationSum && (
            <div className="mb-0 text-center bg-danger text-dark">
              <p>
                {t("company.tabs.gl.form.allocated.sumNonZero", {
                  sum: modificationSum,
                })}
              </p>
            </div>
          )}
          <div className="gap-2 modal-buttons">
            <button onClick={onClose} className="btn w-50 btn-secondary">
              {t("general.cancel")}
            </button>
            <button
              disabled={!isValid}
              type="submit"
              className="btn w-50 btn-primary"
            >
              {isConfirmating ? t("general.confirm") : t("general.save")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
