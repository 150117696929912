import React, {
  useCallback,
  useContext,
  useMemo,
  useEffect,
  useState,
} from "react";
import { useForm, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { USER_ROLE_LABELS, USER_ROLES } from "../../../../consts";
import {
  UpdatedPayroll,
  PayrollAllocationModification,
} from "../../../../interfaces/interfaces";
import { CompanyContext } from "../../../../context/company-context";
import { UserContext } from "../../../../context/user.context";
import payrollApi from "../../../../api/payroll-api";
import { useLoadData } from "../../../../hooks/useLoadData";
import { PayrollFormComponentProps } from "../payroll-form.component";
import FormInputComponent from "../../form-fields/form-input.component";
import Table from "../../../shared/table/table.component";
import Spinner from "../../../shared/spinner/spinner.component";

export default function AllocatedPayrollFormComponent({
  onClose,
  onSubmit,
  isConfirmating,
  payroll,
}: PayrollFormComponentProps) {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const isUserAdmin = [
    USER_ROLE_LABELS[USER_ROLES.ROLE_ADMIN],
    USER_ROLE_LABELS[USER_ROLES.ROLE_COMPANY_ADMIN],
  ].includes(user.role);
  const [updatedPR, isLoading] = useLoadData<UpdatedPayroll>({
    fetcher: useCallback(
      () => payrollApi.getUpdatedPR(company.id, payroll?.id),
      [company.id, payroll]
    ),
  });
  const [isValid, setIsValid] = useState<boolean>(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    ...(updatedPR && { defaultValues: updatedPR }),
  });

  const watchAll = watch();

  const programAllocationModifications = useMemo(
    () =>
      watchAll?.programAllocations?.map(
        (allocation) => allocation.allocationModification
      ),
    [watchAll]
  );

  const programModificationSum = useMemo(() => {
    const totalSum = programAllocationModifications?.reduce(
      (acc, curr) => (isNaN(curr) ? acc : acc + curr),
      0
    );
    return totalSum;
  }, [programAllocationModifications]);

  const programExceedingRowsIds = useMemo(() => {
    const ids = [];
    for (let i = 0; i < watchAll?.programAllocations?.length; i++) {
      if (
        !isNaN(programAllocationModifications[i]) &&
        programAllocationModifications[i] >
          watchAll?.programAllocations[i]?.remainingBalance
      ) {
        ids.push(watchAll?.programAllocations[i].id);
      }
    }
    return ids;
  }, [programAllocationModifications, watchAll]);

  const benefitsAllocationModifications = useMemo(
    () =>
      watchAll?.benefitsAllocations?.map(
        (allocation) => allocation.allocationModification
      ),
    [watchAll]
  );

  const benefitsModificationSum = useMemo(() => {
    const totalSum = benefitsAllocationModifications?.reduce(
      (acc, curr) => (isNaN(curr) ? acc : acc + curr),
      0
    );
    return totalSum;
  }, [benefitsAllocationModifications]);

  const benefitsExceedingRowsIds = useMemo(() => {
    const ids = [];
    for (let i = 0; i < watchAll?.benefitsAllocations?.length; i++) {
      if (
        !isNaN(benefitsAllocationModifications[i]) &&
        benefitsAllocationModifications[i] >
          watchAll?.benefitsAllocations[i]?.remainingBalance
      ) {
        ids.push(watchAll?.benefitsAllocations[i].id);
      }
    }
    return ids;
  }, [benefitsAllocationModifications, watchAll]);

  useEffect(() => {
    if (
      programModificationSum !== 0 ||
      programExceedingRowsIds.length > 0 ||
      benefitsModificationSum !== 0 ||
      benefitsExceedingRowsIds.length > 0
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [
    programModificationSum,
    programExceedingRowsIds,
    benefitsModificationSum,
    benefitsExceedingRowsIds,
  ]);

  useEffect(() => {
    if (!isLoading) {
      reset(updatedPR);
    }
  }, [isLoading, reset, updatedPR]);

  const getColumns = useCallback(
    (collectionName: "programAllocations" | "benefitsAllocations") => [
      {
        header: t("company.tabs.gl.form.allocated.list.edgeId"),
        property: "edgeId",
      },
      {
        header: t("company.tabs.gl.form.allocated.list.grantCode"),
        property: "grantCode",
      },
      {
        header: t("company.tabs.gl.form.allocated.list.transactionAmount"),
        property: "transactionAmount",
      },
      {
        header: t("company.tabs.gl.form.allocated.list.allocationModification"),
        property: "allocationModification",
        render: ({ id }: Record<string, any>): JSX.Element => {
          const idIndex = updatedPR[collectionName].findIndex(
            (allocation) => allocation.id === id
          );
          return (
            <input
              readOnly={isConfirmating}
              className="form-control p-0 text-end fs-none"
              {...register(
                `${collectionName}.${idIndex}.allocationModification`,
                {
                  valueAsNumber: true,
                  pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                }
              )}
            />
          );
        },
      },
      {
        header: t("company.tabs.gl.form.allocated.list.remainingBalance"),
        property: "remainingBalance",
      },
    ],
    [t, isConfirmating, register, updatedPR]
  );

  const saveCallback = (values: FieldValues) => {
    const data = {
      programAllocations: [] as Array<PayrollAllocationModification>,
      benefitsAllocations: [] as Array<PayrollAllocationModification>,
    };
    for (const programAllocation of values.programAllocations.filter(
      (allocation: { allocationModification: any }) =>
        !isNaN(allocation.allocationModification)
    )) {
      data.programAllocations.push({
        id: programAllocation.id,
        parentId: values.id,
        allocationModification: programAllocation.allocationModification,
      });
    }
    for (const benefitAllocation of values.benefitsAllocations.filter(
      (allocation: { allocationModification: any }) =>
        !isNaN(allocation.allocationModification)
    )) {
      data.benefitsAllocations.push({
        id: benefitAllocation.id,
        parentId: values.id,
        allocationModification: benefitAllocation.allocationModification,
      });
    }
    return payrollApi.updateAllocatedPR(company.id, payroll!.id, data);
  };

  return isLoading ? (
    <Spinner size="medium" />
  ) : (
    <form
      onSubmit={handleSubmit((data: FieldValues) =>
        onSubmit(data, saveCallback)
      )}
    >
      <div className="gap-4 row">
        <div className="col">
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.payrollRegister.form.payDate")}
              inputProps={register("payDate")}
              property={"payDate"}
              error={errors?.payDate?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.payrollRegister.form.programAllocationId")}
              inputProps={register("programId")}
              property={"programId"}
              error={errors?.programId?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.payrollRegister.form.jFACR")}
              inputProps={register("jfAcronym")}
              property={"jfAcronym"}
              error={errors?.jfAcronym?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t(
                "company.tabs.payrollRegister.form.payrollProgramAllocation"
              )}
              inputProps={register("payrollProgramAllocationAmount")}
              property={"payrollProgramAllocationAmount"}
              error={errors?.payrollProgramAllocationAmount?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t(
                "company.tabs.payrollRegister.form.prBenefitProgramAllocation"
              )}
              inputProps={register("payrollBenefitsAllocationAmount")}
              property={"payrollBenefitsAllocationAmount"}
              error={errors?.payrollBenefitsAllocationAmount?.message}
            />
          </div>
          {!!updatedPR?.programAllocations?.length && (
            <>
              {!!programExceedingRowsIds.length && (
                <div className="mb-0 text-center bg-danger text-dark">
                  <p>
                    {t("company.tabs.gl.form.allocated.exceedingModification")}
                  </p>
                </div>
              )}
              <div className="mb-0">
                <Table
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  columns={getColumns("programAllocations")}
                  data={updatedPR.programAllocations}
                  classNames={{
                    row: ({ id }) =>
                      programExceedingRowsIds.includes(id) ? "text-danger" : "",
                  }}
                />
              </div>
              {!!programModificationSum && (
                <div className="mb-0 text-center bg-danger text-dark">
                  <p>
                    {t("company.tabs.gl.form.allocated.sumNonZero", {
                      sum: programModificationSum,
                    })}
                  </p>
                </div>
              )}
            </>
          )}
          {!!updatedPR?.benefitsAllocations?.length && (
            <>
              {!!benefitsExceedingRowsIds.length && (
                <div className="mb-0 text-center bg-danger text-dark">
                  <p>
                    {t("company.tabs.gl.form.allocated.exceedingModification")}
                  </p>
                </div>
              )}
              <div className="mb-0">
                <Table
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  columns={getColumns("benefitsAllocations")}
                  data={updatedPR.benefitsAllocations}
                  classNames={{
                    row: ({ id }) =>
                      benefitsExceedingRowsIds.includes(id)
                        ? "text-danger"
                        : "",
                  }}
                />
              </div>
              {!!benefitsModificationSum && (
                <div className="mb-0 text-center bg-danger text-dark">
                  <p>
                    {t("company.tabs.gl.form.allocated.sumNonZero", {
                      sum: benefitsModificationSum,
                    })}
                  </p>
                </div>
              )}
            </>
          )}
          {isUserAdmin ? (
            <div className="gap-2 modal-buttons">
              <button onClick={onClose} className="btn w-50 btn-secondary">
                {t("general.cancel")}
              </button>
              <button
                type="submit"
                className="btn w-50 btn-primary"
                disabled={!isValid}
              >
                {isConfirmating ? t("general.confirm") : t("general.save")}
              </button>
            </div>
          ) : (
            <div className="d-flex justify-content-center pt-2">
              <button onClick={onClose} className="btn w-75 btn-secondary">
                {t("general.cancel")}
              </button>
            </div>
          )}
        </div>
      </div>
    </form>
  );
}
