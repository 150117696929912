import { Payroll } from "../../interfaces/interfaces";
import { formatDate, formatNumber } from "../../utils";
import { TFunction } from "i18next";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { IconType } from "react-icons";
import { Dispatch, ReactNode, SetStateAction } from "react";

const getIcon = (isExpanded: boolean): IconType =>
  isExpanded ? FaAngleUp : FaAngleDown;

export const getColumns = ({
  t,
  expandedRows,
  setExpandedRows,
  onExpandAll,
  isAllCollapsed,
  isUserAdmin,
}: {
  t: TFunction;
  expandedRows: Record<string, boolean>;
  setExpandedRows: Dispatch<SetStateAction<Record<string, boolean>>>;
  onExpandAll: () => void;
  isAllCollapsed: boolean;
  isUserAdmin: boolean;
  setPayrollToEdit: (pr: Payroll & { allocated: boolean }) => void;
  isPayrollAllocated: (pr: Payroll) => boolean;
}) => [
  ...(isUserAdmin
    ? [
        {
          header: "",
          property: "actionList",
          render: (glTransaction: Payroll): JSX.Element => {
            const isParent = glTransaction?.children?.length;
            return isParent ? (
              <br />
            ) : (
              <div className="dropdown">
                <p className="dropdown-toggle" data-bs-toggle="dropdown">
                  {t("company.tabs.gl.list.actions.title") as ReactNode}
                </p>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item">
                      {t("company.tabs.gl.list.actions.edit") as ReactNode}
                    </a>
                  </li>
                </ul>
              </div>
            );
          },
        },
      ]
    : []),
  {
    renderHeader: () => {
      const string = t("company.tabs.payrollRegister.list.edgeId");
      return (
        <div>
          <div>{string}</div>
          <div onClick={onExpandAll}>
            <span>expand all</span>
            {isAllCollapsed ? <FaAngleDown /> : <FaAngleUp />}
          </div>
        </div>
      );
    },
    property: "edgeId",
    render: ({ edgeId }: Payroll) => edgeId,
    customComponent: ({ edgeId, id }: Payroll) =>
      edgeId
        ? edgeId
        : getIcon(expandedRows[id])({
            onClick: () =>
              setExpandedRows((state) => ({
                ...state,
                [id]: !state[id],
              })),
          }),
  },
  {
    header: t("company.tabs.payrollRegister.list.employee"),
    property: "employeeName",
  },
  {
    header: t("company.tabs.payrollRegister.list.programAllocationId"),
    property: "programAllocationId",
  },
  {
    header: t("company.tabs.payrollRegister.list.programAllocationName"),
    property: "programAllocationName",
  },
  {
    header: t("company.tabs.payrollRegister.list.jobFunctionAcronym"),
    property: "jFACR",
  },
  {
    header: t("company.tabs.payrollRegister.list.grantId"),
    property: "grantCode",
  },
  {
    header: t("company.tabs.payrollRegister.list.allocationPercentage"),
    property: "allocationPercentage",
  },
  {
    header: t("company.tabs.payrollRegister.list.accountingPeriod"),
    property: "accountingPeriod",
  },
  {
    header: t("company.tabs.payrollRegister.list.payDate"),
    property: "payDate",
    format: (value: string | JSX.Element) => {
      if (typeof value === "string") {
        return formatDate({ dateString: value });
      }
      return "";
    },
  },
  {
    header: t("company.tabs.payrollRegister.list.checkId"),
    property: "checkId",
  },
  {
    header: t("company.tabs.payrollRegister.list.payrollProgramAllocation"),
    property: "payrollProgramAllocation",
    format: (value: string) => formatNumber(+value),
  },
  {
    header: t("company.tabs.payrollRegister.list.prBenefitProgramAllocation"),
    property: "prBenefitProgramAllocation",
    format: (value: string) => formatNumber(+value),
  },
];
