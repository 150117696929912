import { useContext, useEffect, useMemo, useState } from "react";
import {
  CONFIG,
  ERRORS,
  getJobFunctionColumns,
} from "../action-header/helpers";
import jobFunctionsApi from "../../../api/job-functions-api";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import { CompanyContext } from "../../../context/company-context";

export const useRegions = () => {
  const { company } = useContext(CompanyContext);
  const errorHandler = useErrorHandler();
  const [regions, setRegions] = useState<string[]>([]);
  useEffect(() => {
    jobFunctionsApi.getJobFunctionsRegions(company.id).then(
      (response) => {
        setRegions(response);
      },
      (err) => {
        errorHandler(err);
        setRegions([]);
      }
    );
  }, [company, errorHandler]);

  const configWithRegion = useMemo(
    () => ({
      ...CONFIG,
      [ERRORS.jobFunction]: {
        ...CONFIG[ERRORS.jobFunction],
        columns: getJobFunctionColumns(regions),
      },
    }),
    [regions]
  );
  return {
    configWithRegion,
  };
};
