import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import "./upload-button.component.scss";
import { useTranslation } from "react-i18next";
import ErrorModalComponent from "./error-modal/error-modal.component";
import { UploadingError } from "../../../../interfaces/interfaces";
import { ToastContext } from "../../../../context/toast.context";
import { TOAST_TYPES, RELOAD_UPLOAD_TYPE } from "../../../../consts";
import { ReloadModalValue } from "../../../../interfaces/types";
import { useErrorHandler } from "../../../../hooks/useErrorHandler";
import { SpinnerButton } from "../../spinner/spinner-button";
import { AxiosResponse } from "axios";
import Modal from "../../modal/modal.component";

interface UploadButtonProps {
  title?: string;
  upload?: (
    files: FileList,
    selection: string
  ) => Promise<AxiosResponse | void>;
  multiple?: boolean;
  uploadWarning?: string;
  confirmation?: boolean;
  preselection?: ReloadModalValue;
  disabled?: boolean;
}

export default function UploadButtonComponent({
  title,
  upload,
  multiple = false,
  uploadWarning,
  confirmation,
  preselection,
  disabled,
}: UploadButtonProps): JSX.Element {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [errors, setErrors] = useState<Array<UploadingError>>([]);
  const { addToast } = useContext(ToastContext);
  const errorHandler = useErrorHandler();
  const [isLoading, setIsLoading] = useState(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [isConfirmating, setIsConfirmating] = useState<boolean>(false);
  const [selection, setSelection] = useState<string>(preselection || "");

  const resetConfirmation = useCallback(() => {
    setShowConfirmation(false);
    setIsConfirmating(false);
    setSelection(preselection || "");
  }, [setShowConfirmation, setIsConfirmating, setSelection, preselection]);

  const onUploadHandler = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>): void => {
      setIsLoading(true);
      const files = target?.files;
      if (files && upload) {
        upload(files, selection)
          .then(
            () => {
              addToast({
                id: 1,
                message: t("general.uploadSuccess"),
                type: TOAST_TYPES.success,
              });
            },
            (error) => {
              if (error?.errors?.length) {
                setErrors(error.errors);
                setShowModal(true);
              } else {
                errorHandler(error);
              }
            }
          )
          .finally(() => {
            setIsLoading(false);
          });
        target.value = "";
      }
    },
    [addToast, errorHandler, t, upload, selection]
  );

  const ref = useRef<HTMLInputElement>(null);

  const clickHandler = useCallback(() => {
    if (confirmation) {
      if (isConfirmating && showConfirmation) {
        ref.current?.click();
        setShowConfirmation(false);
        setIsConfirmating(false);
        return;
      }
      if (!isConfirmating && showConfirmation) {
        setIsConfirmating(true);
        return;
      }
      if (!showConfirmation) {
        setShowConfirmation(true);
        return;
      }
    }
    if (!uploadWarning || showWarning) {
      ref.current?.click();
      setShowWarning(false);
      return;
    }
    if (!showWarning) {
      setShowWarning(true);
      return;
    }
  }, [
    showWarning,
    uploadWarning,
    confirmation,
    isConfirmating,
    showConfirmation,
    setShowConfirmation,
  ]);

  return (
    <>
      <Modal
        className="upload-warning-modal"
        show={showWarning}
        title={t("general.warning")}
        onClose={() => setShowWarning(false)}
        Footer={[
          <button onClick={clickHandler} className="btn btn-primary">
            {t("general.upload")}
          </button>,
          <button
            onClick={() => {
              resetConfirmation();
              setShowWarning(false);
            }}
            className="btn btn-secondary"
          >
            {t("general.cancel")}
          </button>,
        ]}
      >
        <div>{uploadWarning}</div>
      </Modal>
      <ErrorModalComponent
        show={showModal}
        onClose={() => setShowModal(false)}
        errors={errors}
      />
      <Modal
        title={t("reloadModal.title")}
        show={showConfirmation}
        {...(isConfirmating && { subtitle: t("reloadModal.subtitle") })}
        Footer={
          <div className="gap-3 confirmation-footer footer">
            <button onClick={resetConfirmation} className="btn btn-secondary">
              {t("general.cancel")}
            </button>
            <button
              onClick={clickHandler}
              className="btn btn-primary"
              disabled={!selection}
            >
              {isConfirmating ? t("reloadModal.approve") : t("general.ok")}
            </button>
          </div>
        }
      >
        <div className="mt-2 d-flex flex-column justify-content-center align-items-center">
          <p>{t("reloadModal.header")}</p>
          <div className="ms-2">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="reloadType"
                id="reloadModalNew"
                disabled={isConfirmating}
                onClick={() => setSelection(RELOAD_UPLOAD_TYPE.NEW)}
                checked={selection === RELOAD_UPLOAD_TYPE.NEW}
              />
              <label className="form-check-label" htmlFor="reloadModalNew">
                {t("reloadModal.radioLabelNew")}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="reloadType"
                id="reloadModalAll"
                disabled={isConfirmating}
                onClick={() => setSelection(RELOAD_UPLOAD_TYPE.ALL)}
                checked={selection === RELOAD_UPLOAD_TYPE.ALL}
              />
              <label className="form-check-label" htmlFor="reloadModalAll">
                {t("reloadModal.radioLabelAll")}
              </label>
            </div>
          </div>
        </div>
      </Modal>
      <button
        ref={buttonRef}
        disabled={disabled || isLoading}
        style={{ color: isLoading ? "#000" : "#fff" }}
        onClick={clickHandler}
        type="button"
        className="btn btn-primary"
      >
        <input
          multiple={multiple}
          ref={ref}
          hidden
          type="file"
          onChange={onUploadHandler}
        />
        {title || t("general.upload")}
        {isLoading && <SpinnerButton target={buttonRef.current} />}
      </button>
    </>
  );
}
