import React, { useCallback, useContext, useState, useMemo } from "react";
import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TOAST_TYPES, USER_ROLES, USER_ROLE_LABELS } from "../../consts";
import { MCOAInterface } from "../../interfaces/interfaces";
import mcoaApi from "../../api/mcoa-api";
import { CompanyContext } from "../../context/company-context";
import { UserContext } from "../../context/user.context";
import { ToastContext } from "../../context/toast.context";
import { useLoadData } from "../../hooks/useLoadData";
import ButtonsComponent from "../../components/shared/download-upload-buttons/buttons.component";
import EmptyListComponent from "../../components/shared/empty-list/empty-list.component";
import McoaFormComponent from "../../components/forms/mcoa/mcoa-form.component";
import Modal from "../../components/shared/modal/modal.component";
import Table from "../../components/shared/table/table.component";
import Spinner from "../../components/shared/spinner/spinner.component";
import "./MCOA.component.scss";
import { DownloadButtonComponent } from "../../components/shared/download-button/download-button.component";
import fileApi from "../../api/file-api";

export default function MCOAComponent(): JSX.Element {
  const [showForm, setShowForm] = useState<boolean>(false);
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { addToast } = useContext(ToastContext);
  const { company } = useContext(CompanyContext);
  const [entityToEdit, setEntityToEdit] = useState<MCOAInterface | undefined>(
    undefined
  );
  const [mcoaList, mcoaListLoading, loadMcoaList] = useLoadData<
    MCOAInterface[]
  >({
    fetcher: useCallback(() => mcoaApi.getMCOAs(company?.id), [company]),
  });
  const isReadonly = useMemo(
    () => user.role === USER_ROLE_LABELS[USER_ROLES.ROLE_USER],
    [user.role]
  );

  const submitHandler = useCallback(
    (values: FieldValues) => {
      const fetcher = values.id ? mcoaApi.updateMCOA : mcoaApi.createMCOA;
      fetcher({ companyId: company.id, data: values })
        .then(
          (data: MCOAInterface) => {
            addToast({
              id: data.id,
              type: TOAST_TYPES.success,
              message: t(
                `company.tabs.mcoa.${values.id ? "edit" : "add"}Success`
              ),
            });
            loadMcoaList();
            setShowForm(false);
          },
          (err) => {
            addToast({
              id: company.id,
              type: TOAST_TYPES.error,
              message: err.title || t("general.serverError"),
            });
          }
        )
        .finally(() => {
          setEntityToEdit(undefined);
          setShowForm(false);
        });
    },
    [addToast, company, loadMcoaList, t]
  );

  const uploadFile = useCallback(
    async (files: FileList): Promise<void> => {
      const formData = new FormData();
      formData.append("file", files[0]);

      const uploadData = await mcoaApi.uploadMCOA(formData, company.id);
      if (uploadData?.errors?.length) {
        return Promise.reject(uploadData);
      }
      loadMcoaList();
    },
    [company, loadMcoaList]
  );

  const COLUMNS: {
    header: string;
    property: keyof MCOAInterface;
    sort?: boolean;
    render?: (row: MCOAInterface) => JSX.Element | string;
  }[] = useMemo(
    () => [
      {
        header: t("company.tabs.mcoa.list.account"),
        property: "code",
        sort: true,
      },
      {
        header: t("company.tabs.mcoa.list.description"),
        property: "name",
        sort: true,
      },
      {
        header: t("company.tabs.mcoa.list.class"),
        property: "accountClass",
        sort: true,
      },
      {
        header: t("company.tabs.mcoa.list.type"),
        property: "accountGrouping.name",
        render: (row: MCOAInterface) => row?.accountGrouping?.name,
        sort: true,
      },
    ],
    [t]
  );

  const importActions = useMemo(
    () => [
      {
        title: t("company.tabs.mcoa.list.import"),
        url: "templates?templateName=MCOA",
      },
    ],
    [t]
  );

  const uploadActions = useMemo(
    () => [
      {
        title: t("company.tabs.mcoa.list.upload"),
        action: uploadFile,
        disabled: isReadonly,
      },
    ],
    [t, uploadFile, isReadonly]
  );

  const renderMCOAs = useCallback(
    () =>
      mcoaList.length ? (
        <div>
          <div className="list-header">
            <div className="actions gap-2">
              <button
                disabled={isReadonly}
                onClick={() => setShowForm(true)}
                className="btn btn-primary"
              >
                {t("company.tabs.mcoa.list.add")}
              </button>
              {ButtonsComponent({ uploadActions })}
              <DownloadButtonComponent
                label={t("general.export")}
                downloadHandler={() =>
                  fileApi.downloadFile(
                    `company/${company.id}/masterchart-accounts-export`
                  )
                }
              />
            </div>
          </div>
          <Table
            columns={COLUMNS}
            search={true}
            data={mcoaList}
            {...(!isReadonly
              ? {
                  onRowClick: (row: MCOAInterface) => {
                    setEntityToEdit(row);
                    setShowForm(true);
                  },
                }
              : {})}
          />
        </div>
      ) : (
        <EmptyListComponent
          {...{
            importActions,
            uploadActions,
            description: t("company.tabs.mcoa.list.emptyTitle"),
          }}
        />
      ),
    [mcoaList, t, importActions, uploadActions, COLUMNS, company.id, isReadonly]
  );

  return (
    <div className="mcoa">
      <Modal
        centredContent={false}
        show={showForm}
        title={t(`company.tabs.mcoa.list.${entityToEdit ? "edit" : "add"}`)}
      >
        <McoaFormComponent
          key={entityToEdit?.id}
          entity={entityToEdit}
          onSubmit={submitHandler}
          onClose={() => {
            setShowForm(false);
            setEntityToEdit(undefined);
          }}
        />
      </Modal>
      {mcoaListLoading ? <Spinner size="medium" /> : renderMCOAs()}
    </div>
  );
}
