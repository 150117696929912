import React from "react";
import "./page-layout-component.scss";
import classNames from "classnames";

interface PageLayoutProps {
  title?: () => JSX.Element | string;
  action?: () => void;
  actionTitle?: string;
  actionNode?: () => JSX.Element | null;
  children: React.ReactNode;
  className?: string;
  smallHeader?: boolean;
}

export default function PageLayout({
  title,
  action,
  actionTitle,
  children,
  actionNode,
  className,
  smallHeader = false,
}: PageLayoutProps): JSX.Element {
  return (
    <div
      className={classNames(
        className,
        "page-layout",
        smallHeader ? "header-small" : ""
      )}
    >
      <header className="header">
        {typeof title !== "function" ? <h4>{title}</h4> : title()}
        {action && (
          <button className="btn btn-primary" onClick={action}>
            {actionTitle}
          </button>
        )}
        {actionNode && actionNode()}
      </header>
      {children}
    </div>
  );
}
