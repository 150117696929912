import { Navigate, NavLink, Route, Routes } from "react-router-dom";
import "./company-routes.component.scss";
import CompanyComponent from "../../company/company.component";
import React, { useContext, useEffect } from "react";
import MCOAComponent from "../../mcoa/MCOA.component";
import { colors, COMPANY_PARTS } from "../../../consts";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import Programs from "../../programs/programs.component";
import { CompanyContext } from "../../../context/company-context";
import companyApi from "../../../api/company-api";
import { Company } from "../../../interfaces/interfaces";
import JobFunctions from "../../job-functions/job-functions.component";
import Grants from "../../grants/grants.component";
import GlAccountListComponent from "../../gl-account/gl-account.component";
import { DocumentsComponent } from "../../documents/documents.component";
import UserDetailsComponent from "../../user-details/user-details.component";
import PayrollRegisterComponent from "../../payroll-register/payroll-register.component";
import BillingAllocationComponent from "../../control-center/control-center.component";
import CompanyHeaderComponent from "../../../components/shared/company-header/company-header.component";
import DashboardRoutes from "../../dashboard/dashboard-routes.component";
import Logo from "../../../components/shared/logo/logo.component";
import UserMenu from "../../../components/layout/header/userMenu/user-menu-component";

export default function CompanyRoutesComponent(): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams();
  const { setCompany, company } = useContext(CompanyContext);
  useEffect(() => {
    const navElements = document.querySelectorAll("nav");
    navElements.forEach((nav) => {
      if (!nav.classList.contains("shownav")) {
        nav.remove();
      }
    });
  }, []);
  useEffect(() => {
    if (!id) {
      setCompany(null);
      return;
    }
    companyApi.getCompany(id).then((data: Company): void => setCompany(data));
    return () => {
      setCompany(null);
    };
  }, [id, setCompany]);
  return (
    <>
      <nav className="navbar navbar-expand-lg shownav">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <Logo
              secondaryColor={colors.darkBlue}
              textColor={colors.darkBlue}
              width="120"
              height="45"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar"
            aria-controls="navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          />
          <div className="collapse navbar-collapse" id="navbar">
            <ul className="navbar-nav me-auto gap-3 mb-2 mb-lg-0">
              {Object.values(COMPANY_PARTS).map((item: string) => (
                <NavLink
                  key={item}
                  className={(navData) =>
                    navData.isActive
                      ? "nav-link grant-budget active"
                      : "nav-link grant-budget"
                  }
                  to={item}
                >
                  {t(`company.tabs.${item}.title`)}
                </NavLink>
              ))}
            </ul>
            <UserMenu />
          </div>
        </div>
      </nav>
      <CompanyHeaderComponent id={id} />
      {company && (
        <div className="content">
          <Routes>
            <Route path="/dashboard/*" element={<DashboardRoutes />} />
            <Route path="/details" element={<CompanyComponent />} />
            <Route path="/users" element={<UserDetailsComponent />} />
            <Route path="/mcoa" element={<MCOAComponent />} />
            <Route path="/programs" element={<Programs />} />
            <Route path="/job-functions" element={<JobFunctions />} />
            <Route path="/grants/*" element={<Grants />} />
            <Route path="/gl" element={<GlAccountListComponent />} />
            <Route path="/documents/*" element={<DocumentsComponent />} />
            <Route
              path="/control-center"
              element={<BillingAllocationComponent />}
            />
            <Route
              path="/payrollRegister/"
              element={<PayrollRegisterComponent />}
            />
            <Route path="/" element={<Navigate to="details" replace />} />
          </Routes>
        </div>
      )}
    </>
  );
}
