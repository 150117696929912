import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SpinnerButton } from "../spinner/spinner-button";
import Modal from "../modal/modal.component";

interface WarningButtonProps {
  title: string;
  warning?: string;
  callback: () => Promise<void>;
  disabled?: boolean;
}

export default function WarningButtonComponent({
  title,
  warning,
  callback,
  disabled,
}: WarningButtonProps): JSX.Element {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const ref = useRef<HTMLButtonElement>(null);

  const callbackHandler = useCallback(() => {
    setIsLoading(true);
    callback().finally(() => setIsLoading(false));
  }, [setIsLoading, callback]);

  const clickHandler = useCallback(() => {
    if (!warning || showWarning) {
      setShowWarning(false);
      callbackHandler();
      return;
    }
    if (!showWarning) {
      setShowWarning(true);
      return;
    }
  }, [warning, showWarning, setShowWarning, callbackHandler]);

  return (
    <>
      <Modal
        className="upload-warning-modal"
        show={showWarning}
        title={t("general.warning")}
        onClose={() => setShowWarning(false)}
        Footer={[
          <button
            onClick={() => setShowWarning(false)}
            className="btn btn-secondary"
          >
            {t("general.cancel")}
          </button>,
          <button onClick={clickHandler} className="btn btn-primary">
            {t("general.ok")}
          </button>,
        ]}
      >
        <div>{warning}</div>
      </Modal>
      <button
        ref={ref}
        disabled={disabled || isLoading}
        style={{ color: isLoading ? "#000" : "#fff" }}
        onClick={clickHandler}
        type="button"
        className="btn btn-primary"
      >
        {title}
        {isLoading && <SpinnerButton target={ref.current} />}
      </button>
    </>
  );
}
