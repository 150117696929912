import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";

interface FormDatePickerProps {
  label?: string;
  property: string;
  error?: string | undefined;
  inputProps: UseFormRegisterReturn;
  readonly?: boolean;
}

export default function FormDatePicker({
  label,
  property,
  error,
  inputProps,
  readonly,
}: FormDatePickerProps): JSX.Element {
  return (
    <div className="mb-3 form-field">
      <label htmlFor={property} className="form-label">
        {label}
      </label>
      <input
        readOnly={readonly}
        type={"date"}
        className="form-control"
        id={property}
        {...inputProps}
      />
      {error && <div className="error">{error}</div>}
    </div>
  );
}
