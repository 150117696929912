import React from "react";
import { FieldValues } from "react-hook-form";
import { Program } from "../../../interfaces/interfaces";
import AllocatedPayrollFormComponent from "./allocatedPR/allocated-pr-form.component";
import UnallocatedPayrollFormComponent from "./unallocatedPR/unallocated-pr-form.component";

export interface PayrollFormComponentProps {
  onClose: () => void;
  onSubmit: (
    data: FieldValues,
    callback: (values: FieldValues) => Promise<any>
  ) => void;
  isConfirmating: boolean;
  payroll?: FieldValues;
  programs: Program[];
}

export default function PayrollFormComponent(
  props: PayrollFormComponentProps
): JSX.Element {
  const { payroll } = props;
  if (payroll?.allocated) {
    return <AllocatedPayrollFormComponent {...props} />;
  } else {
    return <UnallocatedPayrollFormComponent {...props} />;
  }
}
