import React, { useContext, useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { USER_ROLE_LABELS, USER_ROLES } from "../../../consts";
import { EMAIL_VALIDATOR, PHONE_NUMBER_VALIDATOR } from "../../../consts/forms";
import { Company, User } from "../../../interfaces/interfaces";
import companyApi from "../../../api/company-api";
import permissionsApi from "../../../api/permissions-api";
import userApi from "../../../api/user-api";
import utils from "../../../api/utils";
import { CompanyContext } from "../../../context/company-context";
import { ToastContext } from "../../../context/toast.context";
import { UserContext } from "../../../context/user.context";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import CreateEntityModal from "../../shared/create-entity-modal/modal.component";
import ConfirmationModalComponent from "../../shared/confirmation-modal/confirmation-modal.component";
import FormFieldComponent from "../form-fields/form-input.component";
import FormPhoneComponent from "../form-fields/form-phone.component";
import "./user-form.component.scss";

interface UserFormProps {
  entity?: User | null;
  sendInvite?: (id: string | undefined) => void;
  readonly?: boolean;
  getUsers?: () => void;
  onClose?: () => void;
  showSendInviteButton?: boolean;
  allowSuperAdminRole?: boolean;
}

export default function UserForm({
  entity,
  sendInvite,
  readonly = false,
  getUsers,
  onClose,
  showSendInviteButton = true,
  allowSuperAdminRole = true,
}: UserFormProps): JSX.Element {
  const { company } = useContext(CompanyContext);
  const [availableCompanies, setAvailableCompanies] = useState<Array<Company>>(
    []
  );
  const [createdUser, setCreatedUser] = useState<FieldValues | null>(null);
  const { user } = useContext(UserContext);
  const isSuperAdminRole = useMemo(
    () => user.role === USER_ROLE_LABELS[USER_ROLES.ROLE_ADMIN],
    [user]
  );

  const [showEditEmailConfirmation, setShowEditEmailConfirmation] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { isDirty, errors },
    setValue,
  } = useForm({
    ...(entity && {
      defaultValues: {
        ...entity,
        role: entity.role || utils.getRole(entity.authorities),
      },
    }),
  });
  const phoneNumber = watch("phoneNumber");
  const selectedRole = watch("role");
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const errorHandler = useErrorHandler();
  const submit = (values: FieldValues): void => {
    userApi.createUser(values as User).then(
      (data: FieldValues) => {
        setCreatedUser(data);
        const permissionCreation =
          data.role !== USER_ROLE_LABELS[USER_ROLES.ROLE_ADMIN]
            ? permissionsApi.createPermission({
                userId: data.id,
                permission: "WRITE",
                company: {
                  id: values.company || availableCompanies?.[0]?.id,
                },
              })
            : Promise.resolve();
        permissionCreation
          .then(
            () => {
              addToast({
                id: data.id,
                type: "success",
                message: t("users.userForm.createSuccess"),
              });
            },
            (error) => {
              errorHandler(error);
            }
          )
          .finally(() => {
            if (getUsers) {
              getUsers();
            }
          });
      },
      (error) => errorHandler(error)
    );
  };

  const superAdminSelected = useMemo(
    () => selectedRole === USER_ROLE_LABELS[USER_ROLES.ROLE_ADMIN],
    [selectedRole]
  );
  const availableRoles = useMemo(
    () =>
      allowSuperAdminRole && (entity || isSuperAdminRole)
        ? Object.entries(USER_ROLE_LABELS)
        : Object.entries(USER_ROLE_LABELS).filter(
            ([, value]) => value !== USER_ROLE_LABELS[USER_ROLES.ROLE_ADMIN]
          ),
    [entity, isSuperAdminRole, allowSuperAdminRole]
  );
  const onEdit = (values: FieldValues): void => {
    if (values.email !== entity?.email && !showEditEmailConfirmation) {
      setShowEditEmailConfirmation(true);
      return;
    }
    const roleKey = Object.keys(USER_ROLE_LABELS).find(
      (key) => USER_ROLE_LABELS[key] === values.role
    );
    values.authorities[0] = roleKey;
    userApi
      .updateUer(values)
      .then(
        (data: FieldValues) => {
          addToast({
            id: data.id,
            type: "success",
            message: t("users.userForm.editSuccess"),
          });
        },
        (error) => errorHandler(error)
      )
      .finally(() => {
        if (getUsers) {
          getUsers();
        }
        if (onClose) {
          onClose();
        }
      });
  };
  useEffect(() => {
    if (superAdminSelected) setValue("company", []);
  }, [superAdminSelected, setValue]);
  useEffect((): void => {
    if (entity) {
      userApi.getUserCompanies(entity?.id).then(
        (data: Company[]) => {
          setAvailableCompanies(data);
        },
        (err) => errorHandler(err)
      );
    }
    if (!entity) {
      if (company) {
        setAvailableCompanies([company]);
      } else {
        companyApi.getCompanies().then(
          (data: Array<Company>) => {
            setAvailableCompanies(data);
          },
          (err) => errorHandler(err)
        );
      }
    }
  }, [isSuperAdminRole, errorHandler, user.id, company, entity]);

  return (
    <div className="user-form">
      {!entity && (
        <CreateEntityModal
          show={!!createdUser}
          submitTitle={t("users.userForm.sendInvite")}
          cancelTitle={t("users.userForm.cancel")}
          onCancel={() => {
            setCreatedUser(null);
            if (onClose) {
              onClose();
            }
          }}
          onSubmit={() => {
            if (sendInvite) {
              sendInvite(createdUser?.id);
              setCreatedUser(null);
            }
          }}
          bodyData={t("users.userForm.createModal.body", {
            id: createdUser?.id,
          })}
          title={t("users.userForm.createModal.title")}
        />
      )}
      <form onSubmit={handleSubmit(!entity ? submit : onEdit)}>
        <div className="mb-3">
          <label htmlFor="company" className="form-label">
            {t("users.userForm.company")}
          </label>
          <select
            disabled={readonly || superAdminSelected}
            className="form-select"
            id="company"
            {...register("company", {
              disabled: readonly || superAdminSelected,
            })}
          >
            {availableCompanies.map((item) => (
              <option key={item?.id} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="firstName" className="form-label">
            {t("users.userForm.name")}
          </label>
          <input
            disabled={readonly}
            type="text"
            className="form-control"
            id="firstName"
            {...register("firstName")}
          />
        </div>
        <FormFieldComponent
          readonly={readonly || (!isSuperAdminRole && !!entity)}
          error={errors?.email?.message}
          label={t("users.userForm.email")}
          inputProps={register("email", {
            pattern: {
              value: EMAIL_VALIDATOR,
              message: t("general.form.validation.email"),
            },
            required: {
              value: true,
              message: t("general.form.validation.required"),
            },
          })}
          property="email"
        />
        <FormPhoneComponent
          disabled={readonly}
          error={errors?.phoneNumber?.message}
          value={phoneNumber}
          formProps={register("phoneNumber", {
            pattern: {
              value: PHONE_NUMBER_VALIDATOR,
              message: t("general.form.validation.phone"),
            },
          })}
        />
        <div className="mb-3">
          <label htmlFor="role" className="form-label">
            {t("users.userForm.role")}
          </label>
          <select className="form-select" id="role" {...register("role")}>
            {availableRoles.map(([key, value]) => (
              <option key={key} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
        {!readonly && (
          <div className="mb-3 right gap-5">
            {entity && sendInvite && showSendInviteButton && (
              <button
                onClick={() => sendInvite(entity?.id)}
                className="btn btn-secondary"
              >
                {t(
                  `users.userForm.${
                    entity?.inviteSent ? "reSendInvite" : "sendInvite"
                  }`
                )}
              </button>
            )}
            <button
              disabled={!isDirty}
              className="btn btn-primary"
              type="submit"
            >
              {t(`users.userForm.${!entity ? "submit" : "edit"}`)}
            </button>
          </div>
        )}
        <ConfirmationModalComponent
          title={t("users.userForm.editEmailBody", { id: entity?.id })}
          show={showEditEmailConfirmation}
          onSubmit={handleSubmit(onEdit)}
          onClose={() => {
            setShowEditEmailConfirmation(false);
          }}
          submitTitle={t("general.update")}
        />
      </form>
    </div>
  );
}
