import { TFunction } from "i18next";
import { DetailsByProgram } from "../../../interfaces/interfaces";
import { formatNumber } from "../../../utils";
import { SortTypes } from "../../../interfaces/types";
import { SORT_TYPES } from "../../../consts";

export const getStaticColumns = ({
  t,
}: {
  t: TFunction;
}): {
  header: string;
  render?: (row: DetailsByProgram) => JSX.Element | string;
  property: keyof DetailsByProgram;
  sum?: (row: DetailsByProgram, acc: number) => number;
  format?: (value: string | JSX.Element) => string | JSX.Element;
  freeze?: boolean;
  sort?: boolean;
  sortType?: SortTypes;
  textAlign?: CanvasTextAlign;
}[] => [
  {
    header: t("company.tabs.dashboard.programDetails.name"),
    render: (row: DetailsByProgram) => row.accountName || "",
    property: "accountName",
    freeze: true,
    sort: true,
    sortType: SORT_TYPES.string,
    textAlign: "left",
  },
  {
    header: t("company.tabs.dashboard.programDetails.monthActualExpenses"),
    property: "monthActualExpenses",
    sum: (row: DetailsByProgram, acc: number) => +row.monthActualExpenses + acc,
    format: (value: string | JSX.Element) => formatNumber(+value),
    freeze: true,
    sort: true,
    sortType: SORT_TYPES.number,
  },
  {
    header: t("company.tabs.dashboard.programDetails.monthGrantFunding"),
    property: "monthGrantFunding",
    sum: (row: DetailsByProgram, acc: number) => +row.monthGrantFunding + acc,
    format: (value: string | JSX.Element) => formatNumber(+value),
    freeze: true,
    sort: true,
    sortType: SORT_TYPES.number,
  },
  {
    header: t("company.tabs.dashboard.programDetails.monthlySelfFunded"),
    property: "monthlySelfFunded",
    sum: (row: DetailsByProgram, acc: number) => +row.monthlySelfFunded + acc,
    format: (value: string | JSX.Element) => formatNumber(+value),
    freeze: true,
    sort: true,
    sortType: SORT_TYPES.number,
  },
  {
    header: t("company.tabs.dashboard.programDetails.yearActualExpenses"),
    property: "yearActualExpenses",
    sum: (row: DetailsByProgram, acc: number) => +row.yearActualExpenses + acc,
    format: (value: string | JSX.Element) => formatNumber(+value),
    freeze: true,
    sort: true,
    sortType: SORT_TYPES.number,
  },

  {
    header: t("company.tabs.dashboard.programDetails.yearGrantFunding"),
    property: "yearGrantFunding",
    sum: (row: DetailsByProgram, acc: number) => +row.yearGrantFunding + acc,
    format: (value: string | JSX.Element) => formatNumber(+value),
    freeze: true,
    sort: true,
    sortType: SORT_TYPES.number,
  },
  {
    header: t("company.tabs.dashboard.programDetails.selfFunded"),
    property: "selfFunded",
    sum: (row: DetailsByProgram, acc: number) => +row.selfFunded + acc,
    format: (value: string | JSX.Element) => formatNumber(+value),
    freeze: true,
    sort: true,
    sortType: SORT_TYPES.number,
  },
];
