import React, {
  useMemo,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import { USER_ROLES, USER_ROLE_LABELS } from "../../../consts";
import controlCenterApi from "../../../api/control-center";
import { CompanyContext } from "../../../context/company-context";
import { UserContext } from "../../../context/user.context";
import { useLoadData } from "../../../hooks/useLoadData";
import Card from "../../../components/shared/card/card.component";
import Modal from "../../../components/shared/modal/modal.component";
import Spinner from "../../../components/shared/spinner/spinner.component";
import { formatNumber } from "../../../utils";
import "./accounting-period.component.scss";

export default function AccountingPeriod() {
  const { t } = useTranslation();
  const { company, chosenMonth } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const isReadonly = useMemo(
    () => user.role === USER_ROLE_LABELS[USER_ROLES.ROLE_USER],
    [user.role]
  );
  const [showCloseChecklist, setShowCloseChecklist] = useState<boolean>(false);
  const [isConfirmating, setIsConfirmating] = useState<boolean>(false);
  const [checklistData, isLoading] = useLoadData<Record<string, number>>({
    fetcher: useCallback(
      () =>
        showCloseChecklist
          ? controlCenterApi.getOpenCloseChecklist(company.id, chosenMonth)
          : Promise.resolve({}),
      [company, chosenMonth, showCloseChecklist]
    ),
  });
  const [checklistState, setChecklistState] = useState<boolean[]>(
    Array(3).fill(false)
  );
  useEffect(() => {
    setChecklistState([
      !!checklistData?.numberOfGLAllocations,
      !!checklistData?.numberOfPRAllocations,
      !!checklistData?.numberOfMasterFileCreated,
    ]);
  }, [checklistData]);
  const switchChecklist = useCallback(
    (index: number) => {
      setChecklistState((prevState) => {
        prevState[index] = true;
        return [...prevState];
      });
    },
    [setChecklistState]
  );
  const closeCallback = useCallback(() => {
    if (!isConfirmating) {
      setIsConfirmating(true);
    } else {
      setIsConfirmating(false);
      setShowCloseChecklist(false);
    }
  }, [isConfirmating, setIsConfirmating]);

  const footerContent = useMemo(
    () => (
      <div className="footer d-flex gap-3">
        <button
          onClick={() => setShowCloseChecklist(false)}
          className="btn btn-secondary"
        >
          {t("general.cancel")}
        </button>
        <button
          onClick={closeCallback}
          className="btn btn-primary"
          disabled={isLoading || checklistState.some((e) => !e)}
        >
          {t("company.tabs.control-center.closePeriod.closeLabel")}
        </button>
      </div>
    ),
    [t, setShowCloseChecklist, isLoading, closeCallback, checklistState]
  );

  return (
    <>
      <Modal
        centredContent={true}
        show={showCloseChecklist}
        title={t("company.tabs.control-center.closePeriod.title")}
        {...(isConfirmating && {
          subtitle: t("company.tabs.control-center.closePeriod.subtitle"),
        })}
        Footer={footerContent}
      >
        {isLoading ? (
          <Spinner size="medium" />
        ) : (
          <div className="d-flex flex-column">
            <span className="fw-bold">
              {t("company.tabs.control-center.closePeriod.glTitle")}
            </span>
            <div className="d-flex justify-content-between pe-3 pb-3">
              <span
                className={`${
                  checklistData.numberOfGLAllocations ||
                  checklistData.amountOfGLAllocations
                    ? "fw-normal"
                    : "text-danger"
                }`}
              >
                {t("company.tabs.control-center.closePeriod.glDescription", {
                  number: checklistData.numberOfGLAllocations,
                  amount: formatNumber(checklistData.amountOfGLAllocations),
                })}
              </span>
              <input
                className="form-check-input"
                type="checkbox"
                checked={checklistState[0]}
                disabled={checklistState[0]}
                onChange={() => switchChecklist(0)}
              />
            </div>
            <span className="fw-bold">
              {t("company.tabs.control-center.closePeriod.prTitle")}
            </span>
            <div className="d-flex justify-content-between pe-3 pb-3">
              <span
                className={`${
                  checklistData.numberOfPRAllocations ||
                  checklistData.amountOfPRAllocations
                    ? "fw-normal"
                    : "text-danger"
                }`}
              >
                {t("company.tabs.control-center.closePeriod.prDescription", {
                  number: checklistData.numberOfPRAllocations,
                  amount: formatNumber(checklistData.amountOfPRAllocations),
                })}
              </span>
              <input
                className="form-check-input"
                type="checkbox"
                checked={checklistState[1]}
                disabled={checklistState[1]}
                onChange={() => switchChecklist(1)}
              />
            </div>
            <span className="fw-bold">
              {t("company.tabs.control-center.closePeriod.dmsTitle")}
            </span>
            <div className="d-flex justify-content-between pe-3">
              <span
                className={`${
                  checklistData.numberOfMasterFileCreated ||
                  checklistData.numberOfPrograms
                    ? "fw-normal"
                    : "text-danger"
                }`}
              >
                {t("company.tabs.control-center.closePeriod.dmsDescription", {
                  invoices: checklistData.numberOfMasterFileCreated,
                  programs: checklistData.numberOfPrograms,
                })}
              </span>
              <input
                className="form-check-input"
                type="checkbox"
                checked={checklistState[2]}
                disabled={checklistState[2]}
                onChange={() => switchChecklist(2)}
              />
            </div>
          </div>
        )}
      </Modal>
      <Card header={t("company.tabs.companyForm.controlsTitle")}>
        <div className="period-controls gap-3">
          <div className="control gap-3">
            <button
              disabled={isReadonly}
              onClick={() => setShowCloseChecklist(true)}
              className="btn btn-primary"
            >
              {t("company.tabs.companyForm.closePeriod")}
            </button>
            <ReactDatePicker
              readOnly
              className="form-control"
              dateFormat="MM.dd.yyyy"
              placeholderText="mm.dd.yyyy"
              onChange={() => {}}
            />
          </div>
          <div className="control gap-3">
            <button disabled className="btn btn-primary">
              {t("company.tabs.companyForm.unlockPeriod")}
            </button>
            <ReactDatePicker
              readOnly
              className="form-control"
              dateFormat="MM.dd.yyyy"
              placeholderText="mm.dd.yyyy"
              onChange={() => {}}
            />
          </div>
        </div>
      </Card>
    </>
  );
}
