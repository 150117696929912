import Modal from "../../../modal/modal.component";
import { ChangeHandlerProps, Column, SelectOption } from "../interfaces";
import React from "react";
import "./error-modal.component.scss";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import { OnChangeValue } from "react-select";

interface ErrorModalProps {
  showModal: boolean;
  title?: string;
  subTitle?: string;
  columns: Array<Column>;
  data: Array<Record<string, string>>;
  footerTitle?: string;
  toastMessage?: string;
  submitHandler: () => void;
  cancelHandler: () => void;
  disabled?: boolean;
  changeHandler: (data: ChangeHandlerProps) => void;
}

interface RenderCellProps extends Column {
  value: string;
  id: string;
  changeHandler: (data: ChangeHandlerProps) => void;
}

const renderCell = ({
  type,
  changeHandler,
  options,
  value,
  id,
  header,
  property,
}: RenderCellProps): JSX.Element | undefined => {
  switch (type) {
    case "input":
      return (
        <input
          key={header}
          defaultValue={value}
          className="form-control"
          type="text"
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
            changeHandler?.({ value: target.value, id, property })
          }
        />
      );
    case "select":
      return (
        <>
          <input
            defaultValue={value}
            type="text"
            className="form-control"
            list={`${header}-options`}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
              changeHandler?.({ value: target.value, id, property })
            }
          />
          <datalist id={`${header}-options`}>
            {(options || []).map(({ value: currentValue, label }) => (
              <option key={label} value={currentValue} label={label} />
            ))}
          </datalist>
        </>
      );
    case "selectWithCreate":
      return (
        <CreatableSelect
          className="custom-select"
          isClearable
          onChange={(newValue: OnChangeValue<SelectOption, false>) =>
            changeHandler?.({ value: newValue?.value || "", id, property })
          }
          options={options}
        />
      );
    default:
      return undefined;
  }
};

export default function ErrorToFixModalComponent({
  showModal,
  title,
  columns,
  data,
  subTitle,
  footerTitle,
  toastMessage,
  submitHandler,
  cancelHandler,
  disabled,
  changeHandler,
}: ErrorModalProps) {
  const { t } = useTranslation();
  return (
    <Modal
      className="error-to-fix-modal"
      show={showModal}
      title={t(title || "")}
      subtitle={toastMessage}
      size="large"
    >
      <div className="content">
        <span className="notification">
          {t(`${subTitle}${data.length === 1 ? "" : "_plural"}`, {
            rows: data.length,
          })}
        </span>
        <table className="table table-hover">
          <thead>
            <tr>
              {columns.map(({ header }) => (
                <td key={`header-${header}`}>{t(header)}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                {columns.map((column) => (
                  <td key={column.header}>
                    {renderCell({
                      ...column,
                      id: item.id,
                      value: item[column.property],
                      changeHandler,
                    }) || item[column.property]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="footer">
          <span>{t(footerTitle || "")}</span>
          <div className="buttons gap-3">
            <button onClick={cancelHandler} className="btn btn-secondary">
              {t("general.cancel")}
            </button>
            <button
              disabled={disabled}
              onClick={submitHandler}
              className="btn btn-primary"
            >
              {t("general.add")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
