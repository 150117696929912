import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ROUTE_PATHS, USER_ROLES, USER_ROLE_LABELS } from "../../../consts";
import { Program } from "../../../interfaces/interfaces";
import fileApi from "../../../api/file-api";
import { CompanyContext } from "../../../context/company-context";
import { UserContext } from "../../../context/user.context";
import { DownloadButtonComponent } from "../../../components/shared/download-button/download-button.component";
import WarningButtonComponent from "../../../components/shared/warning-button/warning-button.component";
import "./action-header.component.scss";
import UploadComponent from "../../../components/shared/upload/upload-validation-component/upload-validation.component";
import { CONFIG, ERRORS } from "./helpers";
import glAccountsApi from "../../../api/gl-account-api";

interface ActionHeaderProps {
  showPreselection: boolean;
  purgePrograms: Program[];
  allocationPrograms: Program[];
  runAllocation: () => Promise<void>;
  runPurge: () => Promise<void>;
  loadGlAccounts: () => void;
  allocationProgram: string;
  setAllocationProgram: (code: string) => void;
  purgeProgram: string;
  setPurgeProgram: (code: string) => void;
}

export default function ActionHeaderComponent({
  showPreselection,
  purgePrograms,
  allocationPrograms,
  runAllocation,
  runPurge,
  allocationProgram,
  setAllocationProgram,
  purgeProgram,
  setPurgeProgram,
  loadGlAccounts,
}: ActionHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const { company, chosenMonth } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const isReadonly = useMemo(
    () => user.role === USER_ROLE_LABELS[USER_ROLES.ROLE_USER],
    [user.role]
  );
  const activePeriod = useMemo(
    () => chosenMonth.split("/")?.[0],
    [chosenMonth]
  );
  const allocationWarningMessage = useMemo(
    () =>
      allocationProgram === "all"
        ? t("shared.allocation.warningAll")
        : t("shared.allocation.warningCode", {
            code: allocationPrograms.find(
              (program) => program.id.toString() === allocationProgram
            )?.code,
          }),
    [t, allocationProgram, allocationPrograms]
  );
  const purgeWarningMessage = useMemo(
    () =>
      purgeProgram === "all"
        ? t("shared.purge.warningAll")
        : t("shared.purge.warningCode", {
            code: purgePrograms.find(
              (program) => program.id.toString() === purgeProgram
            )?.code,
          }),
    [t, purgePrograms, purgeProgram]
  );

  return (
    <div className="header-actions">
      <div className="actions gap-5">
        <div className="d-flex flex-column gap-1">
          <UploadComponent
            disabled={isReadonly}
            postUploadEvent={loadGlAccounts}
            uploadHandler={glAccountsApi.uploadAccounts}
            validateHandler={glAccountsApi.validateGlFile}
            title={t("company.tabs.gl.uploadGl")}
            errors={ERRORS}
            config={CONFIG}
            showPreselection={showPreselection}
          />
        </div>
        <div>
          <label htmlFor="allocate">Allocate Direct Expenses</label>
          <div className="select-wrapper gap-3">
            <select
              disabled={isReadonly}
              onChange={(e) => setAllocationProgram(e.target.value)}
              className="form-select"
              id="allocate"
              defaultValue={allocationProgram}
            >
              <option value="all">All</option>
              {allocationPrograms.map(({ id, code, name }) => (
                <option key={id} value={id}>
                  {code} - {name}
                </option>
              ))}
            </select>
            <WarningButtonComponent
              disabled={isReadonly}
              title={t("shared.allocation.label")}
              warning={allocationWarningMessage}
              callback={runAllocation}
            />
          </div>
        </div>
        <div>
          <label htmlFor="purge">{t("company.tabs.gl.purgeLabel")}</label>
          <div className="select-wrapper gap-3">
            <select
              disabled={isReadonly}
              onChange={(e) => setPurgeProgram(e.target.value)}
              className="form-select"
              id="purge"
              defaultValue={purgeProgram}
            >
              <option value="all">All</option>
              {purgePrograms.map(({ id, code, name }) => (
                <option key={id} value={id}>
                  {code} - {name}
                </option>
              ))}
            </select>
            <WarningButtonComponent
              disabled={isReadonly}
              title={t("company.tabs.gl.purge")}
              warning={purgeWarningMessage}
              callback={runPurge}
            />
          </div>
        </div>
        <DownloadButtonComponent
          fileName="gl-transactions-export.xlsx"
          label={t("company.tabs.gl.downloadTitle")}
          downloadHandler={() =>
            fileApi.downloadFile(
              `${ROUTE_PATHS.company}/${
                company.id
              }/grant-transanctions/journal-entries${
                activePeriod ? `?activePeriod=${activePeriod}` : ""
              }`
            )
          }
        />
        <DownloadButtonComponent
          fileName="gl-transactions-export.xlsx"
          label={t("general.export")}
          downloadHandler={() =>
            fileApi.downloadFile(
              `${ROUTE_PATHS.company}/${
                company.id
              }/grant-transanctions-export?status.notEquals=BUDGED_MODIFIED${
                activePeriod ? `&activePeriod=${activePeriod}` : ""
              }`
            )
          }
        />
      </div>
    </div>
  );
}
