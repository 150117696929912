import React, { useCallback, useContext, useMemo, useState } from "react";
import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BILLING_ALLOCATION_METHODS,
  TOAST_TYPES,
  USER_ROLES,
  USER_ROLE_LABELS,
  GL_ALLOCATION_STATUS,
} from "../../consts";
import {
  GlTransaction,
  GlUpdateTransaction,
  Program,
} from "../../interfaces/interfaces";
import glAccountsApi from "../../api/gl-account-api";
import programsApi from "../../api/programs-api";
import { CompanyContext } from "../../context/company-context";
import { UserContext } from "../../context/user.context";
import { ToastContext } from "../../context/toast.context";
import { useLoadData } from "../../hooks/useLoadData";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import ActionHeaderComponent from "./action-header/action-header.component";
import GLFormComponent from "../../components/forms/gl/gl-form.component";
import Table from "../../components/shared/table/table.component";
import Modal from "../../components/shared/modal/modal.component";
import Spinner from "../../components/shared/spinner/spinner.component";
import "./gl-account.component.scss";
import { DownloadButtonComponent } from "../../components/shared/download-button/download-button.component";
import fileApi from "../../api/file-api";
import UploadComponent from "../../components/shared/upload/upload-validation-component/upload-validation.component";
import { CONFIG, ERRORS } from "./action-header/helpers";
import { getColumns } from "./helpers";

export default function GlAccountListComponent(): JSX.Element {
  const { t } = useTranslation();
  const errorHandler = useErrorHandler();
  const { addToast } = useContext(ToastContext);
  const { company, chosenMonth } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const isUserAdmin = [
    USER_ROLE_LABELS[USER_ROLES.ROLE_ADMIN],
    USER_ROLE_LABELS[USER_ROLES.ROLE_COMPANY_ADMIN],
  ].includes(user.role);
  const activePeriod = useMemo(
    () => chosenMonth.split("/")?.[0],
    [chosenMonth]
  );
  const [glAccounts, areGLsLoading, refetchAccounts] = useLoadData<
    GlTransaction[]
  >({
    fetcher: useCallback(
      () => glAccountsApi.getAccounts(company.id, activePeriod),
      [activePeriod, company.id]
    ),
    transformer: useCallback(
      (data: GlTransaction[]) =>
        data.filter(({ status }) => status !== "BUDGED_MODIFIED"),
      []
    ),
  });
  const [
    allocationPrograms,
    areAllocationProgramsLoading,
    loadAllocationPrograms,
  ] = useLoadData<Program[]>({
    fetcher: useCallback(
      () =>
        isUserAdmin
          ? programsApi.getGLAllocationPrograms(company.id, activePeriod)
          : Promise.resolve([]),
      [activePeriod, company.id, isUserAdmin]
    ),
  });
  const [purgePrograms, arePurgeProgramsLoading, loadPurgePrograms] =
    useLoadData<Program[]>({
      fetcher: useCallback(
        () =>
          isUserAdmin
            ? programsApi.getGLPurgePrograms(company.id, activePeriod)
            : Promise.resolve([]),
        [activePeriod, company.id, isUserAdmin]
      ),
    });
  const [allGlCount, , refetchAllGlCounts] = useLoadData<number>({
    fetcher: useCallback(
      () => glAccountsApi.getAccountsCount(company.id),
      [company]
    ),
  });
  const [showUploadedResultModal, setShowUploadedResultModal] =
    useState<boolean>(false);
  const [glCount, setGlCount] = useState<string>("");
  const [glAmount, setGlAmount] = useState<string>("");
  const [selectedAllocationProgram, setSelectedAllocationProgram] =
    useState<string>("all");
  const [selectedPurgeProgram, setSelectedPurgeProgram] =
    useState<string>("all");
  const [editedGLID, setEditedGLID] = useState<string | undefined>(undefined);
  const [editedGL, isEditedGLLoading] = useLoadData<GlUpdateTransaction>({
    fetcher: useCallback(() => {
      if (editedGLID) {
        return glAccountsApi.getUpdateTransaction(company.id, editedGLID);
      }
      return new Promise(() => undefined);
    }, [editedGLID, company.id]),
  });
  const [editConfirmation, setEditConfirmation] = useState<boolean>(false);

  const runAllocation = useCallback(() => {
    const allocationProgramId =
      selectedAllocationProgram === "all"
        ? selectedAllocationProgram
        : +selectedAllocationProgram;
    const data = {
      programId: allocationProgramId,
      companyId: company.id,
      type: BILLING_ALLOCATION_METHODS.SAM,
      activePeriod,
    };
    return glAccountsApi.allocateGLTransactions(company.id, data).then(
      (res) => {
        if (res.status == "ERROR") {
          const messages = res.errors?.map(({ message }) => message).join(";");
          errorHandler(undefined, messages);
        } else {
          addToast({
            type: TOAST_TYPES.success,
            message: t("company.tabs.gl.allocateSuccess"),
          });
        }
        loadAllocationPrograms();
        loadPurgePrograms();
        refetchAllGlCounts();
        refetchAccounts();
      },
      (err) => errorHandler(err)
    );
  }, [
    selectedAllocationProgram,
    company.id,
    activePeriod,
    refetchAllGlCounts,
    refetchAccounts,
    loadAllocationPrograms,
    loadPurgePrograms,
    errorHandler,
    addToast,
    t,
  ]);

  const purgeProgramCode = useMemo(
    () =>
      purgePrograms.find(
        (program) => program.id.toString() === selectedPurgeProgram
      )?.code,
    [purgePrograms, selectedPurgeProgram]
  );

  const runPurge = useCallback(() => {
    const programId =
      selectedPurgeProgram === "all" ? "" : selectedPurgeProgram;
    return glAccountsApi
      .purgeGlTransactions(company.id, programId, activePeriod)
      .then(
        (res) => {
          if (res.errors?.length) {
            errorHandler(undefined, res.errors?.[0]?.message);
          } else {
            const toastMessage =
              selectedPurgeProgram === "all"
                ? t("company.tabs.gl.purgeAllSuccess")
                : t("company.tabs.gl.purgeProgramSuccess", {
                    purgeProgram: purgeProgramCode,
                  });
            addToast({
              type: TOAST_TYPES.success,
              message: toastMessage,
            });
            loadAllocationPrograms();
            loadPurgePrograms();
            refetchAccounts();
            refetchAllGlCounts();
          }
        },
        (err) => errorHandler(err)
      );
  }, [
    selectedPurgeProgram,
    purgeProgramCode,
    company.id,
    activePeriod,
    errorHandler,
    t,
    addToast,
    refetchAllGlCounts,
    refetchAccounts,
    loadAllocationPrograms,
    loadPurgePrograms,
  ]);

  const editHandler = useCallback(
    (
      values: FieldValues,
      saveCallback: (values: FieldValues) => Promise<any>
    ) => {
      if (!editConfirmation) {
        setEditConfirmation(true);
        return;
      }
      saveCallback(values).then(
        (res) => {
          if (res.status == "ERROR") {
            const messages = res.errors
              ?.map(({ message }: { message: string }) => message)
              .join(";");
            errorHandler(undefined, messages);
          } else {
            addToast({
              type: TOAST_TYPES.success,
              message: t("company.tabs.gl.editSuccess"),
            });
            setEditConfirmation(false);
            setEditedGLID(undefined);
            loadAllocationPrograms();
            loadPurgePrograms();
            refetchAccounts();
          }
        },
        (err) => errorHandler(err)
      );
    },
    [
      t,
      addToast,
      errorHandler,
      setEditedGLID,
      editConfirmation,
      setEditConfirmation,
      loadAllocationPrograms,
      loadPurgePrograms,
      refetchAccounts,
    ]
  );

  const postUploadEvent = useCallback(() => {
    refetchAllGlCounts();
    loadAllocationPrograms();
    loadPurgePrograms();
    refetchAccounts();
  }, [
    loadAllocationPrograms,
    loadPurgePrograms,
    refetchAccounts,
    refetchAllGlCounts,
  ]);

  const isLoading = useMemo(
    () =>
      areGLsLoading || arePurgeProgramsLoading || areAllocationProgramsLoading,
    [areGLsLoading, arePurgeProgramsLoading, areAllocationProgramsLoading]
  );

  const COLUMNS = useMemo(
    () =>
      getColumns({
        t,
        isUserAdmin,
        setEditedGLID,
        companyId: company.id,
      }),
    [company.id, isUserAdmin, t]
  );

  const glEditTitle = useMemo(() => {
    if (isEditedGLLoading) {
      return t("company.tabs.gl.form.titleLoading");
    }
    return t(
      `company.tabs.gl.form.${
        editedGL?.status === GL_ALLOCATION_STATUS.ALLOCATED_TARGET
          ? "allocated"
          : "unallocated"
      }.title`
    );
  }, [t, editedGL, isEditedGLLoading]);
  const render = useCallback(
    () =>
      allGlCount > 0 ? (
        <>
          <Modal
            className="upload-gl-modal"
            onClose={() => {
              setShowUploadedResultModal(false);
              setGlCount("");
              setGlAmount("");
            }}
            show={showUploadedResultModal}
            title={t("general.uploadResults")}
            Footer={[
              <button
                onClick={() => {
                  setShowUploadedResultModal(false);
                  setGlCount("");
                  setGlAmount("");
                }}
                className="btn btn-primary"
              >
                {t("general.ok")}
              </button>,
            ]}
          >
            <div>
              {t(
                +glCount === 1
                  ? "company.tabs.gl.uploadModalInfo"
                  : "company.tabs.gl.uploadModalInfo_plural",
                { glCount, glAmount }
              )}
            </div>
          </Modal>
          <Modal
            size="large"
            centredContent={true}
            show={!!editedGLID}
            title={glEditTitle}
            {...(editConfirmation && {
              subtitle: t(
                `company.tabs.gl.form.${
                  editedGL?.status === GL_ALLOCATION_STATUS.ALLOCATED_TARGET
                    ? "allocated"
                    : "unallocated"
                }.subtitle`
              ),
            })}
          >
            {isEditedGLLoading ? (
              <Spinner size="medium" />
            ) : (
              <GLFormComponent
                glTransaction={editedGL}
                isConfirmating={editConfirmation}
                onSubmit={editHandler}
                onClose={() => {
                  setEditedGLID(undefined);
                  setEditConfirmation(false);
                }}
              />
            )}
          </Modal>
          <ActionHeaderComponent
            showPreselection={!!glAccounts.length}
            key={glAccounts.length}
            purgePrograms={purgePrograms}
            allocationPrograms={allocationPrograms}
            runAllocation={runAllocation}
            runPurge={runPurge}
            loadGlAccounts={postUploadEvent}
            allocationProgram={selectedAllocationProgram}
            setAllocationProgram={setSelectedAllocationProgram}
            purgeProgram={selectedPurgeProgram}
            setPurgeProgram={setSelectedPurgeProgram}
          />
          {!glAccounts.length ? (
            <div className="empty-table flex-fill justify-content-center align-items-center">
              {t("general.filteredDataMessage")}
            </div>
          ) : (
            <Table columns={COLUMNS} data={glAccounts} search={true} />
          )}
        </>
      ) : (
        <section className="empty-page gap-3">
          <span>{t("company.tabs.gl.addList")}</span>
          <div className="buttons gap-3">
            <DownloadButtonComponent
              label={t("company.tabs.gl.getTemplate")}
              downloadHandler={() =>
                fileApi.downloadFile("templates?templateName=GL_TRANSACTION")
              }
              primary={false}
            />
            <UploadComponent
              disabled={!isUserAdmin}
              postUploadEvent={postUploadEvent}
              uploadHandler={glAccountsApi.uploadAccounts}
              validateHandler={glAccountsApi.validateGlFile}
              title={t("company.tabs.gl.uploadGl")}
              errors={ERRORS}
              config={CONFIG}
              showPreselection={!!glAccounts.length}
            />
          </div>
        </section>
      ),
    [
      allGlCount,
      showUploadedResultModal,
      t,
      glCount,
      glAmount,
      editedGLID,
      glEditTitle,
      editConfirmation,
      editedGL,
      isEditedGLLoading,
      editHandler,
      glAccounts,
      purgePrograms,
      allocationPrograms,
      runAllocation,
      runPurge,
      selectedAllocationProgram,
      selectedPurgeProgram,
      COLUMNS,
      postUploadEvent,
      isUserAdmin,
    ]
  );

  return (
    <div className="gl-accounts">
      {isLoading ? <Spinner size="medium" /> : render()}
    </div>
  );
}
