import { AxiosResponse } from "axios";
import { httpClient } from "../core/http-client/http-client";
import {
  Grant,
  GrantFinData,
  Transaction,
  UploadResponse,
} from "../interfaces/interfaces";
import { GRANT_STATUSES } from "../consts";
import { FieldValues } from "react-hook-form";

const fromDto = (grant: Grant, now: number): Grant => {
  const startDate = new Date(grant.grantBudgetStart).getTime();
  const endDate = new Date(grant.grantBudgetEnd).getTime();
  return {
    ...grant,
    isActive:
      now > startDate && now < endDate
        ? GRANT_STATUSES.active
        : GRANT_STATUSES.inactive,
  };
};

async function getGrants(
  companyId: string | undefined,
  query?: string
): Promise<Array<Grant>> {
  const now = new Date().getTime();
  const { data } = await httpClient.get(`company/${companyId}/grants/count`);
  return httpClient
    .get(`company/${companyId}/grants${query ? `?${query}&` : "?"}size=${data}`)
    .then(
      (response: AxiosResponse): Array<Grant> =>
        response.data.map((item: Grant) => fromDto(item, now))
    );
}

async function createGrant(
  companyId: string | undefined,
  grant: FieldValues
): Promise<Grant> {
  const now = new Date().getTime();
  return httpClient
    .post(`company/${companyId}/grants`, {
      ...grant,
      company: { id: companyId },
      activated: true,
    })
    .then((response: AxiosResponse): Grant => fromDto(response.data, now));
}

async function updateGrant(
  companyId: string | undefined,
  grant: FieldValues
): Promise<Grant> {
  const now = new Date().getTime();
  return httpClient
    .put(`company/${companyId}/grants/${grant.id}`, grant)
    .then((response: AxiosResponse): Grant => fromDto(response.data, now));
}

async function getGrant(
  id: string | undefined,
  companyId: string | undefined
): Promise<Grant> {
  const now = new Date().getTime();
  return httpClient
    .get(`company/${companyId}/grants/${id}`)
    .then((response: AxiosResponse): Grant => fromDto(response.data, now));
}

async function addNewLineToGrant(
  data: FieldValues,
  companyId: string,
  grantcode: string
): Promise<{ errors?: Record<string, string>[]; status: string }> {
  return httpClient
    .post(`company/${companyId}/grant-budgets/${grantcode}`, data)
    .then(
      (
        response: AxiosResponse
      ): { errors?: Record<string, string>[]; status: string } => response.data
    );
}

async function uploadGrants(
  data: FormData,
  companyId: string | undefined
): Promise<UploadResponse> {
  return httpClient
    .post(`company/${companyId}/grants/import`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: AxiosResponse): UploadResponse => response.data);
}

async function uploadFinData(
  data: FormData,
  companyId: string | undefined
): Promise<UploadResponse> {
  return httpClient
    .post(`company/${companyId}/grant-budgets/import`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: AxiosResponse): UploadResponse => response.data);
}

async function getFinData(
  companyId: string | undefined,
  grantId?: string | undefined
): Promise<Array<GrantFinData>> {
  const { data } = await httpClient.get(
    `company/${companyId}/grant-budgets/count`
  );
  return httpClient
    .get(
      `company/${companyId}/grant-budgets?grantId.equals=${grantId}&size=${data}`
    )
    .then((response: AxiosResponse): Array<GrantFinData> => response.data);
}

async function modifyGrantBudget(
  companyId: string | undefined,
  payload: {
    modifications: { value: string | number; grantBudgetId: string }[];
  }
): Promise<{ status: string; errors: { message: string }[] }> {
  return httpClient
    .post(`company/${companyId}/grant-budgets/modify`, payload)
    .then(
      (
        response: AxiosResponse
      ): { status: string; errors: { message: string }[] } => response.data
    );
}

async function getTransactions(
  companyId: string | undefined,
  grantId: string | undefined
): Promise<Array<Transaction>> {
  return httpClient
    .get(
      `company/${companyId}/grant-transanctions?grantId.equals=${grantId}&status.in=BUDGED_MODIFIED&page=0&size=20&sort=transactionDate,DESC`
    )
    .then((response: AxiosResponse): Array<Transaction> => response.data);
}

const grantApi = {
  getGrants,
  uploadGrants,
  getGrant,
  addNewLineToGrant,
  createGrant,
  uploadFinData,
  getFinData,
  updateGrant,
  modifyGrantBudget,
  getTransactions,
};

export default grantApi;
