import fileApi from "../../../../api/file-api";
import { DownloadButtonComponent } from "../../../shared/download-button/download-button.component";
import React from "react";
import { useTranslation } from "react-i18next";
import { templates } from "./helpers";
import Modal from "../../../shared/modal/modal.component";
import "./template-modal.component.scss";

interface TemplateModalProps {
  show: boolean;
  onClose: () => void;
}

export default function TemplatesModalComponent({
  show,
  onClose,
}: TemplateModalProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Modal
      centredContent
      className="template-modal"
      onClose={onClose}
      show={show}
      title={t("shared.navbar.userMenu.modalTitle")}
    >
      <ul>
        {templates.map(({ url, label }) => (
          <ol key={url}>
            <DownloadButtonComponent
              asLink
              label={t(label)}
              downloadHandler={() => fileApi.downloadFile(url)}
              primary={false}
            />
          </ol>
        ))}
      </ul>
    </Modal>
  );
}
