import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useForm, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  USER_ROLE_LABELS,
  USER_ROLES,
  GRANT_NEW_LINE_TYPES,
  NUMBER_VALIDATOR,
} from "../../../consts";
import { Grant } from "../../../interfaces/interfaces";
import { GrantNewLineType } from "../../../interfaces/types";
import jobFunctionsApi from "../../../api/job-functions-api";
import mcoaApi from "../../../api/mcoa-api";
import { useLoadData } from "../../../hooks/useLoadData";
import { CompanyContext } from "../../../context/company-context";
import { UserContext } from "../../../context/user.context";
import FormSelectComponent from "../form-fields/form-select.component";
import FormInputComponent from "../form-fields/form-input.component";
import Spinner from "../../shared/spinner/spinner.component";

interface NewLineFormProps {
  type: GrantNewLineType;
  grant: Grant;
  isConfirmating: boolean;
  onCancel: () => void;
  onSubmit: (data: FieldValues) => void;
}

export default function NewLineForm({
  type,
  grant,
  isConfirmating,
  onCancel,
  onSubmit,
}: NewLineFormProps) {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const isUserAdmin = [
    USER_ROLE_LABELS[USER_ROLES.ROLE_ADMIN],
    USER_ROLE_LABELS[USER_ROLES.ROLE_COMPANY_ADMIN],
  ].includes(user.role);
  const [loadedData, isLoading] = useLoadData<
    Record<string, string> | Record<string, string>[]
  >({
    fetcher: useCallback(
      () =>
        type === GRANT_NEW_LINE_TYPES.COMPENSATION
          ? jobFunctionsApi.getJobFunctionsByGrant(company.id, grant.code)
          : mcoaApi.getMCOAsByGrant(company.id, grant.code),
      [company.id, grant.code, type]
    ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const modifiedBudget = watch("modifiedAnnualBudget");
  const actualBilled = watch("actualBilledAmount");

  useEffect(() => {
    if (isNaN(modifiedBudget) || isNaN(actualBilled)) {
      setValue("RAB", "Error");
      return;
    }
    setValue("RAB", modifiedBudget - actualBilled);
  }, [modifiedBudget, actualBilled, setValue]);

  const dropdownSelections = useMemo(() => {
    if (type === GRANT_NEW_LINE_TYPES.COMPENSATION) {
      return Object.entries(loadedData).map(([jobAcronym, jobDescription]) => ({
        value: jobAcronym,
        label: `${jobAcronym} - ${jobDescription}`,
      }));
    }
    return Object.entries(loadedData).map((el) => {
      const [key, value] = el;
      return {
        value: key,
        label: `${key} - ${value}`,
      };
    });
  }, [type, loadedData]);

  return (
    <>
      {isLoading ? (
        <Spinner size="medium" />
      ) : (
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <div className="gap-4 row">
            <div className="col">
              <div className="mb-3">
                <FormSelectComponent
                  readonly={!isUserAdmin || isConfirmating}
                  label={
                    type === GRANT_NEW_LINE_TYPES.COMPENSATION
                      ? t(
                          "company.tabs.grants.finData.newLine.list.jobFunction"
                        )
                      : t("company.tabs.grants.finData.newLine.list.mcoa")
                  }
                  inputProps={
                    type === GRANT_NEW_LINE_TYPES.COMPENSATION
                      ? register("jobFunctionAcronym", {
                          required: {
                            value: true,
                            message: t("general.form.validation.required"),
                          },
                        })
                      : register("accountCode", {
                          required: {
                            value: true,
                            message: t("general.form.validation.required"),
                          },
                        })
                  }
                  property={
                    type === GRANT_NEW_LINE_TYPES.COMPENSATION
                      ? "jobFunctionAcronym"
                      : "accountCode"
                  }
                  options={dropdownSelections}
                  error={
                    type === GRANT_NEW_LINE_TYPES.COMPENSATION
                      ? errors?.jobFunctionAcronym?.message
                      : errors?.accountCode?.message
                  }
                />
              </div>
              <div className="mb-3">
                <FormInputComponent
                  prefix="$"
                  readonly={!isUserAdmin || isConfirmating}
                  label={t(
                    "company.tabs.grants.finData.newLine.list.originalAward"
                  )}
                  inputProps={register("originalGrantAward", {
                    required: {
                      value: true,
                      message: t("general.form.validation.required"),
                    },
                    pattern: {
                      value: NUMBER_VALIDATOR,
                      message: t("general.form.validation.number"),
                    },
                  })}
                  property={"originalGrantAward"}
                  error={errors?.originalGrantAward?.message}
                />
              </div>
              <div className="mb-3">
                <FormInputComponent
                  prefix="$"
                  readonly={!isUserAdmin || isConfirmating}
                  label={t(
                    "company.tabs.grants.finData.newLine.list.annualAward"
                  )}
                  inputProps={register("annualGrantAward", {
                    required: {
                      value: true,
                      message: t("general.form.validation.required"),
                    },
                    pattern: {
                      value: NUMBER_VALIDATOR,
                      message: t("general.form.validation.number"),
                    },
                  })}
                  property={"annualGrantAward"}
                  error={errors?.annualGrantAward?.message}
                />
              </div>
              <div className="mb-3">
                <FormInputComponent
                  prefix="$"
                  readonly={!isUserAdmin || isConfirmating}
                  label={t(
                    "company.tabs.grants.finData.newLine.list.modifiedBudget"
                  )}
                  inputProps={register("modifiedAnnualBudget", {
                    required: {
                      value: true,
                      message: t("general.form.validation.required"),
                    },
                    pattern: {
                      value: NUMBER_VALIDATOR,
                      message: t("general.form.validation.number"),
                    },
                  })}
                  property={"modifiedAnnualBudget"}
                  error={errors?.modifiedAnnualBudget?.message}
                />
              </div>
              <div className="mb-3">
                <FormInputComponent
                  prefix="$"
                  readonly={!isUserAdmin || isConfirmating}
                  label={t(
                    "company.tabs.grants.finData.newLine.list.actualBilled"
                  )}
                  inputProps={register("actualBilledAmount", {
                    required: {
                      value: true,
                      message: t("general.form.validation.required"),
                    },
                    pattern: {
                      value: NUMBER_VALIDATOR,
                      message: t("general.form.validation.number"),
                    },
                  })}
                  property={"actualBilledAmount"}
                  error={errors?.actualBilledAmount?.message}
                />
              </div>
              <div className="mb-3">
                <FormInputComponent
                  prefix="$"
                  readonly={true}
                  label={t("company.tabs.grants.finData.newLine.list.RAB")}
                  inputProps={register("RAB", {
                    disabled: true,
                  })}
                  property={"RAB"}
                />
              </div>
              <div className="d-flex gap-3 justify-content-center mt-0">
                <button onClick={onCancel} className="btn btn-secondary">
                  {t("general.cancel")}
                </button>
                <button type="submit" className="btn btn-primary">
                  {isConfirmating ? t("general.confirm") : t("general.save")}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
