export const templates = [
  {
    url: "templates?templateName=MCOA",
    label: "company.tabs.mcoa.list.import",
  },
  {
    url: "templates?templateName=PROGRAMS",
    label: "programs.upload.getTemplate",
  },
  {
    url: "templates?templateName=JOB_FUNCTION",
    label: "company.tabs.jobFunctions.list.import",
  },
  {
    url: "templates?templateName=GRANTS",
    label: "company.tabs.grants.getGeneralTemplate",
  },
  {
    url: "templates?templateName=GRANTS_BUDGET",
    label: "company.tabs.grants.getFinancialTemplate",
  },
  {
    url: "templates?templateName=GL_TRANSACTION",
    label: "company.tabs.gl.getTemplate",
  },
  {
    url: "templates?templateName=PAYROLL_REGISTER",
    label: "company.tabs.payrollRegister.getTemplate",
  },
];
