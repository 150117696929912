import React, { useCallback, useContext, useMemo } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { USER_ROLES, USER_ROLE_LABELS } from "../../../consts";
import { ControlCenterJobFunction } from "../../../interfaces/interfaces";
import controlCenterApi from "../../../api/control-center";
import { CompanyContext } from "../../../context/company-context";
import { UserContext } from "../../../context/user.context";
import { useLoadData } from "../../../hooks/useLoadData";
import FormInputComponent from "../form-fields/form-input.component";
import FormControlledSelect from "../form-fields/form-controlled-select.component";
import "./company-setup.component.scss";

interface CompanySetupProp {
  submit: (data: Record<string, string>) => void;
}

export default function CompanySetupForm({ submit }: CompanySetupProp) {
  const { company } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const defaultValues = useMemo(
    () => ({
      offsetGrantCode: company.offsetGrantCode,
      fringeBenefitsRatio: company.fringeBenefitsRatio,
      payrollAllocationAccount: company.payrollAllocationAccount,
      fringeBenefitsAllocationAccount: company.fringeBenefitsAllocationAccount,
      fringeBenefitsJobFunction: company.fringeBenefitsJobFunction,
      indirectCostAllocationAccount: company.indirectCostAllocationAccount,
    }),
    [company]
  );
  const isReadonly = useMemo(
    () => user.role === USER_ROLE_LABELS[USER_ROLES.ROLE_USER],
    [user.role]
  );
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues,
  });
  const { t } = useTranslation();
  const [systemAllocationMCOAs] = useLoadData<Record<string, string>>({
    fetcher: useCallback(
      () => controlCenterApi.getSystemAllocationMCOAs(company.id),
      [company]
    ),
  });
  const [programExpenseMCOAs] = useLoadData<Record<string, string>>({
    fetcher: useCallback(
      () => controlCenterApi.getProgramExpenseMCOAs(company.id),
      [company]
    ),
  });
  const [jobFunctions] = useLoadData<ControlCenterJobFunction[]>({
    fetcher: useCallback(
      () => controlCenterApi.getJobFunctionsAcronyms(company.id),
      [company]
    ),
  });
  const systemAllocationMCOAList = useMemo(
    () =>
      Object.entries(systemAllocationMCOAs).map(([code, name]) => ({
        label: `${code} - ${name}`,
        value: code,
      })),
    [systemAllocationMCOAs]
  );
  const programExpenseMCOAList = useMemo(
    () =>
      Object.entries(programExpenseMCOAs).map(([code, name]) => ({
        label: `${code} - ${name}`,
        value: code,
      })),
    [programExpenseMCOAs]
  );
  const jobFunctionsList = useMemo(
    () =>
      jobFunctions.map(({ id, acronym, description }) => ({
        label: `${acronym} - ${description}`,
        value: id.toString(),
      })),
    [jobFunctions]
  );
  return (
    <form className="setup-form" onSubmit={handleSubmit(submit)}>
      <fieldset disabled={isReadonly}>
        <FormInputComponent
          label={t("company.tabs.companyForm.offsetGrantCode")}
          type="text"
          inputProps={register("offsetGrantCode", {
            required: {
              value: true,
              message: t("general.form.validation.required"),
            },
          })}
          property={"offsetGrantCode"}
          error={errors?.offsetGrantCode?.message}
        />
        <FormInputComponent
          label={t("company.tabs.companyForm.fringeBenefitsRatio")}
          prefix="%"
          // readonly={!editable}
          inputProps={register("fringeBenefitsRatio", {
            pattern: {
              value: /^(100|[0-9]{1,2})(\.[0-9]{1,10})?$/,
              message: t("general.form.validation.percentage"),
            },
          })}
          property="fringeBenefitsRatio"
          error={errors?.fringeBenefitsRatio?.message}
        />
        <FormControlledSelect
          control={control as unknown as Control<FieldValues, any>}
          label={t(`company.tabs.companyForm.payrollAllocationAccount`)}
          property="payrollAllocationAccount"
          options={systemAllocationMCOAList}
          disabled={isReadonly}
        />
        <FormControlledSelect
          control={control as unknown as Control<FieldValues, any>}
          label={t(`company.tabs.companyForm.fringeBenefitsAllocationAccount`)}
          property="fringeBenefitsAllocationAccount"
          options={systemAllocationMCOAList}
          disabled={isReadonly}
        />
        <FormControlledSelect
          control={control as unknown as Control<FieldValues, any>}
          label={t(`company.tabs.companyForm.fringeBenefitsJobFunction`)}
          property="fringeBenefitsJobFunction"
          options={jobFunctionsList}
          disabled={isReadonly}
        />
        <FormControlledSelect
          control={control as unknown as Control<FieldValues, any>}
          label={t(`company.tabs.companyForm.indirectCostAllocation`)}
          property="indirectCostAllocationAccount"
          options={programExpenseMCOAList}
          disabled={isReadonly}
        />
        <div className="footer">
          <button disabled={!isDirty} className="btn btn-primary">
            {t("general.save")}
          </button>
        </div>
      </fieldset>
    </form>
  );
}
