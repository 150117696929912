import { createContext, useCallback, useMemo, useState } from "react";
import { Company } from "../interfaces/interfaces";
import companyApi from "../api/company-api";
import { getYearAndMonthFromDate } from "./helpers";

interface CompanyProviderProps {
  children: React.ReactNode;
}

const CompanyContext = createContext({} as any);

const CompanyProvider = ({ children }: CompanyProviderProps) => {
  const [company, setCompany] = useState<Company | null>(null);

  const firstMonth = useMemo(
    () => getYearAndMonthFromDate(company?.companyFiscalPeriodStartDate),
    [company]
  );

  const currentMonth = useMemo(
    () => getYearAndMonthFromDate(new Date().toString()),
    []
  );

  const [chosenMonth, setChosenMonth] = useState<string>(currentMonth || "");
  const updateCompany = useCallback(() => {
    if (!company?.id) {
      return;
    }
    companyApi.getCompany(company.id).then((data) => setCompany(data));
  }, [company]);

  return (
    <CompanyContext.Provider
      value={{
        firstMonth,
        currentMonth,
        company,
        setCompany,
        updateCompany,
        chosenMonth,
        setChosenMonth,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export { CompanyProvider, CompanyContext };
