import { GrantFinData } from "../../../interfaces/interfaces";
import { formatNumber } from "../../../utils";
import { SortTypes } from "../../../interfaces/types";
import { Dispatch, SetStateAction } from "react";

interface BudgetColumn {
  header: string;
  property: keyof GrantFinData;
  sort?: boolean;
  sortType?: SortTypes;
  render?: (data: GrantFinData) => JSX.Element | string;
  sum?: (row: GrantFinData, acc: number) => number;
  format?: (value: string | JSX.Element) => string | JSX.Element;
  renderHeader?: (property?: keyof GrantFinData) => JSX.Element | string;
  editable?: boolean;
  onChange?: (value: string, row: GrantFinData) => void;
  footerClassName?: string;
}

interface GetColumnsProps {
  t: (key: string, params?: Record<string, unknown>) => string;
  renderAllModificationHeader: (
    property?: keyof GrantFinData
  ) => JSX.Element | string;
  isEditing: boolean;
  budgetModifications: Record<string, string | number>;
  setBudgetModifications: Dispatch<
    SetStateAction<Record<string, string | number>>
  >;
  isModificationsBalance: boolean;
}

export const getColumns = ({
  t,
  renderAllModificationHeader,
  isEditing,
  budgetModifications,
  setBudgetModifications,
  isModificationsBalance,
}: GetColumnsProps): BudgetColumn[] => {
  const columns: BudgetColumn[] = [
    {
      property: "account.code",
      header: t("company.tabs.grants.finData.list.account.code"),
      render: (row: GrantFinData) => row?.account?.code,
      sort: true,
      sortType: "string",
    },
    {
      property: "descriptionOrAcronym",
      header: t(
        "company.tabs.grants.finData.list.account.accountGrouping.name"
      ),
      sort: true,
      sortType: "string",
    },
    {
      header: t("company.tabs.grants.finData.list.originalGrantAward"),
      property: "originalGrantAward",
      sum: (row: GrantFinData, acc: number) => +row.originalGrantAward + acc,
      format: (value: string | JSX.Element) => formatNumber(+value),
      sort: true,
      sortType: "number",
    },
    {
      header: t("company.tabs.grants.finData.list.annualGrantAward"),
      property: "annualGrantAward",
      sum: (row: GrantFinData, acc: number) => +row.annualGrantAward + acc,
      format: (value: string | JSX.Element) => formatNumber(+value),
      sort: true,
      sortType: "number",
    },
    {
      header: "header",
      renderHeader: renderAllModificationHeader,
      property: "modifiedAnnualBudget",
      format: (value: string | JSX.Element) => formatNumber(+value),
      sum: (row: GrantFinData, acc: number) => +row.modifiedAnnualBudget + acc,
      sort: true,
      sortType: "number",
    },
    {
      header: t("company.tabs.grants.finData.list.actualBilledAmount"),
      property: "actualBilledAmount",
      sum: (row: GrantFinData, acc: number) => +row.actualBilledAmount + acc,
      format: (value: string | JSX.Element) => formatNumber(+value),
      sort: true,
      sortType: "number",
    },
    {
      header: t("company.tabs.grants.finData.list.remainingFundsAvailable"),
      property: "remainingBalance",
      sum: (row: GrantFinData, acc: number) =>
        +row.remainingBalance + acc + (+budgetModifications[row.id] || 0),
      render: (row: GrantFinData) =>
        isEditing && budgetModifications[row.id]
          ? (+budgetModifications[row.id] || 0) + row.remainingBalance
          : row.remainingBalance,
      format: (value: string | JSX.Element) => formatNumber(+value),
      sort: true,
      sortType: "number",
    },
  ];
  const editableColumn: BudgetColumn = {
    header: t("company.tabs.grants.finData.list.budgetModification"),
    property: "budgetModification",
    format: (value: string | JSX.Element) => formatNumber(+value),
    sum: (row: GrantFinData, acc: number) =>
      (+budgetModifications[row.id] || 0) + acc || 0,
    editable: true,
    onChange: (value: string, row: GrantFinData) => {
      setBudgetModifications((state) => ({
        ...state,
        [row.id]: value || 0,
      }));
    },
    footerClassName: !isModificationsBalance ? "error" : "",
  };
  if (isEditing) {
    columns.splice(4, 0, editableColumn);
  }
  return columns;
};
