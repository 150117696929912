import BackTo from "../back-to/back-to.component";
import { ROUTE_PATHS } from "../../../consts";
import CompanyIdComponent from "../company-id/company-id.component";
import MonthSwitcher from "../month-switcher/month-switcher-component";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../../../context/company-context";

export default function CompanyHeaderComponent({
  id,
}: {
  id?: string;
}): JSX.Element {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  return (
    <header className="company-header">
      <div className="d-flex gap-4">
        <div className="d-flex flex-column justify-content-center gap-2">
          {id && (
            <BackTo
              link={`/${ROUTE_PATHS.companies}`}
              title={t(
                `companies.form.${id ? "editCompany" : "createCompany"}`,
                {
                  name: company?.name,
                }
              )}
            />
          )}
          <CompanyIdComponent />
        </div>
        <MonthSwitcher />
      </div>
    </header>
  );
}
