import React, { useEffect, useState } from "react";
import "./inactiveuser-modal.component.scss";
import Modal from "../modal/modal.component";
import { useTranslation } from "react-i18next";

interface InActiveUserModalProps {
  title: string;
  activeValue: boolean;
}

export default function InActiveUserModalComponent({
  title,
}: InActiveUserModalProps): JSX.Element {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const hasVisitedDashboard = localStorage.getItem("hasVisitedDashboard");
    if (!hasVisitedDashboard) {
      setShow(true);
      localStorage.setItem("hasVisitedDashboard", "true");
    }
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal
      title={t("general.inactiveCompanyTitle")}
      show={show}
      Footer={
        <div className="gap-3 confirmation-footer footer">
          <button onClick={handleClose} className="btn btn-secondary">
            {t("general.cancel")}
          </button>
        </div>
      }
    >
      <div>{title}</div>
    </Modal>
  );
}
