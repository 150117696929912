import React from "react";
import "./empty-list.component.scss";
import ButtonsComponent from "../download-upload-buttons/buttons.component";
import { ReloadModalValue } from "../../../interfaces/types";

export interface Action {
  title: string;
  action?: (file: FileList, selection: string) => Promise<void>;
  url?: string;
  confirmation?: boolean;
  preselection?: ReloadModalValue;
  disabled?: boolean;
}
interface EmptyListProps {
  importActions: Array<Action>;
  uploadActions: Array<Action>;
  description: string;
  uploadWarning?: string;
}

export default function EmptyListComponent({
  importActions,
  uploadActions,
  description,
  uploadWarning,
}: EmptyListProps): JSX.Element {
  return (
    <div className="empty-list gap-4">
      <span>{description}</span>
      <div className="buttons gap-3">
        {ButtonsComponent({ importActions, uploadActions, uploadWarning })}
      </div>
    </div>
  );
}
