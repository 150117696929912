import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TOAST_TYPES } from "../../../consts";
import { Grant } from "../../../interfaces/interfaces";
import { formatToNumber } from "../../../utils";
import grantsApi from "../../../api/grants-api";
import grantApi from "../../../api/grants-api";
import { CompanyContext } from "../../../context/company-context";
import { ToastContext } from "../../../context/toast.context";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import PageLayout from "../../../components/shared/page-layout/page-layout.component";
import BackTo from "../../../components/shared/back-to/back-to.component";
import GrantForm from "../../../components/forms/grant/grant.form";
import "./grant.component.scss";

interface GrantProps {
  setGrant: (grant: Grant) => void;
}

export default function GrantComponent({ setGrant }: GrantProps): JSX.Element {
  const navigate = useNavigate();
  const [currentGrant, setCurrentGrant] = useState<Grant | null>(null);
  const { t } = useTranslation();
  const { grantId } = useParams();
  const { addToast } = useContext(ToastContext);
  const errorHandler = useErrorHandler();
  const { company } = useContext(CompanyContext);
  const { pathname } = useLocation();
  const { updateCompany } = useContext(CompanyContext);

  useEffect(() => {
    if (!grantId) {
      return;
    }
    grantsApi.getGrant(grantId, company.id).then((data: Grant) => {
      setCurrentGrant(data);
      setGrant(data);
    });
  }, [grantId, company.id, setGrant]);

  const onSubmit = useCallback(
    (data: FieldValues) => {
      const fetcher = grantId ? grantsApi.updateGrant : grantApi.createGrant;

      fetcher(company.id, {
        ...data,
        totalGrantAward: formatToNumber(data.totalGrantAward),
        program: data?.program?.id === "all" ? null : data.program,
      }).then(
        (res: Grant) => {
          addToast({
            id: res.id,
            message: t("company.tabs.grants.editSuccess"),
            type: TOAST_TYPES.success,
          });
          if (!grantId) {
            navigate(`${pathname}/${res.id}`);
          } else {
            updateCompany();
          }
        },
        (err) => errorHandler(err)
      );
    },
    [
      addToast,
      company.id,
      errorHandler,
      grantId,
      navigate,
      pathname,
      t,
      updateCompany,
    ]
  );

  return (
    <PageLayout
      title={() => (
        <BackTo
          link={`${pathname.split("grants")[0]}grants`}
          title={t("company.tabs.grants.grantDetails", {
            id: currentGrant?.code,
          })}
        />
      )}
      actionNode={() =>
        currentGrant?.grantOffset ? null : (
          <div className="company-id gap-2">
            <button
              className="btn btn-primary"
              onClick={() => navigate(`${pathname}/budget/${grantId}`)}
              disabled={!currentGrant}
            >
              {t("company.tabs.grants.financialData")}
            </button>
          </div>
        )
      }
    >
      <GrantForm
        key={currentGrant?.id}
        submit={onSubmit}
        entity={currentGrant}
      />
    </PageLayout>
  );
}
