import React from "react";
import { Controller, Control } from "react-hook-form";
import { getYearMonth, getFirstDay, getLastDay } from "../../../utils";

interface FormMonthPickerProps {
  label?: string;
  property: string;
  control: Control;
  rules?: Record<string, any>;
  readonly?: boolean;
  firstDay: boolean;
}

export default function FormMonthPicker({
  label,
  property,
  control,
  rules,
  readonly,
  firstDay,
}: FormMonthPickerProps): JSX.Element {
  return (
    <Controller
      rules={rules}
      name={property}
      control={control}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        return (
          <div className="mb-3 form-field">
            {label && (
              <label htmlFor={property} className="form-label">
                {label}
              </label>
            )}
            <input
              readOnly={readonly}
              type="month"
              className="form-control"
              value={getYearMonth(field.value)}
              onChange={(e) =>
                field.onChange(
                  firstDay
                    ? getFirstDay(e.target.value)
                    : getLastDay(e.target.value)
                )
              }
            />
            {error && <div className="error">{error.message}</div>}
          </div>
        );
      }}
    />
  );
}
