import React, { useState, useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FieldValues } from "react-hook-form";
import {
  TOAST_TYPES,
  BILLING_ALLOCATION_METHODS,
  USER_ROLE_LABELS,
  USER_ROLES,
} from "../../consts";
import { Payroll, Program } from "../../interfaces/interfaces";
import payrollApi from "../../api/payroll-api";
import programsApi from "../../api/programs-api";
import { ToastContext } from "../../context/toast.context";
import { CompanyContext } from "../../context/company-context";
import { useLoadData } from "../../hooks/useLoadData";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { getColumns } from "./helpers";
import ActionHeaderComponent from "./action-header/action-header.component";
import PayrollFormComponent from "../../components/forms/payroll/payroll-form.component";
import Table from "../../components/shared/table/table.component";
import Modal from "../../components/shared/modal/modal.component";
import Spinner from "../../components/shared/spinner/spinner.component";
import "./payroll-register.component.scss";
import { DownloadButtonComponent } from "../../components/shared/download-button/download-button.component";
import fileApi from "../../api/file-api";
import UploadComponent from "../../components/shared/upload/upload-validation-component/upload-validation.component";
import { ERRORS } from "./action-header/helpers";
import { UserContext } from "../../context/user.context";
import { useRegions } from "./hooks/useRegions";

export default function PayrollRegisterComponent(): JSX.Element | null {
  const { t } = useTranslation();
  const errorHandler = useErrorHandler();
  const { addToast } = useContext(ToastContext);
  const { company, chosenMonth } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const isUserAdmin = [
    USER_ROLE_LABELS[USER_ROLES.ROLE_ADMIN],
    USER_ROLE_LABELS[USER_ROLES.ROLE_COMPANY_ADMIN],
  ].includes(user.role);
  const activePeriod = useMemo(
    () => chosenMonth.split("/")?.[0],
    [chosenMonth]
  );
  const [payrolls, arePayrollsLoading, refetchPayrolls] = useLoadData({
    fetcher: useCallback(
      () => payrollApi.getPayrolls(company.id, activePeriod),
      [activePeriod, company.id]
    ),
  });
  const [shortPrograms, areShortProgramsLoading] = useLoadData<Program[]>({
    fetcher: useCallback(
      () => programsApi.getShortPrograms(company.id, activePeriod),
      [activePeriod, company.id]
    ),
  });

  const [allPayrollsCount, , refetchPayrollCounts] = useLoadData({
    fetcher: useCallback(
      () => payrollApi.getPayrollsCount(company.id),
      [company]
    ),
  });

  const [
    allocationPrograms,
    areAllocationProgramsLoading,
    loadAllocationPrograms,
  ] = useLoadData<Program[]>({
    fetcher: useCallback(
      () =>
        isUserAdmin
          ? programsApi.getPRAllocationPrograms(company.id, activePeriod)
          : Promise.resolve([]),
      [activePeriod, company.id, isUserAdmin]
    ),
  });
  const [purgePrograms, arePurgeProgramsLoading, loadPurgePrograms] =
    useLoadData<Program[]>({
      fetcher: useCallback(
        () =>
          isUserAdmin
            ? programsApi.getPRPurgePrograms(company.id, activePeriod)
            : Promise.resolve([]),
        [activePeriod, company.id, isUserAdmin]
      ),
    });
  const [showUploadedResultModal, setShowUploadedResultModal] =
    useState<boolean>(false);
  const [payrollCount, setPayrollCount] = useState<string>("");
  const [totalPayrollAllocation, setTotalPayrollAllocation] =
    useState<string>("");
  const [payrollToEdit, setPayrollToEdit] = useState<
    (Payroll & { allocated: boolean }) | undefined
  >(undefined);
  const [editConfirmation, setEditConfirmation] = useState<boolean>(false);
  const [selectedAllocationProgram, setSelectedAllocationProgram] =
    useState<string>("all");
  const [selectedPurgeProgram, setSelectedPurgeProgram] =
    useState<string>("all");
  const [expandedRows, setExpandedRows] = useState(
    {} as Record<string, boolean>
  );
  const hasAllocationBeenMade = useMemo(
    () => payrolls?.allocated?.length > 0,
    [payrolls]
  );

  const isLoading = useMemo(
    () =>
      arePayrollsLoading ||
      areShortProgramsLoading ||
      arePurgeProgramsLoading ||
      areAllocationProgramsLoading,
    [
      arePayrollsLoading,
      areShortProgramsLoading,
      arePurgeProgramsLoading,
      areAllocationProgramsLoading,
    ]
  );

  const isPayrollAllocated = useCallback(
    (payroll: Payroll): boolean => {
      for (const allocatedEntry of payrolls.allocated) {
        if (Array.isArray(allocatedEntry.children)) {
          const childrenIds = allocatedEntry.children.map((e) => e.id);
          if (childrenIds.includes(payroll.id)) return true;
        } else if (allocatedEntry.id === payroll.id) {
          return true;
        }
      }
      return false;
    },
    [payrolls]
  );

  const runAllocation = useCallback(() => {
    const allocationProgramId =
      selectedAllocationProgram === "all"
        ? selectedAllocationProgram
        : +selectedAllocationProgram;
    const data = {
      companyId: company.id,
      programId: allocationProgramId,
      grantOffsetCode: company.offsetGrantCode,
      type: BILLING_ALLOCATION_METHODS.SAM,
    };
    return payrollApi.allocatePayrolls(data, company.id, activePeriod).then(
      (res) => {
        if (res.status == "ERROR") {
          res.errors?.map(({ message }) => errorHandler(undefined, message));
        } else {
          addToast({
            type: TOAST_TYPES.success,
            message: t("company.tabs.payrollRegister.allocateSuccess"),
          });
        }
        loadAllocationPrograms();
        refetchPayrollCounts();
        loadPurgePrograms();
        refetchPayrolls();
      },
      (err) => errorHandler(err)
    );
  }, [
    company.id,
    company.offsetGrantCode,
    selectedAllocationProgram,
    activePeriod,
    refetchPayrolls,
    refetchPayrollCounts,
    loadAllocationPrograms,
    loadPurgePrograms,
    errorHandler,
    addToast,
    t,
  ]);

  const purgeProgramCode = useMemo(
    () =>
      purgePrograms.find(
        (program) => program.id.toString() === selectedPurgeProgram
      )?.code,
    [purgePrograms, selectedPurgeProgram]
  );

  const postUploadEvent = useCallback(() => {
    refetchPayrolls();
    refetchPayrollCounts();
  }, [refetchPayrollCounts, refetchPayrolls]);

  const runPurge = useCallback(() => {
    const programId =
      selectedPurgeProgram === "all" ? "" : selectedPurgeProgram;
    return payrollApi.purgePayrolls(company.id, programId, activePeriod).then(
      (res) => {
        if (res.errors?.length) {
          errorHandler(undefined, res.errors?.[0]?.message);
        } else {
          const toastMessage =
            selectedPurgeProgram === "all"
              ? t("company.tabs.payrollRegister.purgeAllSuccess")
              : t("company.tabs.payrollRegister.purgeProgramSuccess", {
                  purgeProgram: purgeProgramCode,
                });
          addToast({
            type: TOAST_TYPES.success,
            message: toastMessage,
          });
          loadAllocationPrograms();
          loadPurgePrograms();
          refetchPayrollCounts();
          refetchPayrolls();
        }
      },
      (err) => errorHandler(err)
    );
  }, [
    selectedPurgeProgram,
    purgeProgramCode,
    company.id,
    activePeriod,
    errorHandler,
    t,
    addToast,
    refetchPayrolls,
    refetchPayrollCounts,
    loadAllocationPrograms,
    loadPurgePrograms,
  ]);

  const editHandler = useCallback(
    (
      values: FieldValues,
      saveCallback: (values: FieldValues) => Promise<any>
    ) => {
      if (!editConfirmation) {
        setEditConfirmation(true);
        return;
      }
      saveCallback(values).then(
        (res) => {
          if (res.status == "ERROR") {
            const messages = res.errors
              ?.map(({ message }: { message: string }) => message)
              .join(";");
            errorHandler(undefined, messages);
          } else {
            addToast({
              type: TOAST_TYPES.success,
              message: t("company.tabs.payrollRegister.editSuccess"),
            });
            setEditConfirmation(false);
            setPayrollToEdit(undefined);
            postUploadEvent();
          }
        },
        (err) => errorHandler(err)
      );
    },
    [
      t,
      addToast,
      errorHandler,
      setPayrollToEdit,
      editConfirmation,
      setEditConfirmation,
      postUploadEvent,
    ]
  );

  const data = useMemo(() => {
    const withChildren: Payroll[] = [];
    payrolls?.allocated?.forEach((item, index) => {
      withChildren.push({ ...item, id: index });
      if (item.children && expandedRows[index]) {
        withChildren.push(...item.children);
      }
    });
    return [...withChildren, ...(payrolls?.unAllocated || [])];
  }, [expandedRows, payrolls]);

  const isAllCollapsed = useMemo(() => {
    const rows = Object.values(expandedRows);
    return !rows.length || rows.every((item) => !item);
  }, [expandedRows]);

  const isAllExpanded = useMemo(() => {
    const expandedValues = Object.values(expandedRows);
    return (
      payrolls?.allocated?.length === expandedValues?.length &&
      expandedValues.every((item) => item)
    );
  }, [expandedRows, payrolls]);

  const onExpandAll = useCallback(() => {
    const newRowsStatus = {} as Record<string, boolean>;
    payrolls.allocated?.forEach((_, index) => {
      newRowsStatus[index] = isAllCollapsed;
    });
    setExpandedRows(newRowsStatus);
  }, [isAllCollapsed, payrolls.allocated]);

  const columns = useMemo(
    () =>
      getColumns({
        t,
        expandedRows,
        setExpandedRows,
        onExpandAll,
        isAllCollapsed,
        isUserAdmin,
        setPayrollToEdit,
        isPayrollAllocated,
      }),
    [
      expandedRows,
      isAllCollapsed,
      onExpandAll,
      t,
      isUserAdmin,
      isPayrollAllocated,
    ]
  );

  const isEditedPayrollAllocated = useMemo(
    () => payrollToEdit?.allocated,
    [payrollToEdit]
  );

  const { configWithRegion } = useRegions();

  return !isLoading ? (
    <div className="payroll-register">
      <Modal
        onClose={() => {
          setPayrollCount("");
          setTotalPayrollAllocation("");
          setShowUploadedResultModal(false);
        }}
        show={showUploadedResultModal}
        title={t("general.uploadResults")}
        Footer={[
          <button
            onClick={() => {
              setPayrollCount("");
              setTotalPayrollAllocation("");
              setShowUploadedResultModal(false);
            }}
            className="btn btn-primary"
          >
            {t("general.ok")}
          </button>,
        ]}
      >
        <div>
          {t(
            +payrollCount === 1
              ? "company.tabs.payrollRegister.uploadModalInfo"
              : "company.tabs.payrollRegister.uploadModalInfo_plural",
            { payrollCount, totalPayrollAllocation }
          )}
        </div>
      </Modal>
      <Modal
        centredContent={true}
        size="large"
        show={!!payrollToEdit}
        title={t(
          `company.tabs.payrollRegister.form.title.${
            isEditedPayrollAllocated ? "allocated" : "unallocated"
          }`
        )}
        {...(editConfirmation && {
          subtitle: t(`company.tabs.payrollRegister.form.subtitle`),
        })}
      >
        <PayrollFormComponent
          key={payrollToEdit?.id}
          isConfirmating={editConfirmation}
          payroll={payrollToEdit}
          programs={shortPrograms}
          onSubmit={editHandler}
          onClose={() => {
            setPayrollToEdit(undefined);
            setEditConfirmation(false);
          }}
        />
      </Modal>
      {allPayrollsCount > 0 ? (
        <div>
          <ActionHeaderComponent
            allocationPrograms={allocationPrograms}
            allocationMade={hasAllocationBeenMade}
            purgePrograms={purgePrograms}
            runAllocation={runAllocation}
            runPurge={runPurge}
            allocationProgram={selectedAllocationProgram}
            setAllocationProgram={setSelectedAllocationProgram}
            purgeProgram={selectedPurgeProgram}
            setPurgeProgram={setSelectedPurgeProgram}
            postUploadEvent={postUploadEvent}
            showPreselection={!!data.length}
          />
          {!data.length ? (
            <div className="empty-table flex-fill justify-content-center align-items-center">
              {t("general.filteredDataMessage")}
            </div>
          ) : (
            <Table
              search
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              columns={columns}
              data={data}
              filterTrigger={() => {
                if (!isAllExpanded) {
                  onExpandAll();
                }
              }}
            />
          )}
        </div>
      ) : (
        <section className="empty-page gap-3">
          <span>{t("company.tabs.gl.addList")}</span>
          <div className="buttons gap-3">
            <DownloadButtonComponent
              label={t("company.tabs.payrollRegister.getTemplate")}
              downloadHandler={() =>
                fileApi.downloadFile("templates?templateName=PAYROLL_REGISTER")
              }
              primary={false}
            />
            <UploadComponent
              disabled={!isUserAdmin}
              showPreselection={!!data.length}
              postUploadEvent={postUploadEvent}
              uploadHandler={payrollApi.uploadPayrolls}
              validateHandler={payrollApi.validatePRFile}
              title={t("company.tabs.payrollRegister.upload")}
              errors={ERRORS}
              config={configWithRegion}
            />
          </div>
        </section>
      )}
    </div>
  ) : (
    <Spinner size="medium" />
  );
}
